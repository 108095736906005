import React, { Component } from 'react';
import './Navigation.css';

class NavigationLink extends Component {
    render() {
        return (
            <li className="uk-active text-transform-initial">
                { this.props.children }
            </li>
        );
    }
}

export default NavigationLink;