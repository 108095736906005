import React, { Component } from 'react';

class Heading extends Component {
    render() {
        return (
            <h1 className="uk-text-center uk-margin-medium-bottom"><span className='cu-font'>{this.props.children}</span></h1>
        );
    }
}

export default Heading;