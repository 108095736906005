import React, { Component } from 'react'

export class BioSectionItem extends Component {
    render() {

        let section = this.props.section;

        if (section.id === 6) {
            return (
                <div>
                    <h1 className="uk-article-title font-cali uk-text-bolder uk-text-center uk-margin-small-top" style={{
                        fontSize: '22px',
                    }}>{section.title}</h1>

                    <div className='uk-flex uk-flex-center'>
                        <ul class="uk-list uk-list-bullet" style={{
                            width: '80%',
                        }}>
                            {section.text.map((sectionText, i) => {
                                return (
                                    <li key={'list-'+i} style={{
                                        fontSize: '13px',
                                        letterSpacing: '1.1px',
                                        lineHeight: '1.1',
                                        textJustify: 'inter-word',
                                        textAlign: 'justify',
                                    }}>
                                        <span>
                                            {sectionText}
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            )
        }

        if (section.thumbnail) {
            return (
                <div className='uk-flex uk-flex-column'>
                    <div className='uk-flex uk-flex-top uk-flex-center' style={{ width: '99%' }}>
                        <div style={{
                            padding: '0 20px 20px',
                        }}>
                            <img className='uk-box-shadow-small' src={section.thumbnail} alt={section.thumbnailAlt} style={{
                                objectFit: 'contain',
                                width: '200px',
                                padding: '5px',
                                backgroundColor: 'white',
                            }} uk-img={'1'}/>
                        </div>

                        <div style={{
                            width: '75%',
                        }}>
                            <div>
                                {section.text.map((sectionText, i) => {
                                    return (
                                        <p key={'intro-section-text-' + section.id + '_' + i} className='cu-tab' style={{
                                            fontSize: '13px',
                                            letterSpacing: '1.1px',
                                            lineHeight: '1.1',
                                            textJustify: 'inter-word',
                                            textAlign: 'justify',
                                        }}> {sectionText} </p>
                                    );
                                })}
                            </div>

                            <div className='uk-flex uk-flex-around uk-flex-middle'>
                                {section.icons.map((icon, i) => {
                                    return (
                                        <a href={icon.url} target='_blank' rel="noopener noreferrer" key={'intro_icon_' + i} uk-icon={`icon: ${icon.icon}`}
                                         style={{
                                            color: icon.color,
                                         }}></a>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <h1 className="uk-article-title font-cali uk-text-bolder uk-text-center uk-margin-small-top" style={{
                    fontSize: '22px',
                }}>{section.title}</h1>

                <div>
                    {section.text.map((sectionText, i) => {
                        return (
                            <p key={'intro-section-text-' + section.id + '_' + i} className='cu-tab' style={{
                                fontSize: '13px',
                                letterSpacing: '1.1px',
                                lineHeight: '1.1',
                                textJustify: 'inter-word',
                                textAlign: 'justify',
                            }}> {sectionText} </p>
                        );
                    })}
                </div>
            </div>
        )
    }
}

export default BioSectionItem
