import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";

class NavigationCardLink extends Component {

    render() {
        let link = this.props.presentation ? `/presentation/${this.props.id}` : `/book/${this.props.id}`;
        return (
            <li className='book-link'>
                <Link to={link}>
                    <div className='uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-visible@m'>
                        <img src={this.props.cover} alt={this.props.title} className="uk-box-shadow-large cu-book-cover uk-margin-bottom-medium" uk-img={'1'} />
                        <span className='font-cali text-md uk-button-text uk-padding-small'>
                            {this.props.title}
                        </span>
                    </div>
                    <div className='uk-hidden@m uk-flex uk-flex-middle'>
                        <img src={this.props.cover} alt={this.props.title} className="uk-box-shadow-large cu-book-cover-sm uk-margin-bottom-medium" uk-img={'1'} />
                        <span className='font-cali text-md uk-button-text uk-padding-small'>
                            {this.props.title}
                        </span>
                    </div>
                </Link>
            </li>
        );
    }
}

export default NavigationCardLink;