import React, { Component } from 'react';
import NewsArticle from '../Components/NewsArticle/NewsArticle';
import Footer from '../Components/Footer/Footer';
import { NEWS_ALBUM } from '../Config/news.config';

class NewsFeed extends Component {

    constructor(props) {
        super(props)

        this.state = {
            news: NEWS_ALBUM,
        }
    }

    render() {
        return (<div className='uk-section-muted uk-width-1-1 uk-flex uk-flex-column uk-flex-center uk-flex-middle'>
            <div className='uk-container uk-margin-medium-top uk-margin-large-bottom uk-visible@m'>
                {this.state.news.map((el, i) => {
                    return (<React.Fragment key={'article-' + i}>
                        <NewsArticle article={el} />
                    </React.Fragment>);
                })}
            </div>
            <div className='uk-margin-small uk-width-1-1 uk-hidden@m'>
                {this.state.news.map((el, i) => {
                    return (<React.Fragment key={'article-' + i}>
                        <NewsArticle article={el} />
                    </React.Fragment>);
                })}
            </div>

            <div className='uk-section'>
                <Footer />
            </div>
        </div>)
    }
}

export default NewsFeed;