import SmaragdeniaCover from '../assets/books/smaragdenia/cover.jpg';
import SmaragdeniaCoverRealSize from '../assets/books/smaragdenia/cover-real-size.jpg';
import SmaragdeniaFullCover from '../assets/books/smaragdenia/book-full-bg.jpg';
import SmaragdeniaWritersPhoto from '../assets/books/smaragdenia/writer.jpg';

import SmagargdeniaPhoto1 from '../assets/books/smaragdenia/book_page_photos/Smaragdenia_Filaki_1.jpg';
import SmagargdeniaPhoto2 from '../assets/books/smaragdenia/book_page_photos/Smaragdenia_Filaki_2.jpg';
import SmagargdeniaPhoto3 from '../assets/books/smaragdenia/book_page_photos/Smaragdenia_Filaki_3.jpg';
import SmagargdeniaPhoto4 from '../assets/books/smaragdenia/book_page_photos/Smaragdenia_Filaki_4.jpg';
import SmagargdeniaPhoto5 from '../assets/books/smaragdenia/book_page_photos/Smaragdenia_Filaki_5.jpeg';
import SmagargdeniaPhoto6 from '../assets/books/smaragdenia/book_page_photos/Smaragdenia_Filaki_6.jpeg';
import SmagargdeniaPhoto7 from '../assets/books/smaragdenia/book_page_photos/Smaragdenia_Filaki_7.jpg';


import OrkosCover from '../assets/books/orkos/cover.jpg';
import OrkosCoverRealSize from '../assets/books/orkos/cover-real-size.jpg';
import OrkosFullCover from '../assets/books/orkos/book-full-bg.jpg';
import OrkosWritersPhoto from '../assets/books/orkos/writer.jpg';

import OrkosPhoto1 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_1.jpg';
import OrkosPhoto2 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_2.jpg';
import OrkosPhoto3 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_3.jpg';
import OrkosPhoto4 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_4.jpg';
import OrkosPhoto5 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_5.jpg';
import OrkosPhoto6 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_6.jpg';
import OrkosPhoto7 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_7.jpg';
import OrkosPhoto8 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_8.jpg';
import OrkosPhoto9 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_9.jpg';
import OrkosPhoto10 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_10.jpg';
import OrkosPhoto11 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_11.jpg';
import OrkosPhoto12 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_12.jpg';
import OrkosPhoto13 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_13.jpg';
import OrkosPhoto14 from '../assets/books/orkos/book_page_photos/Orkos_Siopis_14.jpg';

import XeirokrotimaCover from '../assets/books/xeirokrotima/cover.jpg';
import XeirokrotimaCoverRealSize from '../assets/books/xeirokrotima/cover-real-size.jpg';
import XeirokrotimaFullCover from '../assets/books/xeirokrotima/book-full-bg.jpg';
import XeirokrotimaWritersPhoto from '../assets/books/xeirokrotima/writer.jpg';

import XeirokrotimaPhoto1 from '../assets/books/xeirokrotima/book_page_photos/Xeirokrotima_1.jpg';
import XeirokrotimaPhoto2 from '../assets/books/xeirokrotima/book_page_photos/Xeirokrotima_2.jpg';
import XeirokrotimaPhoto3 from '../assets/books/xeirokrotima/book_page_photos/Xeirokrotima_3.jpg';
import XeirokrotimaPhoto4 from '../assets/books/xeirokrotima/book_page_photos/Xeirokrotima_4.jpg';
import XeirokrotimaPhoto5 from '../assets/books/xeirokrotima/book_page_photos/Xeirokrotima_5.jpg';
import XeirokrotimaPhoto6 from '../assets/books/xeirokrotima/book_page_photos/Xeirokrotima_6.jpg';
import XeirokrotimaPhoto7 from '../assets/books/xeirokrotima/book_page_photos/Xeirokrotima_7.jpg';
import XeirokrotimaPhoto8 from '../assets/books/xeirokrotima/book_page_photos/Xeirokrotima_8.jpg';
import XeirokrotimaPhoto9 from '../assets/books/xeirokrotima/book_page_photos/Xeirokrotima_9.jpg';

import DikaiwsiCover from '../assets/books/dikaiwsi/cover.jpg';
import DikaiwsiCoverRealSize from '../assets/books/dikaiwsi/cover-real-size.jpg';
import DikaiwsiFullCover from '../assets/books/dikaiwsi/book-full-bg.jpg';
import DikaiwsiWritersPhoto from '../assets/books/dikaiwsi/writer.jpg';

import DikaiwsiPhoto1 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_1.jpg';
import DikaiwsiPhoto2 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_2.jpg';
import DikaiwsiPhoto3 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_3.jpg';
import DikaiwsiPhoto4 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_4.jpg';
import DikaiwsiPhoto5 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_5.jpg';
import DikaiwsiPhoto6 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_6.jpg';
import DikaiwsiPhoto7 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_7.jpg';
import DikaiwsiPhoto8 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_8.jpg';
import DikaiwsiPhoto9 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_9.jpg';
import DikaiwsiPhoto10 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_10.jpg';
import DikaiwsiPhoto11 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_11.jpg';
import DikaiwsiPhoto12 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_12.jpg';
import DikaiwsiPhoto13 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_13.jpg';
import DikaiwsiPhoto14 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_14.jpg';
import DikaiwsiPhoto15 from '../assets/books/dikaiwsi/book_page_photos/Dikaiwsi_15.jpg';

import DesmosWriterPhoto from '../assets/books/desmos/desmos-writer.jpg';
import DesmosCover from '../assets/books/desmos/desmos-bg-cover.jpg';
import DesmosBookCover from '../assets/books/desmos/DesmosCover.jpg';
import DesmosBookCoverSmall from '../assets/books/desmos/DesmosCoverSmall.jpg';

import ArxontisaWriter from '../assets/books/arxontisa/arxontisa-writer.jpg';
import ArxontisaBgCover from '../assets/books/arxontisa/arxontisa-bg-cover.jpg';
import ArxontisaCover from '../assets/books/arxontisa/arxontisa-cover.jpg';

import Seira1_SexpirikosGrifosWriterPhoto from '../assets/books/seira-1/sexpirikos-grifos/sexpirikos-writer-image.JPG';
import Seira1_SexpirikosGrifosBgCover from '../assets/books/seira-1/sexpirikos-grifos/sexpirikos-bg.jpg';
import Seira1_SexpirikosGrifosCover from '../assets/books/seira-1/sexpirikos-grifos/sexpirikos-book-cover.jpg';

import Seira1_XD_Writer from '../assets/books/seira-1/xaki-dantela/xaki-dantela-writer.jpg';
import Seira1_XD_Book_Cover from '../assets/books/seira-1/xaki-dantela/xaki-dantela-book-cover.jpg';
import Seira1_XD_BgCover from '../assets/books/seira-1/xaki-dantela/xaki-dantela-bg-2.jpg';

import Seira_MP_Writer from '../assets/books/seira-1/megali-paraskeui/megali-paraskeui-writer.jpg';
import Seira_MP_BookCover from '../assets/books/seira-1/megali-paraskeui/megali-paraskeui-book.jpg';
import Seira_MP_BgCover from '../assets/books/seira-1/megali-paraskeui/megali-paraskeui-bg.jpg';

import Seira_RV_Writer from '../assets/books/seira-1/ragismeni-vera/ragismeni-vera-writer.jpg';
import Seira_RV_BookCover from '../assets/books/seira-1/ragismeni-vera/ragismeni-vera-book-cover.jpg';
import Seira_RV_BgCover from '../assets/books/seira-1/ragismeni-vera/ragismeni-vera-bg.jpg';

import Seira_KK_Writer from '../assets/books/seira-1/klemmeno-kalos/klemmeno-kalos-writer.jpg';
import Seira_KK_BookCover from '../assets/books/seira-1/klemmeno-kalos/klemmeno-kalos-book-cover.jpg';
import Seira_KK_BgCover from '../assets/books/seira-1/klemmeno-kalos/klemmeno-kalos-bg.jpg';

import Seira_SN_Writer from '../assets/books/seira-1/skoteino-nepal/skoteino-nepal-writer.jpg';
import Seira_SN_BookCover from '../assets/books/seira-1/skoteino-nepal/skoteino-nepal-book-cover.jpg';
import Seira_SN_BgCover from '../assets/books/seira-1/skoteino-nepal/skoteino-nepal-bg.jpg';


export const Smaragdenia = {
    id: 1,
    cover: SmaragdeniaCover,
    coverRealSize: SmaragdeniaCoverRealSize,
    pageCoverFull: SmaragdeniaFullCover,
    title: 'Σμαραγδένια Φυλακή',
    shortDescription: `Στις ορεινές Βολίμες της Ζακύνθου, ο ηλικιωμένος πια δημοσιογράφος Κίμωνας Χαρίτος βιώνει είκοσι χρόνια μοναξιάς. Κάπου ανάμεσα στην προσπάθειά του να λησμονήσει τα σμαραγδένια μάτια που του στιγμάτισαν τη ζωή, να αυτοτιμωρηθεί για τις ανήθικες και άνομες πράξεις του και να απελευθερωθεί από τη σμαραγδένια φυλακή του, κρατάει στην κουνιστή του πολυθρόνα την ύστατη παρηγοριά του, τη γάτα του, και αναπολεί το παρελθόν του.`,
    fullDescription: [
        `Στις ορεινές Βολίμες της Ζακύνθου, ο ηλικιωμένος πια δημοσιογράφος Κίμωνας Χαρίτος βιώνει είκοσι χρόνια μοναξιάς. Κάπου ανάμεσα στην προσπάθειά του να λησμονήσει τα σμαραγδένια μάτια που του στιγμάτισαν τη ζωή, να αυτοτιμωρηθεί για τις ανήθικες και άνομες πράξεις του και να απελευθερωθεί από τη σμαραγδένια φυλακή του, κρατάει στην κουνιστή του πολυθρόνα την ύστατη παρηγοριά του, τη γάτα του, και αναπολεί το παρελθόν του.`,
        `Ένα βροχερό πρωινό, μια απροσδόκητη επίσκεψη από μια νεαρή κοπέλα έρχεται να ταράξει την ηρεμία του και να τον ταξιδέψει πίσω στο χρόνο. Εξαιτίας της, ξαναβιώνει το παρελθόν του και όλα περνούν πάλι μπροστά από τα μάτια του: η επαγγελματική και γεμάτη ίντριγκες ανέλιξή του στο χώρο της δημοσιογραφίας, η δόξα, τα πάθη, η οικογένεια, η φιλία, η απώλεια, η ανάγκη για λύτρωση… Όλα αυτά, όμως, είχαν έναν κοινό παρονομαστή: τη Σμαράγδα, τη γυναίκα που του ξύπνησε το πιο ακραίο ερωτικό πάθος, τη γυναίκα που στη θέση των ματιών της φώλιαζαν δύο σπάνια πράσινα πετράδια και που αυτά τον καταδίκασαν σε ισόβια ερωτική κάθειρξη, τη γυναίκα για την οποία, όντας δέσμιος και δεσμώτης της σμαραγδένιας φυλακής της, έζησε όλα του τα χρόνια με τη φρούδη ελπίδα ότι κάποια μέρα θα ήταν μαζί.`,
        `Με φόντο το «φιόρο του Λεβάντε», το τρένο της αφήγησης ξεκινάει το ταξίδι του με προορισμό τη λύτρωση του Κίμωνα. Ωστόσο, τα σχέδια και τα δίχτυα της μοίρας απέχουν πολύ από τις προσδοκίες του…`,
    ],
    landingQuote: '“Οι μεγάλοι έρωτες μένουν αθάνατοι… μονάχα στις αναμνήσεις”',
    prologue: ['Ήταν αργά τη νύχτα και η δυνατή βροχή δεν έλεγε να κοπάσει στο νησί της Ζακύνθου. Ο ηλικιωμένος πια δημοσιογράφος Κίμωνας Χαρίτος καθόταν στην κουνιστή πολυθρόνα του σαλονιού του απολαμβάνοντας τη βροχή και έχοντας στα πόδια του την ύστατη παρηγοριά του, τη γάτα του. Χάζευε πίσω από το τζάμι την οργή του καιρού και αναπολούσε το κρυφό και άστατο παρελθόν του. Λίγο πριν ανάψει ένα ακόμα τσιγάρο, το κουδούνι της εξώπορτας ήχησε. Η μοίρα ήταν έτοιμη να παίξει το τελευταίο της χαρτί γυρνώντας τον αρκετά χρόνια πίσω. Η επισκέπτρια, μια νεαρή πρασινομάτα κοπέλα, επρόκειτο να γίνει δικαστής και να τον χρίσει ξανά δέσμιο και δεσμώτη της σμαραγδένιας φυλακής του…'],
    writersMessagePhoto: SmaragdeniaWritersPhoto,
    writersMessage: [
        'Από μικρός έγραφα. Ώρες ατελείωτες, κρυμμένος μέσα στο δωμάτιο μου, πίσω απ’ το γραφείο μου και έγραφα. Άρπαζα την πένα μου και δεν σταματούσα, αν δεν στέρευε το μελάνι της. Η πένα έμελλε να γίνει και δικό μου «σήμα κατατεθέν». Σύμβολο δημοσιογραφίας άλλωστε... Κοίτα να δεις πως τα φέρνει η ζωή! Τόνοι τα χειρόγραφα στα συρτάρια μου. Έμοιαζαν με αμπέλια που καρτερούν τον τρύγο τους. Έφηβος θα πει μούστος και η διαδικασία της τελειοποίησης σε κρασί θέλει μεράκι και χρόνο. Αποφάσισα λοιπόν να ζυμώσω πάθη, πόνο, έρωτα και όνειρα… Σαν άνοιξα, έπειτα, τις πόρτες απ’ το κελάρι της ψυχής μου, το φως της δικαίωσης έλαμψε στα μάτια μου.',
        'Τώρα πια… είμαι ευτυχισμένος. Στα χέρια σας κρατάτε το πρώτο μου «κρασί». Να το «πιείτε» στην υγειά σας…',
    ],
    pageComments: 4,
    comments: [
        [
            {
                msg: '«Αιώνια δέσμιος και δεσμώτης της σμαραγδένιας ματιάς της»',
                author: 'Κίμωνας',
            },
            {
                msg: '«Πρώτα βγαίνει η ψυχή και μετά το χούι. Γι’ αυτό σού κρούω τον κώδωνα του κινδύνου. Να αγαπήσεις τον γαμπρό σου, αφού πρώτα τον περάσεις από κόσκινο, για να δεις την αξία του. Κάποτε θα με θυμηθείς…»',
                author: 'Ιορδάνης',
            },
            {
                msg: '«Αν την αγαπάς, συγχώρεσέ την… Αν η αγάπη δεν σημαίνει συγχώρεση, τότε θα σημάνει συνήθεια και θα είσαι δυστυχισμένος μέσα στην ευτυχισμένη εικόνα σου»',
                author: 'Σμαράγδα',
            },
            {
                msg: '«Κάποιες στιγμές με έκανες να αισθανθώ ιδιαίτερη, αλλά ήταν μόνο στιγμές, Κιμωνάκο μου. Δεν μπορώ να στηρίξω τη ζωή μου σε στιγμές και μάλιστα μ’ ένα υπέρμετρα φιλόδοξο καθίκι σαν εσένα»',
                author: 'Έφη',
            }
        ],
        [
            {
                msg: '«Εγώ δεν είμαι η Σμαράγδα σου; Εγώ δεν είμαι τα σμαραγδένια μάτια σου; Εγώ δεν είμαι η πορεία σου προς τον έρωτα; Μη με μισήσεις Κίμωνα»',
                author: 'Σμαράγδα',
            },
            {
                box: true,
                msg: '',
                author: '',
                msgs: [
                    {
                        msg: '«Αν σου πω, ότι θα σε εκδικηθώ και ότι θα μου το πληρώσεις πολύ ακριβά αυτό που μου κάνεις τώρα, τι θα πεις;»',
                        author: 'Σμαράγδα',
                    },
                    {
                        msg: '«Έλα τώρα! Όχι μεταξύ μας… Σε ξέρω σαν την παλάμη του χεριού μου. Με έχασες Σμαράγδα…»',
                        author: 'Κίμωνας',
                    },
                ]
            },
            {
                msg: '«Κίμωνα, σύνελθε. Είσαι νέος, επιτυχημένος, με κατακτήσεις, λεφτά και όλη τη ζωή μπροστά σου. Όλα αυτά πας να τα γκρεμίσεις για χάρη ενός ερωτικού απωθημένου. Πας καλά;»',
                author: 'Δημοσθένης',
            },
        ],
        [
            {
                msg: '«Μια ζωή μέσα στην πολυτέλεια των υλικών αγαθών μα και μια ζωή μέσα στην ένδεια των συναισθημάτων. Δεν είμαι αυτή που θα ‘θελα να ήμουν. Για πρώτη φορά στη ζωή μου θα κάνω αυτό για το οποίο δεν θα μετανιώσω. Τη Δευτέρα έχω ραντεβού με τον δικηγόρο μου. Τελειώσαμε Κίμωνα»',
                author: 'Άννα',
            },
            {
                msg: '«Ήθελες να σε θαυμάζουν. Προσπάθησες να συγκεντρώσεις στο πρόσωπό σου τους ρόλους της βασίλισσας και του κηφήνα. Φαλλοκράτης εσχάτου βαθμού. Αδιάφορος για συναισθήματα ή για γνήσιες ανθρώπινες σχέσεις. Ξεπουλημένος μέχρι το κόκαλο και μετά… απέραντες φιλανθρωπίες για να εξιλεώσεις τον πόνο που προκάλεσες σε τόσους άλλους. Ένα κτήνος είσαι, Κίμωνα. Ένα σκέτο κτήνος»',
                author: 'Αφροδίτη',
            },
            {
                msg: '«Ποτέ δεν έβαλα τη φιλία μας δεύτερη. Δεν σε πρόδωσα ποτέ, Δήμο! Με όλες ναι, μα όχι με τη γυναίκα του φίλου μου»',
                author: 'Κίμωνας',
            },
        ], [
            {
                msg: '«Το όνομα της γράφτηκε στην παλάμη του αριστερού μου χεριού και έφτασε μέχρι την καρδιά μου. Της έδωσε αίμα τροφοδοτώντας όλο μου το σώμα και μαζί μ’ αυτό, το μυαλό μου και τη συνείδηση μου»',
                author: 'Κίμωνας',
            },
            {
                msg: '«Πάντα πίστευα πως η υπομονή είναι αρετή. Πάντα υποστήριζα ότι εγώ ξέρω να περιμένω. Και πάντα πίστευα πως όλα εδώ πληρώνονται. Ήρθε η ώρα μου. Ήρθε η δική μου ώρα. Πλήρωσα για όλα. Για όλα…»',
                author: 'Κίμωνας',
            },
        ], [
            {
                msg: '«Θα πάρω μαζί μονάχα τις αναμνήσεις μου και τις τύψεις μου. Θα πάρω μαζί μου το ηλιοβασίλεμα από το Καμπί και τη μελωδία από το καμπαναριό του Αγίου. Θα πάρω μαζί μου τις κεντημένες καμάρες της Χώρας και θα ακούω νοσταλγικά τις καντάδες από τα ταβερνάκια στο Αργάσι. Θα κοιτάω από ψηλά τη θέα της Μπόχαλης και απλώνοντας το χέρι μου θα τα πιάνω όλα, το δημαρχείο, την πλατεία του Σολωμού, το λιμάνι, τη μητρόπολη. Θα πίνω λευκό κρασί στο Κρυονέρι αντικρίζοντας την Κυλλήνη. Απ’ τα Στρομφάδια θα περάσω για να αφήσω το μαράζι μου. Και στον κόλπο του Λαγανά θα βοηθήσω μια χελώνα να διασώσει τα αυγά της. ',
                author: 'Κίμωνας'
            }
        ],
    ],
    bookImages: [
        {
            img: SmagargdeniaPhoto1,
            caption: 'Γρηγόρης Βαλτινός, Κυριάκος Κουζούμης, Αλεξάνδρα Παλαιολόγου, Νάνσια Κωσταρά (Ξενοδοχείο Electra Palace Athens, 20/12/2010)',
        },
        {
            img: SmagargdeniaPhoto2,
            caption: '«Σμαραγδένια Φυλακή» Εκδ. Σαββάλας (Ξενοδοχείο Electra Palace Athens, 20/12/2010)',
        },
        {
            img: SmagargdeniaPhoto3,
            caption: 'Ο Γρήγορης Βαλτινός και η Αλεξάνδρα Παλαιολόγου διαβάζουν αποσπάσματα. Προλογίζει η Νάνσια Κωσταρά (Ξενοδοχείο Electra Palace Athens, 20/12/2010)',
        },
        {
            img: SmagargdeniaPhoto4,
            caption: 'Κυριάκος Κουζούμης (Ξενοδοχείο Electra Palace Athens, 20/12/2010)',
        },
        {
            img: SmagargdeniaPhoto5,
            caption: 'Υπογραφή αντιτύπων (Ξενοδοχείο Electra Palace Athens, 20/12/2010)',
        },
        {
            img: SmagargdeniaPhoto6,
            caption: 'Κυριάκος Κουζούμης, Πέγκυ Τρικαλιώτη (Ξενοδοχείο Electra Palace Athens, 20/12/2010)',
        },
        {
            img: SmagargdeniaPhoto7,
            caption: 'Κυριάκος Κουζούμης (Ξενοδοχείο Electra Palace Athens, 20/12/2010)',
        },
    ],
    social: {
        facebook: 'https://www.facebook.com/%CE%A3%CE%BC%CE%B1%CF%81%CE%B1%CE%B3%CE%B4%CE%AD%CE%BD%CE%B9%CE%B1-%CE%A6%CF%85%CE%BB%CE%B1%CE%BA%CE%AE-157891164312142/',
    },
    playlists: [
        {
            id: {
                playlistId: 'PLwd19liGlZ4DNuIYBM_N5YmblvxvGINWM',
            },
            snippet: {
                title: 'Η Σμαραγδένια Φυλακή - Electra Palace 20/12/2010',
            }
        }
    ],
};

export const Orkos = {
    id: 2,
    cover: OrkosCover,
    coverRealSize: OrkosCoverRealSize,
    pageCoverFull: OrkosFullCover,
    title: 'Όρκος Σιωπής',
    shortDescription: `Δυο φιλικά ζευγάρια ταξιδεύουν στο Ναύπλιο αποφασισμένα να δραπετεύσουν από την καθημερινότητά τους. Τέσσερις άνθρωποι αποφασισμένοι να ζήσουν με κάθε τίμημα τη ζωή που ονειρεύονται.`,
    fullDescription: [
        `Δυο φιλικά ζευγάρια ταξιδεύουν στο Ναύπλιο αποφασισμένα να δραπετεύσουν από την καθημερινότητά τους. Τέσσερις άνθρωποι αποφασισμένοι να ζήσουν με κάθε τίμημα τη ζωή που ονειρεύονται.`,
        `Παλιοί γνώριμοι, φίλοι και εραστές, μοιράζονται τις ζωές τους μέσα στο ψέμα, δέσμιοι ενός όρκου σιωπής.`,
        `Ένα όπλο θα εκπυρσοκροτήσει. Το νήμα της ζωής ενός από τους τέσσερις θα κοπεί.`,
        `Απιστίες, δολοπλοκίες, ένοχα μυστικά, ανομολόγητες επιθυμίες έρχονται στο φως. Αυτό που παραμένει κρυμμένο είναι το αμύθητης αξίας Μενταγιόν του Χρυσού Ταύρου, που όλοι θέλουν ν’ αποκτήσουν.`,
        `Το ένα θύμα διαδέχεται το άλλο. Η αυλαία πέφτει και ο αστυνόμος που έχει αναλάβει την εξιχνίαση της υπόθεσης θα σφίξει το χέρι της αλήθειας. Μιας άλλης αλήθειας…`,
        `Το Μενταγιόν του Χρυσού Ταύρου είναι άραγε η αιτία ή η αφορμή για όσα συμβαίνουν στις ζωές των ηρώων;`,
    ],
    landingQuote: '“Η εκδίκηση ικανοποιεί αλλά ποτέ δεν παρηγορεί.”',
    prologue: ['Ο επιχειρηματίας Νικηφόρος Μεταξάς με την συντροφιά της συζύγου του, Αριάδνης και των καλύτερων τους φίλων, Δαμιανού και Έλλης, επιλέγουν το Ναύπλιο για να δραπετεύσουν για λίγες ημέρες από την Αθήνα. Οι παρασκηνιακές τους σχέσεις θα έρθουν στην επιφάνεια, ενώ τα σχέδια τους για το μέλλον κατοικούν ακόμα μέσα στα σπήλαια του μυαλού τους. Το παρελθόν και το παρόν τους θα γίνουν οι δυο όψεις του ίδιου νομίσματος και κανείς δεν θα είναι σε θέση να ξέρει, αν τελικά αυτό θα φέρει «κορώνα ή γράμματα». Απιστίες, δολιότητα, ιδιοτέλεια, πάθος, ζήλεια και πολλά άλλα θα είναι τα συστατικά της έκρηξης που θα προκληθεί από την χημεία των χαρακτήρων τους. Φορώντας τα προσωπεία της άγνοιας και της αθωότητας θα προσπαθήσουν να δραπετεύσουν από τα δεσμά των τύψεων και να τραβήξουν ευθεία στο μονοπάτι της ζωής. Μα σε αυτό το μονοπάτι όλοι τους επιθυμούν να έχουν στην κατοχή τους έναν αμύθητο θησαυρό. Το Μενταγιόν του Χρυσού Ταύρου… Μα αυτό το μονοπάτι φέρει ένα βαθύ στίγμα, που ακούει στο όνομα «Όρκος σιωπής»…'],
    writersMessagePhoto: OrkosWritersPhoto,
    writersMessage: [
        'Από έφηβος λάτρευα τα βροχερά βράδια. Ξενυχτούσα και κοιτούσα απ’ το παράθυρο τις ψιχάλες. Άρπαζα την πένα μου και βυθιζόμουν στον ωκεανό των λέξεων με σκοπό να ανακαλύψω την Ατλαντίδα της ψυχής μου. Τώρα πια έχω μεγαλώσει. Κι όμως… τα χούγια μου δεν άλλαξαν. Για δες τι σου είναι η μνήμη! Σαν χθες τα θυμάμαι όλα. Ήταν βράδυ και η μπόρα δεν έλεγε να κοπάσει. Περπάτησα στον κήπο του πρόσφατου παρελθόντος μου και κάπου εκεί βρήκα κάτι γλάστρες με ξεραμένα φυτά. «Κρίμα» είπα, και αποφάσισα να τις ποτίσω με… μελάνι. Έρωτες, αγάπες, φιλίες, προδοσίες, απωθημένα και όρκοι αναπτύχθηκαν μέσα σε λίγους μήνες και έγιναν… σελίδες. Σαν σήκωσα έπειτα το βλέμμα μου στον οργισμένο ουρανό διέκρινα την χρυσοκεντημένη πανσέληνο να με λούζει με το φως της δικαίωσης. Τελικά ναι… τα βράδια μου δικαιώθηκαν',
        'Στον κήπο της δικής σας βιβλιοθήκης έχετε τούτες τις γλάστρες. Ας τις ποτίσετε με ο, τι νερό εσείς επιθυμείτε.',
    ],
    pageComments: 4,
    comments: [
        [
            {
                msg: '«Τέχνη σου το ψέμα, αλήθεια σου ο κυνισμός, θεός σου το χρήμα και κάπου μέσα σ’ όλα αυτά έβαζες και εμένα. Τώρα που μιλάω μόνη μου και αγνοώ αν με ακούς, θα σ’ τα πω όλα. Τέτοια που ήμουν, καλά μου έκανες…»',
                author: 'Αριάδνη',
            },
            {
                msg: '«Μόνη μου στα δυσάρεστα, μα μόνη μου και στα ευχάριστα. Γιατί; Πώς τα έκανες έτσι ρε Γρηγόρη; Έδωσες μια κλωτσιά και τα τίναξες όλα στον αέρα. Μας εγκατέλειψες. Τα κατάφερα όμως. Μόνη μου. Ο Θάνος δουλεύει και ο Κάρολος τελείωσε το πανεπιστήμιο. Τα άφησες αγοράκια και έγιναν άντρες…»',
                author: 'Σοφία',
            },
            {
                msg: '«Θα πάρουμε όρκο σιωπής και δεν θα μιλήσουμε ποτέ και σε κανέναν για ό,τι συνέβη σήμερα. Μ’ ακούτε;»',
                author: 'Δαμιανός',
            },
        ],

        [
            {
                box: true,
                msg: '',
                author: '',
                msgs: [
                    {
                        msg: '«Σε πληγώνω από άμυνα»',
                        author: 'Φένια',
                    },
                    {
                        msg: '«Έτσι επιβεβαιώνεσαι;»',
                        author: 'Κάρολος',
                    },
                    {
                        msg: '«Έτσι νομίζω πως παίρνω το πάνω χέρι»',
                        author: 'Φένια',
                    },
                ]
            }
        ],

        [
            {
                msg: `«Τζόκεϊ λέμε το στρατιωτικό καπέλο μας. Όλοι από μέσα έχουν γράψει και κάτι. Άλλοι μετρούν τις 'μέρες τους. Άλλοι το όνομα της κοπελιάς τους. Άλλοι στιχάκια από τραγούδια. Εγώ; Εγώ έγραψα μια φράση. "Έίναι γλυκό το πιοτό της ζωής"»`,
                author: 'Κάρολος',
            },
        ],

        [
            {
                box: true,
                msg: '',
                author: '',
                msgs: [
                    {
                        msg: `«Όταν βρίσκεσαι στο πάτωμα, κοιτάς το ταβάνι και ονειρεύεσαι. Θέτεις στόχους και μάχεσαι για να τους κατακτήσεις. Τους διεκδικείς και σαν έρθει η ώρα να τους χαρείς, η απόλαυσή τους έχει όχι μονάχα ένταση αλλά και διάρκεια. Τώρα που μιλάμε είσαι στο πάτωμα και δοκιμάζεσαι, ταλαιπωρείσαι. Είναι πολύ εύκολο να κοιτάς τη ζωή από το ταβάνι. Πολύ βολικό! Μα το πάτωμα είναι το σχολείο. Το πάτωμα είναι η αλήθεια… Κάποια μέρα θα με θυμηθείς…»`,
                        author: 'Κάρολος',
                    },
                    {
                        msg: `«Έχεις πέσει ποτέ στο πάτωμα;»`,
                        author: 'Λοχαγός Κανετάκης',
                    },
                    {
                        msg: `«Χμ! Ας πούμε, ότι ξέρω πόσα ακριβώς είναι τα πλακάκια του δαπέδου από το υπνοδωμάτιό μου»`,
                        author: 'Κάρολος',
                    },
                ]
            }
        ],

        [
            {
                box: true,
                msg: '',
                author: '',
                msgs: [
                    {
                        msg: `«Πετάς στον δρόμο όχι εμένα, αλλά την ίδια σου την ψυχή. Γιατί Αθηναΐδα;»`,
                        author: 'Άλκης',
                    },
                    {
                        msg: `«Ο δρόμος είναι το σπίτι μου. Σ’ αυτόν μεγάλωσα, σ’ αυτόν μαθήτευσα και σ’ αυτόν θα τερματίσω κάποτε»`,
                        author: 'Αθηναΐδα',
                    },
                    {
                        msg: `«Πάρε με τότε, μαζί σου»`,
                        author: 'Άλκης',
                    },
                    {
                        msg: `«Δεν έχω ανάγκη από συντροφιά. Γαλουχήθηκα στη μοναξιά. Στην αρχή την αγνοείς, έπειτα την κοροϊδεύεις, μετά την πολεμάς και στο τέλος… σε νικάει. Είναι αργά πια να αλλάξω στρατόπεδο…»`,
                        author: 'Αθηναΐδα',
                    },
                ]
            }
        ],

        [
            {
                msg: `«Η ζωή είναι γένους θηλυκού και πρώτα αμάρτησε η γυναίκα. Η ζωή είναι γένους θηλυκού και αν γυναίκα γέννησε Θεό, γέννησε αρσενικό Θεό. Είμαι και εγώ παιδί ενός κατώτερου Θεού, Άλκη, ενός Θεού που δεν μ’ αγάπησε… ποτέ!»`,
                author: 'Αθηναΐδα',
            },
            {
                msg: `«Τώρα πια είμαι μια κυρία. Είμαι η κυρία Αθηναΐδα. Για κάποιους η Αθηναΐδα του έρωτα. Και όχι άδικα. Ξεπέταξα πολλά αμούστακα. Έκανα άνδρες πολλούς εφήβους που είχαν θηλυπρεπή συμπεριφορά. Έσωσα γάμους, μιας και οι άνδρες εκτόνωναν τα ζωώδη ένστικτά τους πάνω μου. Πλούσια τα έργα και οι ημέρες μου. Πολυσέλιδα!»`,
                author: 'Αθηναΐδα',
            },
        ],

        [
            {
                msg: `«Ξέρεις πώς είναι να σε αγκαλιάζουν; Ξέρεις πώς είναι να σε κοιτάνε στα μάτια και να σού μιλάνε μονάχα μέσα από τη σιωπή; Ξέρεις πώς είναι να κάνεις την υπέρβαση; Να ξεπερνάς τα όριά σου; Νιώθεις θεός μέσα στη θνητή σου φύση. Κάποτε μου είπες, πως οι πόρνες σε κάνουν να νιώθεις αυτοκράτορας. Κι όμως οι αυτοκράτορες είναι κατώτεροι από τους θεούς. Είμαι θεός δίπλα της. Ένας θεός που την αγάπησε και που ό,τι θαύμα μπορούσε να κάνει, το έκανε μονάχα για χατίρι της…»`,
                author: 'Άλκης',
            },
            {
                msg: `«Τιμωρήθηκα για την αλαζονεία μου, σαν ένας άλλος Ίκαρος…»`,
                author: 'Γρηγόρης',
            },
        ],

        [
            {
                box: true,
                msg: '',
                author: '',
                msgs: [
                    {
                        msg: `«Τελικά εσείς οι δικηγόροι είστε μεγάλα καθίκια!»`,
                        author: 'Δαμιανός',
                    },
                    {
                        msg: `«Αυτά τα καθίκια έχεις ανάγκη τώρα…»`,
                        author: 'Ερρίκος',
                    },

                    {
                        msg: `«Δεν σας χώνεψα ποτέ μου, ούτε εσάς, ούτε την αστεία επιστήμη σας…»`,
                        author: 'Δαμιανός',
                    },
                    {
                        msg: `«Τότε γιατί αποφοίτησες της νομικής;»`,
                        author: 'Ερρίκος',
                    },

                ]
            }
        ],

        [
            {
                msg: `«Ακόμα δεν μπορώ να κοιμηθώ τα βράδια. Τύψεις! Πράξεις νεότητας που αποφέρουν μονάχα τύψεις!»`,
                author: 'Φοίβος',
            },
        ],
    ],
    bookImages: [
        {
            img: OrkosPhoto1,
            caption: 'Ελένη Δήμου, Κυριάκος Κουζούμης, Ιωσήφ Μαρινάκης, Νίκος Γαλανός, Πέγκυ Τρικαλιώτη (Ξενοδοχείο N.J.V Athens Plaza, 20/2/2012)',
        },
        {
            img: OrkosPhoto2,
            caption: 'Διαβάζουν αποσπάσματα: Ελένη Δήμου, Ιωσήφ Μαρινάκης, Νίκος Γαλανός. Προλογίζει: Πέγκυ Τρικλιώτη (Ξενοδοχείο N.J.V Athens Plaza, 20/2/2012)',
        },
        {
            img: OrkosPhoto3,
            caption: 'Πέγκυ Τρικαλιώτη, Κυριάκος Κουζούμης (Ξενοδοχείο N.J.V Athens Plaza, 20/2/2012)',
        },
        {
            img: OrkosPhoto4,
            caption: 'Κυριάκος Κουζούμης (Ξενοδοχείο N.J.V Athens Plaza, 20/2/2012)',
        },
        {
            img: OrkosPhoto5,
            caption: 'Από την παρουσίαση του «Όρκος Σιωπής» (Ξενοδοχείο N.J.V Athens Plaza, 20/2/2012)',
        },
        {
            img: OrkosPhoto6,
            caption: 'Νάνσια Κωσταρά, Αλεξάνδρα Παλαιολόγου, Κυριάκος Κουζούμης (Ξενοδοχείο N.J.V Athens Plaza, 20/2/2012)',
        },
        {
            img: OrkosPhoto7,
            caption: 'Ιωσήφ Μαρινάκης, Ελένη Δήμου, Ηρώ Μουκίου, Κυριάκος Κουζούμης, Νίκος Γαλανός, Πέγκυ Τρικαλιώτη (Ξενοδοχείο N.J.V Athens Plaza, 20/2/2012)',
        },
        {
            img: OrkosPhoto8,
            caption: 'Κυριάκος Κουζούμης, Χριστίνα Αλεξανιάν (Ξενοδοχείο N.J.V Athens Plaza, 20/2/2012)',
        },
        {
            img: OrkosPhoto9,
            caption: 'Ηρώ Μουκίου (Ξενοδοχείο N.J.V Athens Plaza, 20/2/2012)',
        },
        {
            img: OrkosPhoto10,
            caption: '«Όρκος Σιωπής» Εκδ. Σαββάλας (Ξενοδοχείο N.J.V Athens Plaza, 20/2/2012)',
        },
        {
            img: OrkosPhoto11,
            caption: 'Κυριάκος Κουζούμης, Κατερίνα Διδασκάλου, Στέλιος Καλαθάς (Public Πειραιά, 26/4/2012)',
        },
        {
            img: OrkosPhoto12,
            caption: '«Όρκος Σιωπής» στα Public (Public Πειραιά, 26/4/2012)',
        },
        {
            img: OrkosPhoto13,
            caption: 'Διαβάζουν αποσπάσματα: Κατερίνα Διδασκάλου, Στέλιος Καλαθάς (Public Πειραιά, 26/4/2012)',
        },
        {
            img: OrkosPhoto14,
            caption: 'Υπογραφή αντιτύπων (Public Πειραιά, 26/4/2012)',
        },
    ],
    social: {
        facebook: 'https://www.facebook.com/%CE%8C%CF%81%CE%BA%CE%BF%CF%82-%CE%A3%CE%B9%CF%89%CF%80%CE%AE%CF%82-205846142832234/',
    },
    playlists: [
        {
            id: {
                playlistId: 'PLwd19liGlZ4Bv1d3cZVCLF--6DivS7XXt',
            },
            snippet: {
                title: 'Όρκος Σιωπής - Public Πειραιά 26/4/2012',
            }
        },
        {
            id: {
                playlistId: 'PLwd19liGlZ4Ambna13vD36m9-NU1H4pbM',
            },
            snippet: {
                title: 'Όρκος Σιωπής - N.J.V. Athens Plaza 20/2/2012',
            }
        },
    ],
};

export const Xeirokrotima = {
    id: 3,
    cover: XeirokrotimaCover,
    coverRealSize: XeirokrotimaCoverRealSize,
    pageCoverFull: XeirokrotimaFullCover,
    title: 'Το Τελευταίο Χειροκρότημα',
    shortDescription: `Αν και απόγονος μιας εκ των σημαντικότερων εφοπλιστικών οικογενειών ο Πάρης Αλεξάνδρου θα βρεθεί στον χώρο του θεάτρου. Κάπου στο Θησείο, ο φτερωτός θεός Έρωτας τοξεύει στον πυρήνα της καρδιάς του το κοφτερό βέλος της σαγηνευτικής Ρωξάνης. Ο Πάρης αγνοώντας την αλήθεια των παρασκηνίων, θα πορευτεί στο σανίδι της ζωής με μόνο γνώμονα τον αυτοσεβασμό του.`,
    fullDescription: [
        `Αν και απόγονος μιας εκ των σημαντικότερων εφοπλιστικών οικογενειών ο Πάρης Αλεξάνδρου θα βρεθεί στον χώρο του θεάτρου. Κάπου στο Θησείο, ο φτερωτός θεός Έρωτας τοξεύει στον πυρήνα της καρδιάς του το κοφτερό βέλος της σαγηνευτικής Ρωξάνης. Ο Πάρης αγνοώντας την αλήθεια των παρασκηνίων, θα πορευτεί στο σανίδι της ζωής με μόνο γνώμονα τον αυτοσεβασμό του.`,
        `Με τη Ρωξάνη θα χαράξουν διαφορετικά μονοπάτια στο όρος της τέχνης.Εκείνος θα αφήσει για αποτύπωμα το αίμα της ψυχής του, ενώ εκείνη την αρρωστημένη και ακραία φιλοδοξία της. Εκείνος θα καρφώσει τη σημαία του στην ιερή κορυφή του θεάτρου, ενώ εκείνη θα γκρεμιστεί στη χαράδρα της δόξας...`,
        `Η προδοσία θα χτυπήσει ανελέητα τον Πάρη, μα θα του δώσει την ύστατη στιγμή το φιλί της ζωής η Μαντώ, και η παρελθοντική αόρατη αγάπη της θα ενσαρκωθεί, κάνοντας τον φοίνικα της ελπίδας να αναγεννηθεί από τις στάχτες του…`,
        `Ένα μυθιστόρημα-χείμαρρος όπου κυριαρχούν τα ανθρώπινα πάθη, ο έρωτας, η φιλοδοξία, η προδοσία και η αναζήτηση του σκοπού της ζωής...`,
    ],
    landingQuote: '“Ενα κέρμα, δύο όψεις: Η Δόξα και η Ταπεινοφροσύνη”',
    prologue: ['Σε μια ιδιωτική κλινική της Κηφισιάς μια γυναίκα παλεύει σκληρά με την αποσύνθεση. Στον ουρανό χαράζει η πρώτη του Δεκέμβρη και ένα λευκό σεντόνι απλώνεται σ’ όλη την Αθήνα. Η ασθενής δεν περιμένει κανέναν. Ξαφνικά όμως, η πόρτα του δωματίου ανοίγει και μια νεαρή κοπέλα κάνει ένα βήμα μπροστά. Αντικρίζει την ασθενή και στο φρικιαστικό θέαμα ανατριχιάζει. Επόμενο βήμα. Στέκεται αμήχανη απέναντί της και σφίγγει τις γροθιές της. Τελευταίο βήμα. Αποφασισμένη αρπάζει τη ρομφαία του λόγου και κάθεται πλάι της. Έχει τόσα να της πει… και θα της τα πει. Όλα! Έχει όμως και τόσα να τη ρωτήσει… Λίγο πριν πέσει η αυλαία η ασθενής θα επιθυμήσει τη συγχώρεση, ενώ η επισκέπτης τη δικαίωση. Για ποια θα ηχήσει το χειροκρότημα…;'],
    writersMessagePhoto: XeirokrotimaWritersPhoto,
    writersMessage: [
        'Ήταν αργά το βράδυ όταν καθόμουν στο γραφείο μου και ετοιμαζόμουν να βάλω μελάνι στην πένα μου. Ξαφνικά, οι σταγόνες της δυνατής βροχής ήχησαν στ’ αυτιά μου σαν δυνατά και δυσερμήνευτα θεατρικά κουδουνίσματα. Πρώτο! Πλησίασα το ανοιχτό τζαμιλίκι και αναστέναξα. Δεύτερο! Μαγνητίστηκα απ’ την ευωδία του μουσκεμένου χώματος και έφτασα στην εξώπορτα. Τρίτο! Πήρα μια βαθιά ανάσα και βγήκα στο παλκοσένικο του δρόμου. Μόνος! Η βροχή με αγκάλιασε και μου χάρισε το πιο συγκινητικό φιλί της. Έστρεψα τα μάτια μου στην αυλαία του ουρανού και την είδα να σηκώνεται! Χειροκρότησα το κενό και άνοιξα το στόμα μου για να πιω τα δάκρυα της νύχτας. Σαν βούτηξα έπειτα την πένα μου στο μελανοδοχείο της καρδιάς, έγραψα τις πρώτες λέξεις, που πέρασαν απ’ τα βρεγμένα χωράφια του μυαλού μου. Ευτυχία, γαλήνη, δικαίωση!',
        'Στα χέρια σας κρατάτε εκείνες τις σταγόνες. Ας τις τοποθετήσετε σε όποιο δοχείο εσείς επιθυμείτε. ',
    ],
    pageComments: 4,
    comments: [
        [
            {
                msg: '«Το θέατρον είναι θρησκεία, ένας μικρός θεός. Τους καλλιτέχνες που τον υπηρετούν θα τους αγκαλιάσει. Μα τους άλλους θα τους ξεβράσει το ορμητικό κύμα του σανιδιού».',
                author: 'Καθηγητής Ιωάννης Φέρρης',
            },
            {
                msg: '«Θέατρο έπαιξα εχθές το βράδυ. Μια χαρά ήμουν. Καμμία αδιαθεσία! Τί τα θες; Έπαιξα και έχασα»',
                author: 'Μαντώ',
            },
            {
                msg: '«Κάθε βράδυ τον ρωτούσα «μπαμπά, πού είναι η μαμά; Πότε θα έρθει;» και εκείνος μου έλεγε «δεν θα έρθει κορίτσι μου. Είναι μακριά! Είναι στον Χριστούλη, και όποιος πάει στον Χριστούλη δεν γυρίζει ποτέ πίσω». Ψέματα! Ήταν όλα ψέματα!»',
                author: 'Ρωξάνη',
            },
        ],

        [
            {
                box: true,
                msg: '',
                author: '',
                msgs: [
                    {
                        msg: '«Ο γιος σου είναι άπειρος, ένας ερασιτέχνης! Η ζωή του θα βυθιστεί σαν ακυβέρνητο καράβι, αφού εκείνος δεν είναι άξιος να γίνει ο καπετάνιος της»',
                        author: 'Νέστορας',
                    },
                    {
                        msg: '«Να θυμίσω σε σένα που εδώ και χρόνια ασχολείσαι με τα πλοία, ότι ο Νώε ήταν ερασιτέχνης, ενώ τον Τιτανικό τον έφτιαξε επαγγελματίας…»',
                        author: 'Άσπα',
                    },
                ],
            },
            {
                msg: '«Τιμή σου είναι μονάχα το χειροκρότημα. Τιμή για έναν ηθοποιό είναι να τον πουν καλό, να πουν πως άγγιξε τις χορδές από την άρπα της ψυχής τους. Τιμή είναι να σου μιλούν στον ενικό, να σε λένε με το όνομά σου»',
                author: 'Καθηγήτρια Μαίρη Δενεξή',
            },
        ],

        [
            {
                msg: '«Στο σκάκι της ζωής ο πατέρας σου πάντα βασιλιάς! Ο πατέρας σου πάντα έκανε το ρουά, αλλά εγώ θα κάνω το ματ! Και όλα θα τελειώσουν!»',
                author: 'Άσπα',
            },
            {
                msg: '«Μη φύγεις! Οι χαρές της ζωής σου είναι εδώ μέσα, κοιμούνται στα κρεβάτια τους. Εκεί που θα πας θα ζήσεις την ένταση, μα θα στερηθείς τη διάρκεια. Αξίζει; Σε ρωτάω αξίζει;»',
                author: 'Άσπα',
            },
            {
                msg: '«Σ’ ένα υποτιθέμενο δικαστήριο η κατηγορία που θα μου απαγγελλόταν θα ήταν αυτή της ηθικής αυτουργίας και όχι της δολοφονίας. Γιατί δεν σε σκότωσα, παρά σε άφησα να πεθάνεις… Τελικά, είμαι η πιο τρανή μαθήτριά σου»',
                author: 'Άσπα',
            },
        ],

        [
            {
                box: true,
                msg: '',
                author: '',
                msgs: [
                    {
                        msg: '«Είμαι και εγώ ένα άγαλμα, Πάρη. Άψυχο δημιούργημα ενός έμψυχου δημιουργού, που δεν μου χάρισε ψυχή με την ανάσα του, παρά με άφησε μονάχα να δείχνω… κατ’ εικόνα και ομοίωση…»',
                        author: 'Μελίνα',
                    },
                    {
                        msg: '«Αγάλματα πέρασαν στην ιστορία και λατρεύτηκαν ως είδωλα θεών. Εσύ είσαι η δική μου θεά και εγώ σε λατρεύω!»',
                        author: 'Πάρης',
                    },
                ],
            },
            {
                msg: '«Στη ρουλέτα της ζωής τα ποντάραμε όλα και ο κρουπιέρης Θεός μας τα πήρε. Όχι με τη μία, αλλά λίγα λίγα… για να πονάμε περισσότερο, για να μας τιμωρεί αργά και βασανιστικά…»',
                author: 'Δημήτρης',
            },
        ],

        [
            {
                msg: '«Ήσουν η μάνα του χαρτιού! Πέντε βιαστικών φράσεων, δεκάδων λέξεων χωρίς ηχόχρωμα, χωρίς βλέμμα, χωρίς χείλη. Ήσουν η μάνα της μελάνης που απίθωσε τη μητρότητά της πάνω σε μια πρόχειρη κόλλα»',
                author: 'Χριστίνα',
            },
            {
                msg: '«Τώρα σ’ έχω στα χέρια μου και αρνούμαι πεισματικά να τα τραβήξω από πάνω σου. Τώρα η άλλοτε αδαής νοημοσύνη μου έχει δώσει τη θέση της στη γνώση. Και το μόνο που γνωρίζω είναι πως δεν θέλω να σε χάσω ποτέ…»',
                author: 'Πάρης',
            },
        ],

        [
            {
                msg: '«Στην τσόχα της ζωής ανακάτεψα τα φύλλα και άφησα τη Ρωξάνη να κόψει. Τράβηξε το δέκα το καλό, ενώ εγώ τον άσσο και το δέκα μπαστούνι και… Πέθανα! Μα με τους κλέφτες δεν αξίζει να παίζεις τίμια. Τώρα θα ανακατέψω ξανά την τράπουλα και θα φροντίσω να έχω κρύψει στο μανίκι μου έναν άσσο!»',
                author: 'Πάρης',
            },
            {
                msg: '«Κάποτε πάγωναν όλοι μονάχα μ’ ένα βλέμμα μου, Πάρη. Απ’ το ίδιο βλέμμα πάγωσα και εγώ, όταν κοίταξα το είδωλό μου στον καθρέφτη. Σαν μία άλλη Μέδουσα!»',
                author: 'Ρωξάνη',
            },
        ],
    ],
    bookImages: [
        {
            img: XeirokrotimaPhoto1,
            caption: 'Σωκράτης Αλαφούζος, Παναγιώτα Βλαντή, Πέγκυ Σταθακοπούλου, Κυριάκος Κουζούμης, Ηρώ Μουκίου, Γιάννης Βόγλης (Θέατρο Διάνα, 7/4/2014)',
        },
        {
            img: XeirokrotimaPhoto2,
            caption: 'Διαβάζουν αποσπάσματα: Σωκράτης Αλαφούζος, Παναγιώτα Βλαντή, Πέγκυ Σταθακοπούλου, Γιάννης Βόγλης. Προλογίζει: Ηρώ Μουκίου (Θέατρο Διάνα, 7/4/2014)',
        },
        {
            img: XeirokrotimaPhoto3,
            caption: 'Από την παρουσίαση του μυθιστορήματος «Το Τελευταίο Χειροκρότημα» (Θέατρο Διάνα, 7/4/2014)',
        },
        {
            img: XeirokrotimaPhoto4,
            caption: 'Κυριάκος Κουζούμης (Θέατρο Διάνα, 7/4/2014)',
        },
        {
            img: XeirokrotimaPhoto5,
            caption: 'Υπογραφή αντιτύπων (Θέατρο Διάνα, 7/4/2014)',
        },
        {
            img: XeirokrotimaPhoto6,
            caption: 'Κυριάκος Κουζούμης (Θέατρο Διάνα, 7/4/2014)',
        },
        {
            img: XeirokrotimaPhoto7,
            caption: 'Κυριάκος Κουζούμης (Θέατρο Διάνα, 7/4/2014)',
        },
        {
            img: XeirokrotimaPhoto8,
            caption: '«Το Τελευταίο Χειροκρότημα» Εκδ. Ωκεανός (Θέατρο Διάνα, 7/4/2014)',
        },
        {
            img: XeirokrotimaPhoto9,
            caption: '«Το Τελευταίο Χειροκρότημα» Εκδ. Ωκεανός (Hondos Center, 18/6/2014)',
        },
    ],
    social: {
        facebook: 'https://www.facebook.com/toteleftaioxeirokrotima/',
    },
    playlists: [
        {
            id: {
                playlistId: 'PLwd19liGlZ4CpZg4SYBrBgU2B4RXzLGZn',
            },
            snippet: {
                title: 'Το Τελευταίο Χειροκρότημα  - Θέατρο Διάνα 7/4/2014',
            }
        },
    ],
};

export const Dikaiwsi = {
    id: 4,
    cover: DikaiwsiCover,
    coverRealSize: DikaiwsiCoverRealSize,
    pageCoverFull: DikaiwsiFullCover,
    title: 'Η Δικαίωση της Αθωότητας',
    shortDescription: `Το αιχμηρό μονοπάτι του μαιευτήρα-γυναικολόγου Ίωνα Χατζηπαύλου θα ενωθεί με αυτό του δικηγόρου Μάριου Δίκαρου σε μια καφετέρια στο λιμάνι της συμπρωτεύουσας και θα διασταυρωθεί με αυτό του Άγη Δαμάσκου. Οι τρεις νεαροί άνδρες, ενωμένοι με τα αόρατα δεσμά μιας σατανικής φιλίας, θα πορευθούν στη ζωή με μοναδικό θεό τους το χρήμα. Κάπηλοι της ανθρωπιάς θα δράσουν παρασκηνιακά εις βάρος αθώων παιδικών ψυχών... Θα δράσουν σαν θύτες.`,
    fullDescription: [
        `Το αιχμηρό μονοπάτι του μαιευτήρα-γυναικολόγου Ίωνα Χατζηπαύλου θα ενωθεί με αυτό του δικηγόρου Μάριου Δίκαρου σε μια καφετέρια στο λιμάνι της συμπρωτεύουσας και θα διασταυρωθεί με αυτό του Άγη Δαμάσκου. Οι τρεις νεαροί άνδρες, ενωμένοι με τα αόρατα δεσμά μιας σατανικής φιλίας, θα πορευθούν στη ζωή με μοναδικό θεό τους το χρήμα. Κάπηλοι της ανθρωπιάς θα δράσουν παρασκηνιακά εις βάρος αθώων παιδικών ψυχών... Θα δράσουν σαν θύτες.`,
        `Μερικά χρόνια αργότερα η σατανική τριανδρία εξυφαίνει ένα νέο βάρβαρο και ανελέητο σχέδιο. Ενώ το ένα θύμα θα διαδέχεται το άλλο, ο οφθαλμός της Δίκης θα καραδοκεί αθέατος μα και αμείλικτος. Οι απαντήσεις στα μυριάδες «γιατί» είναι κρυμμένες στο βαθύ παρελθόν...`,
        `Ο γραπτός νόμος θα εκπροσωπηθεί από τον έμπειρο, αεικίνητο, γηραιό αστυνόμο Διονύσιο Δελόγκα. Ο άγραφος νόμος, όμως, από τον άνθρωπο που έχει τραβήξει τη σκανδάλη... Ποιος Νόμος θα… δικαιωθεί;`,
        `Ένα συγκλονιστικό ψυχολογικό θρίλερ, βασισμένο σε πραγματικά περιστατικά που κόβουν την ανάσα!`,
    ],
    landingQuote: '“Πληθώρα ανατροπών, συγκλονιστικές αποκαλύψεις και αναπάντεχο φινάλε.”',
    prologue: ['Ύστερα από αρκετά χρόνια ο μαιευτήρας-γυναικολόγος Ίωνας Χατζηπαύλου, ο δικηγόρος Μάριος Δίκαρος και ο ξενοδόχος Άγης Δαμάσκος αποφασίζουν να αναθερμάνουν τις σχέσεις της παλιάς λυκοφιλίας τους. Στην καρδιά του χειμώνα συναντιούνται στο σύγχρονο στολίδι του Σουνίου, στο πολυτελές ξενοδοχείο «Παλέ ντε Ρέγκις». Μετά τις τυπικές χειραψίες και τα λόγια του καλωσορίσματος απομονώνονται στο γραφείο του διευθυντή και ξεκινούν τον δικό τους «μυστικό δείπνο». Μιλούν με γρίφους, μα οι ίδιοι καταλαβαίνουν άριστα τα ευκόλως εννοούμενα. Φλερτάρουν ξανά με τον Καιάδα της αισχροκέρδειας και ένα ακόμα εγκληματικό τους σχέδιο μετρά αντίστροφα για την εφαρμογή του. Υποστηρίζουν πως θα είναι η τελευταία φορά! Θα προλάβουν άραγε να το φέρουν εις πέρας ή τα ουρλιαχτά κάποιων αθώων ψυχών θα αναστηθούν απαιτώντας δικαιοσύνη;'],
    writersMessagePhoto: DikaiwsiWritersPhoto,
    writersMessage: [
        'Μεσάνυχτα! Άκουσα ένα παιδικό κλάμα. Τι ήχος! Μελωδία και ουρλιαχτό μαζί. Έβγαλα απ’ το ντουλάπι τον Άτλαντα και τον άπλωσα στο πάτωμα. Έβαλα μπροστά τις μηχανές απ’ το καράβι του ονείρου μου και είδα τα φουγάρα των σωθικών μου να βγάζουν καπνό. Άρπαξα πένα και σάλπαρα πάνω στις θάλασσες των λευκών σελίδων μου. Παιδικά χαμόγελα, παλμοί καρδιάς, φτερουγίσματα ψυχής, χειρουργικά νυστέρια και φλόγες συνείδησης έγιναν τα κύματα που με έγδερναν ακατάπαυστα για μήνες. Στον ορίζοντα ενωνόταν η φουρτουνιασμένη θάλασσα με τον αγριεμένο ουρανό, μα εγώ διέκρινα ένα ξέφωτο γης. Στεριά απ’ το σύμπλεγμα των νήσων των μακάρων. Είδα το φως απ’ τον φάρο του λιμανιού να αναβοσβήνει ζωγραφίζοντάς μου ένα υδάτινο μονοπάτι. Πάτησα το πόδι μου στoν προβλήτα και έσκυψα το κεφάλι. Με υποδέχτηκε ένα γαλήνιο αερικό. Σαν εγώ του έσφιξα το χέρι, εκείνο μου έδωσε λίγο απ’ το νέκταρ της ευτυχίας και λίγη απ’ την αμβροσία της δικαίωσης.',
        'Στον ωκεανό της βιβλιοθήκης σας έχετε εκείνα τα κύματα. Ας σαλπάρετε πάνω τους με όποιο καράβι εσείς επιθυμείτε…',
    ],
    pageComments: 4,
    comments: [
        [
            {
                msg: '«Ήμουν αιώνια δέσμιος της σμαραγδένιας ματιάς της»',
                author: 'Ίωνας',
            },
            {
                msg: '«Κουβαλάω ακόμη, και θα κουβαλάω για μια ζωή, τους δύο σταυρούς μου, τον χαμό της μάνας μου και τον βιασμό μου.»',
                author: 'Ρένα',
            },
            {
                msg: '«Είμαι και εγώ ένα θύμα, Άγη! Το θύμα ενός ανώτερου και ισχυρότερου θύτη, θηλυκού γένους. Είμαι το θύμα της Φύσης!»',
                author: 'Φραντζέσκα',
            },
        ],
        [
            {
                box: 1,
                msg: '',
                author: '',
                msgs: [
                    {
                        msg: '«Πληροφορήθηκα πως είσαι μία από τους κληρονόμους του»',
                        author: 'Διονύσης',
                    },
                    {
                        msg: '«Τα συγχαρητήριά μου στον πληροφοριοδότη σου. Ή μήπως θα έπρεπε να πω στον ρουφιάνο σου;»',
                        author: 'Ελεάνα',
                    },
                    {
                        msg: '«Πες ό,τι θες. Άλλωστε, όλοι αγαπούν τη ρουφιανιά. Τον ρουφιάνο πάλι, ελάχιστοι...»',
                        author: 'Διονύσης',
                    },
                ]
            }
        ],
        [
            {
                msg: '«Κι αν είναι αμαρτίες αυτά που ξεστομίζω, ας πληρώσω! Άλλωστε, πάντα αυτό έκανα! Πάντα ξεπλήρωνα! Τα χρέη που μου άφησε η αξιοπρέπειά μου, ξεπλήρωνα...»',
                author: 'Σοφία',
            },
            {
                msg: '«Η ανασφάλεια, λένε, φοράει μάσκα μεγαλοπρέπειας κι εγώ καλή μου, έδειχνα πολύ μεγαλοπρεπής, πολύ!»',
                author: 'Μάριος',
            },
            {
                msg: '«Τώρα δεν μου απομένει τίποτε άλλο απ’ το να συνεχίσω τη ζωή μου με την ίδια ανάμνησή σου. Άλλωστε, εδώ και χρόνια αποτελείς ανάμνηση για μένα...»',
                author: 'Άγης',
            },
        ],
        [
            {
                box: 1,
                msg: '',
                author: '',
                msgs: [
                    {
                        msg: '«Η ιστορία δεν έχει μόνο αλγεβροποιημένους κανόνες. Έχει και γεωμετρικά αξιώματα και εσύ ήσουν πάντα ένα σχήμα ταυτόσημο του κύκλου. Δεν θα τετραγωνοποιηθείς ποτέ. Θα μείνεις πάντα κύκλος, να κυλιέσαι, όπου και αν σε σπρώξω εγώ!»',
                        author: 'Μάριος',
                    },
                    {
                        msg: '«Αυτό λοιπόν, είναι το αντίο μας;»',
                        author: 'Άγης',
                    },
                    {
                        msg: '«Όχι! Αυτό είναι το εις το επανιδείν μας!»',
                        author: 'Μάριος',
                    },
                ]
            }
        ],
        [
            {
                msg: '«Στο χείλος της εμμηνόπαυσης, στους πρόποδες της οστεοπόρωσης εγώ γίνομαι μάνα και το μόνο που ξέρω είναι ότι φοβάμαι. Αγνοώ το γιατί, μα παραδέχομαι ότι φοβάμαι!»',
                author: 'Σάρα',
            },
        ],
        [

            {
                box: 1,
                msg: '',
                author: '',
                msgs: [
                    {
                        msg: '«Να συμπεράνω λοιπόν, πως έχετε αποτύχει στην εύρεση του δολοφόνου;»',
                        author: 'Πέτρος',
                    },
                    {
                        msg: '«Να συμπεράνεις πως όλα έχουν τελειώσει. Πλέον, όλα έχουν τελειώσει...»',
                        author: 'Διονύσης',
                    },
                    {
                        msg: '«Και εκείνο το καμπανάκι που μου είχατε πει; Εκείνο που μέσα σας χτυπάει διαφορετικά όταν...»',
                        author: 'Πέτρος',
                    },
                    {
                        msg: '«Α, ναι, το καμπανάκι! Τί ωραίος ο ήχος του! Τί χαρμόσυνος!»',
                        author: 'Διονύσης',
                    },
                ]
            }
        ],
        [
            {
                msg: '«Η κουβέρτα της ευτυχίας δεν είναι ίδια για όλους. Δεν αρκεί για να τους σκεπάσει όλους. Φρόντισε να κουρνιάσεις στη ζεστασιά της και τότε θα με θυμηθείς»',
                author: 'Διονύσης',
            },
            {
                msg: '«Η ώρα είναι αργά και μισή. Πολύ αργά και ακριβώς μισή»',
                author: 'Σάρα',
            },
        ],
    ],
    bookImages: [
        {
            img: DikaiwsiPhoto1,
            caption: 'Πάνος Σόμπολος, Κυριάκος Κουζούμης, Πέμη Ζούνη, Γιώργος Αρμένης (Ξενοδοχείο Divani Palace Acropolis, 2/11/2015)',
        },
        {
            img: DikaiwsiPhoto2,
            caption: 'Διαβάζουν αποσπάσματα: Πέμη Ζούνη, Γιώργος Αρμένης. Προλογίζει: Πάνος Σόμπολος (Ξενοδοχείο Divani Palace Acropolis, 2/11/2015)',
        },
        {
            img: DikaiwsiPhoto3,
            caption: 'Κυριάκος Κουζούμης (Ξενοδοχείο Divani Palace Acropolis, 2/11/2015)',
        },
        {
            img: DikaiwsiPhoto4,
            caption: 'Υπογραφή αντιτύπων (Ξενοδοχείο Divani Palace Acropolis, 2/11/2015)'
        },
        {
            img: DikaiwsiPhoto5,
            caption: 'Συνέντευξη στο EpsilonTV για το μυθιστόρημα «Η Δικαίωση της Αθωότητας» (Ξενοδοχείο Divani Palace Acropolis, 2/11/2015)'
        },
        {
            img: DikaiwsiPhoto6,
            caption: '«Η Δικαίωση της Αθωότητας» Εκδ. Ωκεανός (Ξενοδοχείο Divani Palace Acropolis, 2/11/2015)'
        },
        {
            img: DikaiwsiPhoto7,
            caption: 'Κυριάκος Κουζούμης (Ξενοδοχείο Divani Palace Acropolis, 2/11/2015)'
        },
        {
            img: DikaiwsiPhoto8,
            caption: 'Παναγιώτης Πετράκης, Μαρία Χατζηνικολάου, Κυριάκος Κουζούμης, Αλεξάνδρα Παλαιολόγου, Όθωνας Μεταξάς (Πολιτιστικό Κέντρο Φλοίσβος, 18/4/2016)'
        },
        {
            img: DikaiwsiPhoto9,
            caption: 'Διαβάζουν αποσπάσματα: Αλεξάνδρα Παλαιολόγου, Παναγιώτης Πετράκης, Όθωνας Μεταξάς. Προλογίζει: Μαρία Χατζηνικολάου (Πολιτιστικό Κέντρο Φλοίσβος, 18/4/2016)'
        },
        {
            img: DikaiwsiPhoto10,
            caption: 'Κυριάκος Κουζούμης (Πολιτιστικό Κέντρο Φλοίσβος, 18/4/2016)',
        },
        {
            img: DikaiwsiPhoto11,
            caption: 'Μάριος Ιορδάνου, Σοφία Καζατζιάν, Κυριάκος Κουζούμης (Βιβλιοπωλείο «Θέμα», 13/11/2015)',
        },
        {
            img: DikaiwsiPhoto12,
            caption: 'Διαβάζουν αποσπάσματα: Μάριος Ιορδάνου, Σοφία Καζατζιάν (Βιβλιοπωλείο «Θέμα», 13/11/2015)'
        },
        {
            img: DikaiwsiPhoto13,
            caption: 'Κυριάκος Κουζούμης (Βιβλιοπωλείο «Θέμα», 13/11/2015)'
        },
        {
            img: DikaiwsiPhoto14,
            caption: 'Κυριάκος Κουζούμης (Βιβλιοπωλείο «Πλαίσιο», 11/12/2015, Ζάκυνθος)'
        },
        {
            img: DikaiwsiPhoto15,
            caption: 'Γεωργία Καλαμαρά, Κυριάκος Κουζούμης. Συνέντευξη στο Ionian Channel (Βιβλιοπωλείο «Πλαίσιο», 11/12/2015, Ζάκυνθος)'
        },
    ],
    social: {
        facebook: 'https://www.facebook.com/%CE%97-%CE%94%CE%B9%CE%BA%CE%B1%CE%AF%CF%89%CF%83%CE%B7-%CF%84%CE%B7%CF%82-%CE%91%CE%B8%CF%89%CF%8C%CF%84%CE%B7%CF%84%CE%B1%CF%82-374566459409555/',
    },
    playlists: [
        {
            id: {
                playlistId: 'PLwd19liGlZ4A_VeiVAHAnwOqbMn0w_paL',
            },
            snippet: {
                title: 'Η Δικαίωση της Αθωότητας - Πολιτιστικό Κέντρο "Φλοίσβος" 18/4/2016',
            }
        },

        {
            id: {
                playlistId: 'PLwd19liGlZ4CKfMzmRy7MUHumOG8fNCtl',
            },
            snippet: {
                title: 'Η Δικαίωση της Αθωότητας - Βιβλιοπωλείο "Θέμα" 13/11/2015',
            }
        },
        {
            id: {
                playlistId: 'PLwd19liGlZ4AmBAo0lVDgvJQla6C8-OWd',
            },
            snippet: {
                title: 'Η Δικαίωση της Αθωότητας - Divani Palace Acropolis - 2/11/2015',
            }
        },
    ],
};

export const Desmos = {
    id: 5,
    //TODO,
    cover: DesmosBookCoverSmall,
    //TODO,
    coverRealSize: DesmosBookCover,
    //TODO,
    pageCoverFull: DesmosCover,
    title: 'Ο Γόρδιος Δεσμός των Αγγέλων',
    shortDescription: `Ιάκωβος Ρωμανός, Στέφανος Γαλανός, Νάντια Κωστέα και Μενέλαος Απέργης είναι οι μέτοχοι ενός κολοσσού που εμπορεύεται φάρμακα μα και φαρμάκια και ακούει στο όνομα Σπίρανς.`,
    fullDescription: [
        `Ιάκωβος Ρωμανός, Στέφανος Γαλανός, Νάντια Κωστέα και Μενέλαος Απέργης είναι οι μέτοχοι ενός κολοσσού που εμπορεύεται φάρμακα μα και φαρμάκια και ακούει στο όνομα Σπίρανς.`,
        `Στις έντεκα το πρωί είναι προγραμματισμένη μία σύσκεψη ανάμεσα στους μετόχους και τα στελέχη της εταιρείας. Για ανεξήγητο και αναπάντεχο λόγο όμως, κάποιος δεν θα δηλώσει το «παρών». Έντρομες, αλαζονικές μα και ενοχικές ματιές θα διασταυρωθούν πάνω απ’ το οβάλ τραπέζι στο γραφείο του προέδρου…`,
        `Το μπαούλο του χρόνου θα ανοίγει αργά και βασανιστικά και σαν ένα άλλο κουτί της Πανδώρας θα φέρνει στην επιφάνεια αρκετά φαντάσματα. Όλα τους, το ένα μετά το άλλο θα σφίξουν με συγκίνηση και τιμή το χέρι της αλήθειας μα και της δικαίωσης…`,
        `Φορώντας την καμπαρντίνα του και έχοντας δεμένα τα χέρια του πίσω απ’ τη μέση ο αστυνόμος Αλέξανδρος Δαμίγος θα παρουσιαστεί σαν από μηχανής θεός, που με όπλα την υπομονή και την επιμονή θα αναζητήσει το διότι σε κάθε γιατί. Όλα τους θα τον αφήσουν ενεό, συγκλονίζοντάς τον πρωτόγνωρα.`,
        `Μυστήριο, ψέματα, πάθη, αλαζονεία, τύψεις αλλά και πόθος, διαστροφή, σιωπή και κραυγές είναι κάποιοι από τους κόμπους ενός άλλου γόρδιου δεσμού που ενώνει τα έργα και τις ημέρες των μετόχων της Σπίρανς.`,
        `Άραγε, οι κόμποι εκείνου του γόρδιου δεσμού θα μεταμορφωθούν σε θηλιά γύρω απ’ τον λαιμό του αστυνόμου ή ο ίδιος θα καταφέρει να τους λύσει σαν ένας άλλος Μέγας Αλέξανδρος;`,
    ],
    //TODO
    landingQuote: '',
    prologue: [
        `Έχοντας ξεπεράσει κάθε ηθικό φραγμό μια μοιραία ξανθιά γυναίκα αφήνει πίσω της τη Σαρωνίδα και την επόμενη μέρα φορώντας το προσωπείο της σκληρά εργαζόμενης μα και διατηρώντας ψηλά τον πήχη της αλαζονείας πηγαίνει στη δουλειά της…`,
        `Ο Ιάκωβος Ρωμανός, ο Στέφανος Γαλανός, η Νάντια Κωστέα και ο Μενέλαος Απέργης είναι οι μέτοχοι του φαρμακευτικού κολοσσού, Σπίρανς. Στις έντεκα το πρωί είναι προγραμματισμένη μια άλλη συνοδός κορυφής ανάμεσα σ’ αυτούς, αλλά και στα στελέχη της ίδιας εταιρείας. Για άγνωστο και αναπάντεχο λόγο κάποιος δεν θα δηλώσει το ‘παρών’ και οι υπόλοιποι θ’ ανταλλάξουν έντρομα μα και ένοχα βλέμματα… `,
        `Άραγε, θα καταφέρουν οι μέτοχοι της Σπίρανς να σφίξουν ακόμα περισσότερο τον μοιραίο γόρδιο δεσμό που τους ενώνει ή κάποιες αγγελικές φιγούρες θα κατεβούν από τα ουράνια και θα λύσουν έναν προς έναν τους άκαμπτους και αιματοβαμμένους κόμπους του;`,
    ],
    writersMessagePhoto: DesmosWriterPhoto,
    writersMessage: [
        'Χειμωνιάτικο χάραμα! Τα καλλίγραμμα δάχτυλα της βροχής ζουλούσαν τα ασπρόμαυρα πλήκτρα στο πιάνο του ουρανού και εγώ μόνος μέσα στην έρημο του γραφείου μου να ακούω την αγγελική μελωδία της. Εκείνη άγγιξε το κρεσέντο της κι εγώ άγγιξα την πένα μου. Απ’ το πουθενά, κόκκοι μιας χρυσής άμμου ορθώθηκαν γύρω μου και πήραν μορφές αερικών. Σφιχταγκαλιάστηκαν και χόρεψαν πλάι μου το πιο αισθαντικό βαλς. Η αγάπη με τον έρωτα, η ζωή με τον θάνατο, η μοίρα με τον θεό. Τι ζευγάρια! Όσο η βροχή πότιζε το χώμα με νερό, εγώ πότιζα τις λευκές μου κόλλες με μελάνι. Σαν η παρτιτούρα του καιρού έφτασε στην τελευταία της σελίδα, εγώ διέκρινα πλάι μου έναν σωρό άλλες σελίδες γραμμένες στη σκιά της ευτυχίας και στιλβωμένες από δικαίωση. Τις έφερα στ’ αυτιά μου και τις ξεφύλλισα. Τι θρόισμα! Όμοιο μ’ αυτό που με μεγαλώνει χρόνια τώρα…',
        'Στο πεντάγραμμο της βιβλιοθήκης σας έχετε τούτες τις σελίδες… Ας ηχήσει το θρόισμά τους με όποια μελωδία εσείς επιθυμείτε…',
    ],
    pageComments: 4,
    comments: [
        // TODO
    ],
    bookImages: [
        // TODO
    ],
    social: {
        // TODO Get new 
        facebook: 'https://www.facebook.com/%CE%97-%CE%94%CE%B9%CE%BA%CE%B1%CE%AF%CF%89%CF%83%CE%B7-%CF%84%CE%B7%CF%82-%CE%91%CE%B8%CF%89%CF%8C%CF%84%CE%B7%CF%84%CE%B1%CF%82-374566459409555/',
    },
    playlists: [
        // TODO
    ],
};

export const Arxontisa = {
    id: 6,
    //TODO,
    cover: ArxontisaCover,
    //TODO,
    coverRealSize: ArxontisaCover,
    //TODO,
    pageCoverFull: ArxontisaBgCover,
    title: 'Η Αρχόντισσα των Αερικών',
    shortDescription: `Ύστερα από αρκετά χρόνια, ο Βασίλης Αβησσυνός θα ταξιδέψει από τη Βενετία, όπου μεγάλωσε, στη Μακρινίτσα, όπου γεννήθηκε. Θα περιπλανηθεί στο αρχοντικό της γενιάς του και σαν μπει στη σοφίτα θα έρθει αντιμέτωπος με μία αόρατη δοκιμασία. `,
    fullDescription: [
        `Ύστερα από αρκετά χρόνια, ο Βασίλης Αβησσυνός θα ταξιδέψει από τη Βενετία, όπου μεγάλωσε, στη Μακρινίτσα, όπου γεννήθηκε. Θα περιπλανηθεί στο αρχοντικό της γενιάς του και σαν μπει στη σοφίτα θα έρθει αντιμέτωπος με μία αόρατη δοκιμασία.`,
        `Εκείνος όμως θα τη βλέπει καλά… Το αερικό της γιαγιάς του θα εμφανιστεί σταδιακά στην κουνιστή πολυθρόνα. Η Γιοβάννα θα γείρει ανάποδα την κλεψύδρα του χρόνου και όλοι οι κόκκοι της άμμου θα πέσουν βροχηδόν, ταξιδεύοντας τον Βασίλη σ’ ένα παρελθόν που δε γνωρίζει.`,
        `Θα του αποκαλύψει πως κάποτε μια σκιερή φιγούρα έκανε στέμμα τον φθόνο και την οργή και έδωσε έναν αμείλικτο όρκο. Να ξεκληρίσει τους Αβησσυνούς…`,
        `Έπειτα όμως, η Γιοβάννα θα χτυπήσει το δόρυ της μαγκούρας της στο δρύινο πάτωμα και θα τον συστήσει μ’ ένα παρόν που ακόμα αναπνέει ζωσμένο με μυστικούς μα και μυστικιστικούς ιμάντες…`,
        `Άραγε, ο Βασίλης θα μείνει πιστός στις πέντε αισθήσεις του ή θα παραδοθεί άνευ όρων στο κρυφό χάρισμα της έκτης του αίσθησης; Μία συγκλονιστική ιστορία που ακροβατεί ανάμεσα στη λογική της φύσης και τη μαγεία της μεταφυσικής!`,
    ],
    //TODO
    landingQuote: '',
    prologue: [
        `Τηρώντας ευλαβικά τον λόγο που έδωσε στον πατέρα του, ο Βασίλης θα ταξιδέψει από τη Βενετία της Ιταλίας, όπου μεγάλωσε, στη Μακρινίτσα του Πηλίου, όπου γεννήθηκε. Στις πλαγιές του βουνού θα αντικρίσει ένα επιβλητικό αρχοντικό σπίτι, το οποίο κάποτε στέγαζε μία από τις πιο τρανές φαμίλιες του τόπου. Την οικογένεια Αβησσυνού. Τη δική του οικογένεια!`,
        `Στη σοφίτα εκείνου του σπιτιού θα τον περιμένει μία αλλόκοτη έκπληξη, η οποία θα τον φέρει πρόσωπο με πρόσωπο με τα καταιγιστικά γεγονότα που στο παρελθόν έπληξαν τους Αβησσυνούς, τις άγνωστες αλήθειες που παραμένουν κρυμμένες στην κλεψύδρα του χρόνου και τις χειμαρρώδεις αποκαλύψεις που θα του κλονίσουν άφθονα δεδομένα.`,
        `Όλα αυτά όμως, μέσα από μία αχλύ ιεροτελεστία που θα ακροβατεί ανάμεσα στη φύση και τη μεταφυσική. Άραγε ο Βασίλης θα μείνει προσκολλημένος στην αντίληψη που του παρέχουν οι πέντε του αισθήσεις ή θα υπερβεί τον εαυτό του και θα παραδοθεί στις κρυφές χάρες της έκτης αίσθησης;`,
    ],
    writersMessagePhoto: ArxontisaWriter,
    writersMessage: [
        'Νύχτωνε! Πάλι δεν θα έκλεινα μάτι. Ικέτης μιας ουράνιας μούσας για δημιουργία από καρδιάς προς καρδιές. Μέσα από μία μεταφυσική τελετουργία μία νεφελώδης τεχνίτρια με τάλαντο στην υαλοπλαστική ζύγωσε κοντά μου. Με τα μυστικά της αγάπης και το μυστηριώδες χάρισμα της έκτης αίσθησης έπλασε ένα εντυπωσιακό Μουράνο μιας σφυροειδούς πένας.  Αργότερα φύσηξε γοερά διαλύοντας την πένα σε χιλιάδες κόκκους άμμου. Η τεχνίτρια εξανεμίστηκε, μα εγώ δεν πίστεψα στιγμή πως με ξεγέλασε. ',
        'Με πολύμηνη υπομονή τους μάζευα έναν έναν. Τους πυράκτωσα στο εργαστήριο των λευκών σελίδων μου δίνοντάς τους όψη προσώπων. Τους κουβάριασα με μεράκι και τους άφησα να με πάνε βαρκάδα με τη γόνδολα της συγκίνησης στα κανάλια της ευτυχίας και τη λιμνοθάλασσα της δικαίωσης. Ένα χάραμα είχα στα χέρια μου το δικό μου Μουράνο. Το σχήμα του; Καθρέφτης! Ο καθρέφτης της ψυχής μου…',
        'Στη σκόνη της βιβλιοθήκης σας έχετε εκείνους τους κόκκους. Ας τους μορφοποιήσετε σε ό,τι Μουράνο εσείς επιθυμείτε…',
    ],
    pageComments: 4,
    comments: [
        // TODO
    ],
    bookImages: [
        // TODO
    ],
    social: {
        // TODO Get new 
        facebook: 'https://www.facebook.com/%CE%97-%CE%94%CE%B9%CE%BA%CE%B1%CE%AF%CF%89%CF%83%CE%B7-%CF%84%CE%B7%CF%82-%CE%91%CE%B8%CF%89%CF%8C%CF%84%CE%B7%CF%84%CE%B1%CF%82-374566459409555/',
    },
    playlists: [
        // TODO
    ],
};

export const Seira1_SexpirikosGrifos = {
    id: 7,
    purchaseUrl: 'https://www.bythebook.gr/en-gb/publications/by-the-book-11/shakespearean-riddle',
    //TODO,
    cover: Seira1_SexpirikosGrifosCover,
    //TODO,
    coverRealSize: Seira1_SexpirikosGrifosCover,
    //TODO,
    pageCoverFull: Seira1_SexpirikosGrifosBgCover,
    title: 'Σαιξπηρικός Γρίφος',
    shortDescription: `Ανήμερα Αγίου Βαλεντίνου ο Στράτος Ανούσης θα απευθύνει πρόταση γάμου στην αγαπημένη του, Υβόννη Ψυχάρη. Το επόμενο πρωί η Υβόννη θα βρεθεί δολοφονημένη! Στο πλευρό της, μία φωτογραφία με δέκα σπουδαστές δραματικής σχολής. Όλοι τους λάτρεις του Σαίξπηρ!`,
    fullDescription: [
        `Ανήμερα Αγίου Βαλεντίνου ο Στράτος Ανούσης θα απευθύνει πρόταση γάμου στην αγαπημένη του, Υβόννη Ψυχάρη. Το επόμενο πρωί η Υβόννη θα βρεθεί δολοφονημένη! Στο πλευρό της, μία φωτογραφία με δέκα σπουδαστές δραματικής σχολής. Όλοι τους λάτρεις του Σαίξπηρ!`,
        `Η καταξιωμένη αστυνόμος Άννα Δερβίση θα αναλάβει την εξιχνίαση της υπόθεσης και με τη βοήθεια του στενού της συνεργάτη, Κυριάκου Λιβαθινού, θα αναζητήσουν εξηγήσεις. `,
        `Το ένα θύμα θα διαδέχεται το άλλο, ενώ απ’ το αρχείο της αστυνομίας θα ανασυρθούν κάποιοι σκονισμένοι φάκελοι, που συνδέονται με ύποπτο τρόπο μεταξύ τους.`,
        `Λίγο πριν από το τέλος, η αστυνόμος θα πετάξει το προσωπείο απ’ το πρόσωπο του θύτη. Εκείνος, μην μπορώντας να κάνει αλλιώς θα ομολογήσει! Πριν από το φινάλε όμως θα ζητήσει μία χάρη. Μία ύστατη χάρη...`,
        `Άραγε, η Άννα θα παραμείνει ταγμένη στο γράμμα του νόμου ή θα ευθυγραμμιστεί με τους συναισθηματισμούς της καρδιάς;`,
        `Μια ιστορία γύρω από γνωστά σαιξπηρικά αποφθέγματα. Όλα τους, μέρη ενός αρρωστημένου γρίφου με ρίζες στο παρελθόν!`
    ],
    //TODO
    landingQuote: '',
    prologue: [
        `Ανήμερα Αγίου Βαλεντίνου ο νεαρός ηθοποιός Στράτος θα κάνει πρόταση γάμου στην αγαπημένη του και ομοίως νεαρή ηθοποιό, Υβόννη. Εκείνη, δίχως δεύτερη σκέψη, θα δεχτεί και η φαντασία της θα καλπάσει, μια και η Υβόννη θα δει την παραμυθένια ζωή που λαχταρούσε να ζήσει, να ξεκινά τα πρώτα της βήματα…`,
        ` Η μοίρα όμως θα φορέσει το προσωπείο του τιμωρού και τους κοθόρνους του μένους. Σαν σκιά θα πορευτεί ανάμεσα σε παλιούς γνώριμους με δεσμά τη φοιτητική τους ζωή και σαν καπνός θα διαπεράσει σωθικά, συνειδήσεις και όρκους!`,
        `Οι πρώην σπουδαστές της δραματικής σχολής “Τάλαντο” θα διαβάσουν αλλιώς τον σαιξπηρικό στίχο “να ζεις κανείς ή να μη ζει;” και όλοι τους θα έρθουν ενώπιοι ενωπίοις με μία αλήθεια που… είχαν ξεχάσει!`,
    ],
    writersMessagePhoto: Seira1_SexpirikosGrifosWriterPhoto,
    writersMessage: [
        `Η γιορτή του Έρωτα! Εκείνου του κατεργάρη με τα φτερά στην πλάτη και τα βέλη στη φαρέτρα. Εκείνος, ο πρώτος υπήκοος της καρδιάς!`,
        `Έτρεχα στον προορισμό μου. Με μια ανθοδέσμη και μια κάρτα. Ένα απλό δίστιχο, γέννημα αγάπης το μπιλιέτο της. Και στα ερτζιανά σαιξπηρικά στιχάκια. Ξάφνου, μια έκτακτη είδηση. Μυρίστηκα έγκλημα πάθους και οι σκέψεις κουβαριάστηκαν με τη φαντασία. `,
        `Νωρίς το χάραμα με μολύβι και χαρτί. Επικεφαλίδα μια καρδιά και ένας γρίφος στην πρώτη γραμμή. Κι ύστερα μαγεία! Αυτή η ερωτική μυσταγωγία της δημιουργίας, που σαν άλλος Άγιος Βαλεντίνος φτερώνει στην αρχή και στοχεύει στο τέλος. `,
        `Θυμήθηκα πως το δηλητήριο του σκορπιού είναι στην ουρά του και η αξία του σονέτου στον τελευταίο του στίχο. Η αστυνομική πλοκή εκτόξευσε βέλη ευτυχίας μέσα μου και το μπιλιέτο εκείνης της κάρτας με λάβωσε με δικαίωση. Εγώ, ο τελευταίος υπήκοος της συγγραφής...`,
        `Στα σονέτα της βιβλιοθήκης σας έχετε εκείνο το μπιλιέτο. Ας το διαβάσετε όποια μέρα του χρόνου εσείς επιθυμείτε... `,
    ],
    pageComments: 0,
    comments: [
        // TODO
    ],
    bookImages: [
        // TODO
    ],
    social: {
        // TODO Get new 
        // facebook: 'https://www.facebook.com/%CE%97-%CE%94%CE%B9%CE%BA%CE%B1%CE%AF%CF%89%CF%83%CE%B7-%CF%84%CE%B7%CF%82-%CE%91%CE%B8%CF%89%CF%8C%CF%84%CE%B7%CF%84%CE%B1%CF%82-374566459409555/',
    },
    playlists: [
        // TODO
    ],
};

export const Seira1_XakiDantela = {
    id: 8,
    purchaseUrl: 'https://www.bythebook.gr/en-gb/publications/by-the-book-11/khaki-lace',
    //TODO,
    cover: Seira1_XD_Book_Cover,
    //TODO,
    coverRealSize: Seira1_XD_Book_Cover,
    //TODO,
    pageCoverFull: Seira1_XD_BgCover,
    title: 'Χακί Δαντέλα',
    shortDescription: `Έξω απ’ το ιστορικό Ασκληπιείο της Κω θα συναντηθούν ο Σήφης, ο Μηνάς και ο Γιώργος, φίλοι με “χακί” δεσμά. Μία σκιερή φιγούρα θα καταφτάσει και δίχως να χάσει χρόνο θα εκτελέσει τον σκοπό της. Το αίμα των τριών φιλών θα ποτίσει το έδαφος.`,
    fullDescription: [
        `Έξω απ’ το ιστορικό Ασκληπιείο της Κω θα συναντηθούν ο Σήφης, ο Μηνάς και ο Γιώργος, φίλοι με “χακί” δεσμά. Μία σκιερή φιγούρα θα καταφτάσει και δίχως να χάσει χρόνο θα εκτελέσει τον σκοπό της. Το αίμα των τριών φιλών θα ποτίσει το έδαφος.`,
        `Η καταξιωμένη αστυνόμος Άννα Δερβίση θα αναλάβει την εξιχνίαση της υπόθεσης. Με τη βοήθεια του στενού της συνεργάτη, Κυριάκου Λιβαθινού, θα αντιστρέψουν την κλεψύδρα του χρόνου και θα πληροφορηθούν το κυνικό προφίλ των θυμάτων κατά τη στρατιωτική τους θητεία.`,
        `Παράλληλα, κάποιες σκουριασμένες υποθέσεις στο αρχείο της αστυνομίας θα αποτελέσουν τα απολεσθέντα κομμάτια ενός οργισμένου παζλ.`,
        `Με περίσσια υπομονή και άφθονες σημειώσεις στο τεφτέρι της, η αστυνόμος θα αφεθεί στο σήμαντρο του ενστίκτου της, μέχρι που θα βαδίσει με σιγουριά στο σκοτεινό μονοπάτι του δολοφόνου. Λίγο πριν από τις χειροπέδες όμως, θα τον ακούσει να εκλιπαρεί για μία τελευταία επίσκεψη.`,
        `Άραγε, η Άννα θα δώσει προτεραιότητα στο “πρέπει”, που επιβάλλουν τα γαλόνια της ή στο “αξίζει”, που κραυγάζει η ανθρωπιά της;`,
        `Μια ιστορία που δραπετεύει απ’ τις λυκοφιλίες μέσα στα στρατόπεδα και γλυκοκοιτάζει τη φιλία μέσα στη μοναξιά της ψυχής...`
    ],
    //TODO
    landingQuote: '',
    prologue: [
        `Τρεις φίλοι με “χακί” δεσμά, ο Μηνάς, ο Σήφης και ο Γιώργος, θα ξαναβρεθούν στην Κω, όπου είχαν υπηρετήσει τη μαμά πατρίδα. Το σημείο συνάντησής τους θα είναι ο χώρος στάθμευσης στο ιστορικό Ασκληπιείο.`,
        `Μόλις ανταμώσουν, μια σκιά θα δηλώσει την παρουσία της... Η ζωή της “παραλλαγής” θα ξυπνήσει απ’ τον λήθαργο και θα δώσει ένα ρεσιτάλ αποκαλύψεων, ενώ την ίδια στιγμή σκονισμένες υποθέσεις απ’ το αστυνομικό αρχείο με φόντο την Κω θα ανατείλουν προς τον ουρανό της δικαίωσης…`
    ],
    writersMessagePhoto: Seira1_XD_Writer,
    writersMessage: [
        `Νύχτωσε. Πάλι ικέτης της μούσας για κάτι από ψυχής προς ψυχές. Ένα τσιγάρο, ένας αναστεναγμός και ένα δάκρυ. Ύστερα στο πάτωμα με παρέα τη σιωπή. Ξάφνου απ’ τις σελίδες των αναμνήσεων αντήχησε το θρόισμα παραδοσιακών κελευσμάτων. Άγαλμα το κορμί.`,
        `Σαν ασπρόμαυρο φιλμ με χακί λεπτομέρειες παρήλασαν πίσω απ’ τα κλειστά βλέφαρα λούφες και αγγαρείες, σκοπιές και περιπολίες, ασκήσεις και αλφαμιτιλίκια, βολές και θαλαμοφυλίκια, «γερμανικά», ποινές, αναφορές και στο τέλος η ηχώ του «καλός πολίτης» κελάηδισμα στα αυτιά της ψυχής.`,
        `Για την Ελλάδα, την πατρίδα, τη σημαία, τη γη και τη θάλασσα, για όλα εκείνα τα ιδανικά που παραμένουν λαξευμένα σε άμεμπτο μάρμαρο. Ξετρύπωσα το σκονισμένο τζόκεϊ και διάβασα το χειρόγραφο απόφθεγμα στην υφασμάτινη κεφαλή. `,
        `Βουρκωμένος άρπαξα μολύβι και τότε η ευτυχία βημάτισε ρομποτικά με γυαλισμένες αρβύλες ενώ η έμπνευση βάρεσε πυρ στον στόχο της δικαίωσης…`,
        `Στο φιλμ της βιβλιοθήκης σας έχετε εκείνη την παρέλαση. Ας ακούσετε τον βηματισμό της με όποια ηχώ εσείς επιθυμείτε…`
    ],
    pageComments: 0,
    comments: [
        // TODO
    ],
    bookImages: [
        // TODO
    ],
    social: {
        // TODO Get new 
        // facebook: 'https://www.facebook.com/%CE%97-%CE%94%CE%B9%CE%BA%CE%B1%CE%AF%CF%89%CF%83%CE%B7-%CF%84%CE%B7%CF%82-%CE%91%CE%B8%CF%89%CF%8C%CF%84%CE%B7%CF%84%CE%B1%CF%82-374566459409555/',
    },
    playlists: [
        // TODO
    ],
};

export const Seira1_MegaliParaskeui = {
    id: 9,
    purchaseUrl: 'https://www.bythebook.gr/en-gb/publications/by-the-book-11/good-friday',
    //TODO,
    cover: Seira_MP_BookCover,
    //TODO,
    coverRealSize: Seira_MP_BookCover,
    //TODO,
    pageCoverFull: Seira_MP_BgCover,
    title: 'Μεγάλη Παρασκευή',
    shortDescription: `Με πρωταπριλιάτικο αστείο θα μοιάζει στον Ανέστη Ταβουλάρη η ομοφυλοφιλία του μικρού του γιου, του Μανώλη. Θα δώσει όμως, προτεραιότητα στην αγάπη! Λίγες ώρες αργότερα, η δίδυμη αδελφή του Μανώλη, η Ειρήνη, θα βρει τον πατέρα τους νεκρό στον στάβλο.`,
    fullDescription: [
        `Με πρωταπριλιάτικο αστείο θα μοιάζει στον Ανέστη Ταβουλάρη η ομοφυλοφιλία του μικρού του γιου, του Μανώλη. Θα δώσει όμως, προτεραιότητα στην αγάπη! Λίγες ώρες αργότερα, η δίδυμη αδελφή του Μανώλη, η Ειρήνη, θα βρει τον πατέρα τους νεκρό στον στάβλο.`,
        `Η καταξιωμένη αστυνόμος Άννα Δερβίση, απεσταλμένη από την Αθήνα, θα αναλάβει την εξιχνίαση της υπόθεσης. Με τη βοήθεια του στενού της συνεργάτη, Κυριάκου Λιβαθινού, θα αναζητήσουν εξηγήσεις από τη Γαστούνη μέχρι το Βαρθολομιό κι από τη Ζαχάρω μέχρι τη λίμνη του Καϊάφα.`,
        `Τα θύματα θα αυξηθούν! Το δαιμόνιο μυαλό της αστυνόμου θα αναζητήσει τα κίνητρα της δολοφονίας σε παλιότερες σελίδες ημερολογίων...`,
        `Υπό άρτια σκηνοθετημένη δράση θα βρει την άκρη του νήματος, αλλά θα είναι αργά!`,
        `Και πάνω που θα βρίσκεται στο σημείο μηδέν, νέα στοιχεία θα αναδυθούν στην επιφάνεια σαν φυσαλίδες από πρόσωπα που πάσχισαν να πνίξουν μυστικά, καημούς κι απωθημένα στη θάλασσα της νιότης τους.`,
        `Άραγε, η Άννα θα δώσει γη και ύδωρ στο μαράζι του δολοφόνου ή θα πιαστεί εξαπίνης απ’ την αποφασισμένη για όλα φιγούρα του;`,
        `Μια ιστορία που επιβεβαιώνει περίτρανα πως όσα ξέρει ο νοικοκύρης δεν τα ξέρει ο κόσμος όλος...`
    ],
    //TODO
    landingQuote: '',
    prologue: [
        `Στην κωμόπολη της Γαστούνης βρίσκεται το σπίτι του Ανέστη Ταβουλάρη. Ο ίδιος, αφού έχει ολοκληρώσει τις καθιερωμένες αγροτικές δουλειές, θα επιστρέψει αργά τη νύχτα και θα πιάσει επ’ αυτοφώρω τον μικρό του γιο, τον Μανώλη, με τον καλύτερο φίλο του. Το σοκ θα είναι μεγάλο αλλά η αγάπη για τον γιο του ακόμα μεγαλύτερη!`,
        `Ο Ανέστης θα απιθώσει το μαράζι του στην κυλιόμενη καρέκλα της συζύγου του, η οποία -υπό φυτική ιδιότητα- μπορεί να τον ακούσει μονάχα με τα αυτιά της ψυχής.`,
        `Όλα τα ανομολόγητα μυστικά θα βγουν στην επιφάνεια, η χρόνια σιωπή θα βρει μουράγιο στη δράση κι ένας άλλος ασκός του Αιόλου θα ανοίξει φέρνοντας τον άνεμο της εξομολόγησης να ξεριζώσει πάθη και απωθημένα.`,
    ],
    writersMessagePhoto: Seira_MP_Writer,
    writersMessage: [
        `Χειμώνας! Χάθηκα στην άσφαλτο ακολουθώντας το σινιάλο του ενστίκτου. Μπροστά μου ξεπρόβαλε το λιμάνι του Πειραιά. Στάθμευσα όπως όπως και περπάτησα ώς την άκρη του προβλήτα.`,
        `Κάθισα κατάχαμα κι άναψα τσιγάρο. Έκλεισα μάτια και αφέθηκα στους εσωτερικούς ήχους. Με την μπρατσέρα της φαντασίας ανοίχτηκα στο πέλαγο κι εκείνοι οι ήχοι μεταμορφώθηκαν σε μυθικές σειρήνες. Τα κύματα πάφλαζαν, ο άνεμος μανιασμένος και ένα σαρδόνιο χαμόγελο στα χείλη του βυθού. `,
        `Τρόμαξα, αλλά συνέχισα το κυνήγι. Συνέχισα να κυνηγώ το άγνωστο, όπως αυτό στριμώχνεται στα σπήλαια του υποσυνείδητου. Έπειτα, επέστρεψα στον ρεαλισμό και καθώς βάδιζα προς τα πίσω, είδα ένα πελώριο καμπαναριό.`,
        `Ο άλλοτε τρομακτικός ήχος των σειρήνων διοχετεύτηκε στις καμπάνες και μια αισθαντική μελωδία με νότες ευτυχίας αντιλάλησε στον ουρανό της δικαίωσης. Όταν αργότερα έπιασα πένα, σημείωσα πως το σινιάλο του ενστίκτου ταράζει τα σωθικά, μα λυτρώνει την ψυχή...`,
        `Στο κωδωνοστάσιο της βιβλιοθήκης σας έχετε εκείνες τις καμπάνες. Ας τις κρούσετε μ’ όποιον τρόπο εσείς επιθυμείτε.`
    ],
    pageComments: 0,
    comments: [
        // TODO
    ],
    bookImages: [
        // TODO
    ],
    social: {
        // TODO Get new 
        // facebook: 'https://www.facebook.com/%CE%97-%CE%94%CE%B9%CE%BA%CE%B1%CE%AF%CF%89%CF%83%CE%B7-%CF%84%CE%B7%CF%82-%CE%91%CE%B8%CF%89%CF%8C%CF%84%CE%B7%CF%84%CE%B1%CF%82-374566459409555/',
    },
    playlists: [
        // TODO
    ],
};

export const Seira1_RagismeniVera = {
    id: 10,
    // purchaseUrl: 'https://www.bythebook.gr/en-gb/publications/by-the-book-11/good-friday',
    //TODO,
    cover: Seira_RV_BookCover,
    //TODO,
    coverRealSize: Seira_RV_BookCover,
    //TODO,
    pageCoverFull: Seira_RV_BgCover,
    title: 'Ραγισμένη Βέρα',
    shortDescription: `Ακολουθώντας το παγιωμένο πρόγραμμά του ο Χρήστος θα πάει στην εργασία του, στο συνεργείο αυτοκινήτων. Ως συνήθως, μαζί με τους συναδέλφους του θα παραγγείλουν καφέδες. Λίγη ώρα αργότερα, επιθετικοί πόνοι θα βρουν το στήθος του και τα γόνατά του θα τον προδώσουν.`,
    fullDescription: [
        `Ακολουθώντας το παγιωμένο πρόγραμμά του ο Χρήστος θα πάει στην εργασία του, στο συνεργείο αυτοκινήτων. Ως συνήθως, μαζί με τους συναδέλφους του θα παραγγείλουν καφέδες. Λίγη ώρα αργότερα, επιθετικοί πόνοι θα βρουν το στήθος του και τα γόνατά του θα τον προδώσουν.`,
        `Κεραυνός εν αιθρία η αναπάντεχη απώλειά του. Χάρη στις τοξικολογικές εξετάσεις όμως, η αστυνομία θα κάνει λόγο περί δολοφονίας. Η καταξιωμένη αστυνόμος Άννα Δερβίση θα αναλάβει την εξιχνίαση του εγκλήματος. Με τη βοήθεια του στενού της συνεργάτη, Κυριάκου Λιβαθινού, θα έρθουν αντιμέτωποι με την πολυποίκιλη παρασκηνιακή ζωή του θύματος.`,
        `Σύσσωμα τα πρόσωπα που σχετίζονταν παντοιοτρόπως με το θύμα θα τεθούν υπό την ανάκριση της Άννας. Ο επίμονος δολοφόνος δεν θα αργήσει να κάνει το επόμενο βήμα. Η Άννα όμως θα έχει φυλάξει τα νώτα της και τότε θα δράσει καταλυτικά. Χάρη σε μια εύστοχη μπλόφα θα αποδείξει για ακόμα μία φορά πως δεν χαρακτηρίζεται τυχαία ως δαιμόνια!`,
        `Άραγε, επρόκειτο για εγκλήματα που έγιναν στο όνομα της εκδίκησης ή που έλαβαν χώρα κατά λάθος;`,
        `Μια ιστορία που αποδεικνύει περίτρανα ότι τα φαινόμενα απατούν και δυστυχώς... δεν απατούν μόνο αυτά!`
    ],
    //TODO
    landingQuote: '',
    prologue: [
        `Ακολουθώντας το παγιωμένο πρόγραμμά του ο Χρήστος Βασιλείου θα πάει στην εργασία του, στο συνεργείο αυτοκινήτων. Λίγη ώρα αργότερα, θα τον επισκεφθεί η σύζυγός του, η Ελεονώρα.Ύστερα από μία συναισθηματική κουβέντα μεταξύ τους, η Ελεονώρα θα αποχωρήσει και εκείνος θα επιστρέψει στη δουλειά.`,
        `Μετέπειτα κι ενώ όλα θα κυλούν ομαλά, η μοίρα θα αρπάξει βελόνα και κλωστή και θα ράψει αλλόκοτα το υφαντό της.`,
        `Η αναπάντεχη απώλεια του Χρήστου θα πέσει σαν κεραυνός εν αιθρία.`,
        `Πολύ σύντομα όμως θα αναδυθεί στην επιφάνεια της αλήθειας η παρασκηνιακή ζωή του. Μία ζωή που κρυβόταν έντεχνα στο κυτίο της ασυδοσίας, ενώ ήταν περιτυλιγμένη με το αμπαλάζ του ψέματος και του αμοραλισμού.`,
    ],
    writersMessagePhoto: Seira_RV_Writer,
    writersMessage: [
        `Γλυκοχάραγο! Στα τζάμια στάλες υγρασίας, στην ατμόσφαιρα λευκή πάχνη και στον αγέρα βουητό οργής. Η μανιασμένη Φύση με κλείδωσε στο γραφείο μου.`,
        `Κυνηγώντας τη δημιουργικότητα έλυσα τα γκέμια στα άλογα της φαντασίας. Ο γδούπος του γοργού βήματος πάνω στον ανηφορικό χωματόδρομο όμοιος με καρδιοχτύπι ταραχής.`,
        `Ύστερα από ώρα αντίκρισα το πανώριο κάστρο της έμπνευσης να ανοίγει τις μεγαλοπρεπείς θύρες του. Δεν τις διέβην. Επέλεξα τη διπλανή απόκρυφη χαραμάδα.`,
        `Τρύπωσα σε μια σκιερή άδεια αίθουσα. Στο βάθος μία δεσποτική φιγούρα. Αποσβολωμένος γονάτισα εμπρός της. Εκείνη κοντοζύγωσε και μου δώρισε από καρδιάς ό,τι είχε.`,
        `Έπειτα μου υπέδειξε τον θρόνο. Αρνήθηκα. Συνειδητά επέλεξα τον χωματόδρομο της φυγής. Σαν επέστρεψα στο γραφείο μου, βρήκα το σκήπτρο της ευτυχίας πλάι στο μελάνι και το στέμμα της δικαίωσης πάνω στις λευκές σελίδες. Τελικά, οι μυστικές χαραμάδες είναι τα πιο φεγγερά περάσματα στον κόσμο της μυθοπλασίας...`,
        `Στο κάστρο της βιβλιοθήκης σας έχετε εκείνα τα δώρα. Ας τα τοποθετήσετε σ’ όποιον θρόνο εσείς επιθυμείτε...`
    ],
    pageComments: 0,
    comments: [
        // TODO
    ],
    bookImages: [
        // TODO
    ],
    social: {
        // TODO Get new 
        // facebook: 'https://www.facebook.com/%CE%97-%CE%94%CE%B9%CE%BA%CE%B1%CE%AF%CF%89%CF%83%CE%B7-%CF%84%CE%B7%CF%82-%CE%91%CE%B8%CF%89%CF%8C%CF%84%CE%B7%CF%84%CE%B1%CF%82-374566459409555/',
    },
    playlists: [
        // TODO
    ],
};

export const Seira1_KlemmenoKalos = {
    id: 11,
    // purchaseUrl: 'https://www.bythebook.gr/en-gb/publications/by-the-book-11/good-friday',
    //TODO,
    cover: Seira_KK_BookCover,
    //TODO,
    coverRealSize: Seira_KK_BookCover,
    //TODO,
    pageCoverFull: Seira_KK_BgCover,
    title: 'Κλεμμένο Κάλλος',
    shortDescription: `Με αφορμή την εορτή της γυναίκας στην πολυτελή κατοικία της Γεωργίας Μάνου θα διοργανωθεί ένα πριβέ πάρτι. Η οικοδέσποινα με τις τρεις “επιστήθιες” φίλες της, τη Μάγδα, τη Βανέσσα και την Τάνια, θα ζήσουν στιγμές ξέφρενου πάθους συντροφιά δύο χορευτών. Το επόμενο πρωί όμως, η Γεωργία θα βρεθεί νεκρή!`,
    fullDescription: [
        `Με αφορμή την εορτή της γυναίκας στην πολυτελή κατοικία της Γεωργίας Μάνου θα διοργανωθεί ένα πριβέ πάρτι. Η οικοδέσποινα με τις τρεις “επιστήθιες” φίλες της, τη Μάγδα, τη Βανέσσα και την Τάνια, θα ζήσουν στιγμές ξέφρενου πάθους συντροφιά δύο χορευτών. Το επόμενο πρωί όμως, η Γεωργία θα βρεθεί νεκρή!`,
        `Η καταξιωμένη αστυνόμος Άννα Δερβίση θα αναλάβει την εξιχνίαση της υπόθεσης. Με τη βοήθεια του στενού της συνεργάτη, Κυριάκου Λιβαθινού, θα έρθουν ενώπιοι ενωπίοις με μια αλλόκοτη αλληλουχία στοιχείων που θα έχουν ως πηγή το κυνήγι του κάλλους!`,
        `Το ένα θύμα θα διαδέχεται το άλλο και η Άννα αποδεσμευμένη από δεδομένα και γεγονότα θα συμπεράνει πως τα κίνητρα του δολοφόνου δεν κρύβονται κάτω απ’ το χαλί της μεγαλομανίας...`,
        `Όταν πια η αστυνόμος θα έχει καταλήξει στο πρόσωπο του δολοφόνου, θα εμφανιστεί μπροστά του και θα ξανασυστηθεί μαζί του. Θα ακούσει με ευλάβεια όλα τα διότι του στα γιατί της.`,
        `Άραγε θα αρκούν τα επιχειρήματα του δολοφόνου για να την πείσουν πως ό,τι έκανε το έκανε στο όνομα ενός... άλλου κάλλους;`,
        `Μια ιστορία με παραμορφωμένες συνειδήσεις, μα με απαράμιλλης ομορφιάς καρδιές!`
    ],
    //TODO
    landingQuote: '',
    prologue: [
        `Ανήμερα της εορτής της γυναίκας τέσσερις «επιστήθιες» φίλες, η Γεωργία, η Βανέσσα, η Μάγδα και η Τάνια θα κάνουν ένα πριβέ πάρτι με δύο καυτούς χορευτές. Είναι αποφασισμένες να θυσιάσουν στον βωμό της γυναικείας τους ιδιότητας κάθε ηθική αναστολή. Και θα το κάνουν!`,
        `Το επόμενο πρωί όμως, μία εξ αυτών θα βρεθεί δολοφονημένη και το χρονοντούλαπο της λυκοφιλίας τους θα ανοίξει αργά και βασανιστικά.`,
        `Μυστική χημεία, ακραίες συμφωνίες, αλλόκοτη μοιρασιά στοιχείων κι ένας διακαής πόθος σαν σημαία στη βραχονησίδα των ψυχών τους. Ο πόθος της ανεξαρτησίας!`,
    ],
    writersMessagePhoto: Seira_KK_Writer,
    writersMessage: [
        `Μεσάνυχτα. Έξω χιονιάς. Παλτό, κασκόλ, γάντια κι ύστερα μοναχικός περίπατος στους δρόμους της γειτονιάς. Στην καρδιά μιας πλατείας ένας υπερήλικας άνδρας έκλαιγε με λυγμούς. Ζύγωσα και τον άγγιξα στον ώμο.`,
        `Κάναμε τσιγάρο και μοιράστηκε μαζί μου το μαράζι του. Την επομένη η γυναίκα του θα υποβαλλόταν σε μια κρίσιμη επέμβαση. Η ζωή της ακροβάτης στο σχοινί της ελπίδας. Ανταλλάξαμε στοιχεία επικοινωνίας. Το επόμενο βράδυ του τηλεφώνησα. Είχε ευχάριστα νέα.`,
        `Δίχως δεύτερη σκέψη κάθισα στο γραφείο μου και έπιασα καθαρή σελίδα. Ο χιονιάς είχε επιστρέψει δριμύτερος. Κι όμως, μέσα στη φαντασία μου οι νιφάδες του μου έστρωναν το χαλί της έμπνευσης. Ζωγράφισα μια καρδιά.`,
        `Της πέρασα ρυτίδες και της φόρεσα γκρίζα μαλλιά. Συγκινημένος απ’ το συμβάν της πλατείας, ένιωσα την ευτυχία να με ζεσταίνει και στο τεντωμένο δικό μου σχοινί ακροβάτησε η ελπίδα της δικαίωσης...`,
        `Στην πλατεία της βιβλιοθήκης σας έχετε εκείνες τις νιφάδες. Ας ακροβατήσετε επάνω τους με όποια ελπίδα εσείς επιθυμείτε...`,
    ],
    pageComments: 0,
    comments: [
        // TODO
    ],
    bookImages: [
        // TODO
    ],
    social: {
        // TODO Get new 
        // facebook: 'https://www.facebook.com/%CE%97-%CE%94%CE%B9%CE%BA%CE%B1%CE%AF%CF%89%CF%83%CE%B7-%CF%84%CE%B7%CF%82-%CE%91%CE%B8%CF%89%CF%8C%CF%84%CE%B7%CF%84%CE%B1%CF%82-374566459409555/',
    },
    playlists: [
        // TODO
    ],
};

export const Seira1_SkoteinoNepal = {
    id: 12,
    // purchaseUrl: 'https://www.bythebook.gr/en-gb/publications/by-the-book-11/good-friday',
    //TODO,
    cover: Seira_SN_BookCover,
    //TODO,
    coverRealSize: Seira_SN_BookCover,
    //TODO,
    pageCoverFull: Seira_SN_BgCover,
    title: 'Σκοτεινό Νεπάλ',
    shortDescription: `Η Ευτυχία και ο Γιώργος θα βρεθούν σ’ ένα φθηνό ξενοδοχείο της Αθήνας. Το επόμενο χάραμα όμως, θα βρεθεί στο πεζοδρόμιο το πτώμα του Γιώργου. Την ίδια στιγμή, η Ευτυχία θα αγνοείται.`,
    fullDescription: [
        `Η Ευτυχία και ο Γιώργος θα βρεθούν σ’ ένα φθηνό ξενοδοχείο της Αθήνας. Το επόμενο χάραμα όμως, θα βρεθεί στο πεζοδρόμιο το πτώμα του Γιώργου. Την ίδια στιγμή, η Ευτυχία θα αγνοείται.`,
        `Η καταξιωμένη αστυνόμος Άννα Δερβίση θα αναλάβει την εξιχνίαση και των δύο υποθέσεων. Με τη βοήθεια του στενού της συνεργάτη, Κυριάκου Λιβαθινού, θα καταλήξουν σ’ έναν κοινό παρονομαστή ανάμεσα στα ερωτευμένα θύματα. Στο ταξίδι τους στο Νεπάλ...`,
        `Μεθοδικά θα θέσουν όλα τα μέλη του ταξιδιωτικού γκρουπ κάτω απ’ το μικροσκόπιο της ανάκρισης. Θα διαπιστώσουν παρασκηνιακές σχέσεις, φιλοτεχνημένη λυκοφιλία και κυρίως έναν συνδετικό κρίκο πολλών με το παρελθόν.`,
        `Στην αίθουσα του ανακριτηρίου θα αντηχήσουν η αλήθεια και τα πραγματικά κίνητρα. Λίγο πριν από το φινάλε, θα πέσει στο τραπέζι σαν ώριμο μήλο απ’ το δένδρο της θλίψης η επιθυμία για μια αγκαλιά!`,
        `Άραγε, οι φόνοι έγιναν στο όνομα της εκδίκησης και του μένους ή στο όνομα του απωθημένου και της υπομονής; Ή και στων δύο;`,
        `Μια ιστορία που ταξιδεύει απ’ τους πρόποδες των Ιμαλαΐων στην Ελλάδα, φέροντας στις αποσκευές της μοιραία πάθη και ανομολόγητα μυστικά...`,
    ],
    //TODO
    landingQuote: '',
    prologue: [
        `Η Ευτυχία και ο Γιώργος θα βρεθούν σ’ ένα φθηνό ξενοδοχείο της Αθήνας. Μετά τον βωμό στη θεά Αφροδίτη, θα κάνουν τσιγάρο και θα αναπολήσουν στιγμιότυπα ζήλιας με φόντο τους πρόποδες των Ιμαλαΐων αλλά και την κοιλάδα του Τσιτουάν. Ύστερα θα βγουν μια βόλτα στη γραφική Πλάκα και η ώρα θα περάσει, δίχως να το καταλάβουν.`,
        `Το επόμενο χάραμα ένας περαστικός απ’ την οδό Σατωβριάνδου θα μείνει με κρεμασμένο το πιγούνι, καθώς θα δει στο πεζοδρόμιο ένα πτώμα.`,
        `Βήμα βήμα οι έρευνες της αστυνομίας θα καταλήξουν στο οργανωμένο ταξίδι ενός δωδεκαμελούς γκρουπ στο ορεινό Νεπάλ.`,
        `Η παρασκηνιακή χημεία πολλών θα σημάνει έκρηξη, τα χρόνια μυστικά θα βουτηχτούν στον βούρκο της οργής και η ευχή του «Namaste» θα παραποιηθεί σε ανίερη, υπακούοντας στους νόμους του πάθους, αλλά και στης θλίψης.`,
    ],
    writersMessagePhoto: Seira_SN_Writer,
    writersMessage: [
        `Θέση δίπλα στο παράθυρο. Γύρω μου πλήθος επιβατών παραδομένοι στην -άβολη- αγκαλιά του Μορφέα. Κρυφά σήκωσα το σκίαστρο και ένα συννεφένιο σεντόνι απλώθηκε μπροστά μου, ενώ οι κορυφές των Ιμαλαΐων δέσποζαν στη χάρτα του ουρανού σαν λευκά ξυράφια. Δέος! Καθώς το αεροσκάφος πλησίαζε στη γη του Νεπάλ, οι κοιλάδες και οι ζούγκλες γίνονταν πιο ευδιάκριτες.`,
        `Ένα γαργαλητό έμπνευσης βάρυνε τα βλέφαρά μου. Πίσω απ’ αυτά ορθώθηκαν σκιές ανήμερων θηρίων. Άλλες κυνηγούσαν τροφή, άλλες αλυχτούσαν τρομακτικά κι άλλες αναζητούσαν τη σωτηρία γαντζωμένες στους κορμούς ψηλών δένδρων. `,
        `Και στη μέση το ανθρώπινο στοιχείο, αποσβολωμένο απ’ το κάλλος της άγριας Φύσης. Σαν άνοιξα έπειτα τη “χάρτα” των σημειώσεών μου, είδα όλες εκείνες τις θηριώδεις σκιές σε ανθρώπινο σουλούπι.`,
        `Όλες τους κυνηγοί της ευτυχίας μέσα στην αγριότητα της καθημερινότητας. Και όλες τους ορειβάτες στο Έβερεστ της ζωής με μόνο γνώμονα τη δικαίωση...`,
        `Στην κοιλάδα της βιβλιοθήκης σας έχετε εκείνα τα θηρία. Ας τα ημερέψετε με όποιον τρόπο εσείς επιθυμείτε...`,
    ],
    pageComments: 0,
    comments: [
        // TODO
    ],
    bookImages: [
        // TODO
    ],
    social: {
        // TODO Get new 
        // facebook: 'https://www.facebook.com/%CE%97-%CE%94%CE%B9%CE%BA%CE%B1%CE%AF%CF%89%CF%83%CE%B7-%CF%84%CE%B7%CF%82-%CE%91%CE%B8%CF%89%CF%8C%CF%84%CE%B7%CF%84%CE%B1%CF%82-374566459409555/',
    },
    playlists: [
        // TODO
    ],
};