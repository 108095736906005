import React, { Component } from 'react';
import Heading from '../Heading/Heading';
import EditorialImage from '../../assets/images/editorial-tiny.jpg';
import './Editorial.css';

class Editorial extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="uk-section uk-section-muted">
                    <div className='uk-container'> 
                        <Heading>Editorial</Heading>

                        <div className="uk-grid-match uk-child-width-expand@s" uk-grid="true">
                            <div>
                                <div id="editorialImageWrapper" className='uk-flex uk-flex-center'>
                                    <img src={EditorialImage} alt="KyriakosEditorial" id="editorialImage" className="uk-box-shadow-medium"  uk-img={'1'}/>
                                </div>
                            </div>

                            <div>
                                <p className='uk-text-lead uk-text-small uk-text-justify uk-text-center uk-flex uk-flex-column uk-flex-start uk-flex-left'>
                                <span className='uk-margin-small-bottom cu-tab'>
                                        Είμαι ο Κυριάκος Κουζούμης και έχω τη μεγάλη χαρά να σας καλωσορίζω στον κόσμο μου.
                                </span>

                                    <span className='uk-margin-small-bottom cu-tab'>
                                        Έναν κόσμο που χτίζω χρόνια με μόνα υλικά τη φαντασία, το μεράκι, το μελάνι της πένας αλλά φυσικά και το word στον υπολογιστή. Το πιο σημαντικό υλικό όμως είναι η άσβεστη φλόγα μου για δημιουργία, επικοινωνία και… αυτογνωσία μέσα από τη συγγραφή.
                                        Έναν κόσμο που μοιράζομαι μαζί σας μέσα από κάθε μου συγγραφική απόπειρα…
                                </span>

                                    <span className='uk-margin-small-bottom cu-tab'>
                                        Εδώ λοιπόν θα βρείτε πολλά περισσότερα πράγματα για μένα αλλά και για τα «παιδιά» μου…
                                </span>

                                    <span className='uk-margin-small-bottom cu-tab'>
                                        Νέα, πληροφορίες, παρουσιάσεις, φωτογραφικό υλικό, βίντεο και πολλά άλλα που συγκεντρώθηκαν με υπομονή, επιλέχθηκαν με δυσκολία και… πήραν τον δρόμο της διαδικτυακής δημοσίευσης…
                                </span>

                                    <span className='uk-margin-small-bottom cu-tab'>
                                        Αισθάνομαι λιγάκι αγχωμένος γιατί ποτέ μου δεν περίμενα ότι κάποια στιγμή θα είχα τον δικό μου ιστότοπο. Και… εκείνο το ποτέ έχει γίνει τώρα! Αν σας έχω δίπλα μου, το άγχος θα απομακρυνθεί…
                                </span>

                                    <span className='uk-margin-small-bottom uk-width-1-1 uk-text-center'>
                                        Σας ευχαριστώ από καρδιάς!
                                </span>

                                    <span className='uk-width-1-1 uk-text-right cu-font uk-text-bold uk-text-large'>
                                        Εις το επανιδείν<br></br>
                                        Κυριάκος Κουζούμης
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Editorial;