import PROFILE_BIO_IMAGE from '../assets/bio/profile_bio.png';

export const BIO_INFO = {
    sections: [
        'intro',
        'study',
        'journalism',
        'writingStart',
        'writingEnd',
        'books',
        'interests',
    ],
    intro: {
        id: 1,
        thumbnail: PROFILE_BIO_IMAGE,
        thumbnailAlt: 'Κυριάκος Κουζούμης',
        text: [
            `Ο Κυριάκος Κουζούμης γεννήθηκε στην Αθήνα και μεγάλωσε στη Νίκαια του Πειραιά.`,
            `Από πολύ νωρίς είχε φέρει στην επιφάνεια την κλίση του στα φιλολογικά μαθήματα. Η έκθεση όμως… τον δυσκόλευε πολύ! Τελικά όμως, τα ‘φερε έτσι η ζωή, ώστε να τη λατρέψει, ίσως γιατί στις λευκές σελίδες των τετραδίων του, εκείνος έβρισκε την ευκαιρία να διοχετεύει το εκφραστικό του ταλέντο.`,
        ],
        icons: [
            {
                url: 'https://www.facebook.com/kyriakos.kouzoumis',
                icon: 'facebook',
                title: 'Facebook',
                color: '#4267B2',
            },

            {
                url: 'https://www.youtube.com/channel/UCwC6YyHrFznLl757c7OBzhg',
                icon: 'youtube',
                title: 'Youtube',
                color: '#d92027',

            },

            {
                url: 'https://twitter.com/k_kouzoumis',
                icon: 'twitter',
                title: 'Twitter',
                color: '#1b6ca8',
            },
            {
                url: 'https://www.linkedin.com/in/kyriakos-kouzoumis-64b879160/',
                icon: 'linkedin',
                title: 'LinkedIn',
                color: '#00bcd4',
            },
        ],
    },

    study: {
        id: 2,
        title: 'Σπουδές',
        text: [
            `Από έφηβος είχε επιλέξει το επάγγελμά του. Δημοσιογραφία! Σπούδασε στο τμήμα Επικοινωνίας και Μέσων Μαζικής Ενημέρωσης του Εθνικού και Καποδιστριακού Πανεπιστημίου Αθηνών και αποφοίτησε ύστερα από τέσσερα χρόνια…`,
        ],
    },

    journalism: {
        id: 3,
        title: `Δημοσιογραφία`,
        text: [
            `Εργάστηκε ως δημοσιογράφος στον ραδιοφωνικό σταθμό του ΑΝΤ-1, στην κρατική τηλεόραση της ΕΡΤ καθώς και στο ραδιόφωνο της ΝΕΤ. Έγραφε στη στήλη του Πολιτισμού στην εφημερίδα «Η Ελλάδα αύριο» καθώς και στο περιοδικό έντυπο «Επίκαιρα». Εργάστηκε στο Δελτίο Ειδήσεων του τηλεοπτικού σταθμού Έψιλον. Επιμελήθηκε και παρουσίασε ντοκιμαντέρ στον ΟΤΕ History. Παρουσίασε την εκπομπή συνεντεύξεων "Ομολογουμένως". Γράφει σε ειδησεογραφικούς ιστότοπους. Είναι μέλος της Πανελλήνιας Ένωσης Λογοτεχνών.`,
        ],
    },

    writingStart: {
        id: 4,
        title: `Η αρχή…`,
        text: [
            `Μόλις τελείωσε τις στρατιωτικές του υποχρεώσεις, απομονώθηκε στο αγαπημένο του νησί, τη Ζάκυνθο και άρχισε να γράφει θέλοντας να ξεχαστεί… Δίχως να το καταλάβει είχε στη μνήμη του υπολογιστή του ένα ολόκληρο μυθιστόρημα. Το πρώτο του!`,
        ],
    },

    writingEnd: {
        id: 5,
        title: `Η συνέχεια…`,
        text: [
            `Πλέον… έχει στο συγγραφικό ενεργητικό του μυθιστορήματα που έχουν ταξιδέψει τους αναγνώστες σε κόσμους εσωτερικούς αλλά και γήινους, τους έχουν συντροφεύσει μέσα από τις πλοκές τους, προβληματίσει εξαιτίας των μηνυμάτων τους, συγκινήσει χάρη στους ήρωες και τους χαρακτήρες αυτών.`,
        ],
    },

    books: {
        title: 'Βιβλιογραφία',
        id: 6, 
        text: [
            `Σμαραγδένια Φυλακή (Εκδ. Σαββάλας, 2010)`,
            `Όρκος Σιωπής (Εκδ. Σαββάλας, 2011)`,
            `Το Τελευταίο Χειροκρότημα (Εκδ. Ωκεανός, 2014) \n  (Υποψήφιο για το βραβείο «Ο πιο ερωτικός χαρακτήρας», Βραβείο Public 2015)`,
            `Η Δικαίωση της Αθωότητας (Εκδ. Ωκεανός, 2015)`,
            `Ο Γόρδιος Δεσμός των Αγγέλων (Εκδ. Υδροπλάνο, 2020)`,
        ],
    },

    interests: {
        title: 'Ενδιαφέροντα',
        id: 7, 
        text: [
            `Από μικρός στον ελεύθερο του χρόνο συνήθιζε να χαζεύει με τις ώρες στην τηλεόραση κοινωνικές και δραματικές σειρές.`,
            `Στην εφηβεία λάτρεψε τη λογοτεχνία. Έργα κλασικής, ελληνική και ξένης, λογοτεχνίας κόσμησαν τα ράφια της βιβλιοθήκης του χρίζοντάς τον θερμό αναγνώστη τους`,
            `Αδυναμία του… να κάθεται κλεισμένος στο γραφείο του διαβάζοντας ένα καλό βιβλίο που θα τον παρασύρει.`,
            `Αγαπάει τα μουσεία, τον μοντελισμό, το διαδίκτυο, τα ταξίδια! Αρέσκεται να μαθαίνει καινούρια πράγματα, να ερευνά, να ρωτά, να… «γηράσκει αεί διδασκόμενος».`,
        ],
    }
}