import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className='uk-container'>
                <div className='uk-text-center uk-text-small uk-text-muted'>&copy; Kyriakos Kouzoumis 2020</div>
            </div>
        );
    }
}

export default Footer;