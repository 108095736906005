import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
class ReferenceLink extends Component {
    render() {
        let additionalAttributes = {
            // populate with anchor attributes such as target
        };

        if (this.props.openInNewTab) {
            additionalAttributes.target = '_blank';
        }

        if (!this.props.openInNewTab) {
            return <Link to={this.props.link}>
                <div className='x uk-link-toggle uk-flex uk-flex-middle uk-flex-center'>
                    {/* {this.props.iconL ? (<span className='uk-link-heading uk-margin-small-right' uk-icon={`icon: ${this.props.iconL}`}></span>) : (null)} */}
                    <span className="uk-link-heading uk-text-bold uk-text-small">{this.props.text}</span>
                    {/* {this.props.iconR ? (<span className='uk-link-heading uk-margin-small-left' uk-icon={`icon: ${this.props.iconR}`}></span>) : (null)} */}
                </div>
            </Link> 
        }

                return (
                <a className="uk-link-toggle" {...additionalAttributes} rel="noopener noreferrer" href={this.props.link}>
                    <div className='uk-link-toggle uk-flex uk-flex-middle uk-flex-center'>
                        {/* {this.props.iconL ? (<span className='uk-link-heading uk-margin-small-right' uk-icon={`icon: ${this.props.iconL}`}></span>) : (null)} */}
                        <span className="uk-link-heading uk-text-bold uk-text-small">{this.props.text}</span>
                        {/* {this.props.iconR ? (<span className='uk-link-heading uk-margin-small-left' uk-icon={`icon: ${this.props.iconR}`}></span>) : (null)} */}
                    </div>
                </a>
                );
    }
}

                export default ReferenceLink;