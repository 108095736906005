import React, { Component } from 'react';
import LandingVideoFile from '../../assets/videos/site.mp4';
import LandingImageFile1 from '../../assets/landing_images/kyriakos-bg-image.jpg';
import LandingImageFile2 from '../../assets/landing_images/landing_image_2.jpg';
import LandingImageFile3 from '../../assets/landing_images/kouzoumis_landing_image_3.jpg';
import LandingImageFile4 from '../../assets/landing_images/kouzoumis_landing_image_4.jpg';
// import LandingImageFile5 from '../../assets/landing_images/kouzoumis_landing_image_5.jpg';
// import LandingImageFile6 from '../../assets/landing_images/kouzoumis_landing_image_6.jpg';
// import LandingImageFile7 from '../../assets/landing_images/kouzoumis_landing_image_7.jpg';
// import LandingImageFile8 from '../../assets/landing_images/kouzoumis_landing_image_8.jpg';
// import LandingImageFile9 from '../../assets/landing_images/kouzoumis_landing_image_9.jpg';
// import LandingImageFile10 from '../../assets/landing_images/kouzoumis_landing_image_10.jpg';
// import LandingImageFile11 from '../../assets/landing_images/kouzoumis_landing_image_11.jpg';


class LandingVideo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            play: true,
        };

        this.videoReference = React.createRef();
        this.hideNav = props.hideNav.bind(this);
    }

    render() {
        return (
            <div className='uk-section-muted uk-flex uk-flex-center uk-flex-middle' style={{
                padding: '20px 0',
            }}>
                <div class="uk-position-relative uk-light" tabindex="-1" uk-slideshow="animation: pull" style={{
                    width: '1080px',
                }}>

                    <ul class="uk-slideshow-items">
                        
                        <li>
                            <video ref={this.videoReference} uk-video="autoplay: true;" autoPlay muted={false} controls playsInline uk-cover={'1'} src={LandingVideoFile}></video>
                        </li>

                        <li>
                            <img src={LandingImageFile1} alt="Kyriakos Koyzoumis" uk-cover={'1'}  uk-img={'1'}/>
                        </li>
                        <li>
                            <img src={LandingImageFile2} alt="Kyriakos Koyzoumis" uk-cover={'1'}  uk-img={'1'}/>
                        </li>
                        <li>
                            <img src={LandingImageFile3} alt="Kyriakos Koyzoumis" uk-cover={'1'}  uk-img={'1'}/>
                        </li>
                        <li>
                            <img src={LandingImageFile4} alt="Kyriakos Koyzoumis" uk-cover={'1'}  uk-img={'1'}/>
                        </li>
                        {/* <li>
                            <img src={LandingImageFile5} alt="Kyriakos Koyzoumis" uk-cover={'1'}  uk-img={'1'}/>
                        </li>
                        <li>
                            <img src={LandingImageFile6} alt="Kyriakos Koyzoumis" uk-cover={'1'}  uk-img={'1'}/>
                        </li>
                        <li>
                            <img src={LandingImageFile7} alt="Kyriakos Koyzoumis" uk-cover={'1'}  uk-img={'1'}/>
                        </li>
                        <li>
                            <img src={LandingImageFile8} alt="Kyriakos Koyzoumis" uk-cover={'1'}  uk-img={'1'}/>
                        </li>
                        <li>
                            <img src={LandingImageFile9} alt="Kyriakos Koyzoumis" uk-cover={'1'}  uk-img={'1'}/>
                        </li>
                        <li>
                            <img src={LandingImageFile10} alt="Kyriakos Koyzoumis" uk-cover={'1'}  uk-img={'1'}/>
                        </li>
                        <li>
                            <img src={LandingImageFile11} alt="Kyriakos Koyzoumis" uk-cover={'1'}  uk-img={'1'}/>
                        </li> */}
                    </ul>

                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous={'1'} uk-slideshow-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next={'1'} uk-slideshow-item="next"></a>

                </div>
                {/* <div className="uk-inline">
                    <video uk-video="autoplay: true;" autoPlay src={LandingVideoFile} style={{
                        width: '1080px',
                    }}></video>
                    <div class="uk-overlay uk-position-bottom">
                        <div className='uk-flex uk-flex-between uk-flex-middle'>
                            <div className='uk-flex uk-flex-around uk-flex-middle'> */}
                {/* <span uk-icon="icon: play; ratio: 2" style={{
                                    color: '#ffffff',
                                    margin: '0 20xp 0 0',
                                }}></span>
                                <span uk-icon="icon: arrow-right; ratio: 2" style={{
                                    color: '#ffffff',
                                    margin: '0 20xp 0 0',
                                }}></span> */}
                {/* </div>

                            <span uk-icon="icon: arrow-right; ratio: 2" style={{
                                color: '#ffffff',
                            }}></span>
                        </div>
                    </div>
                </div> */}
            </div>
            // <div uk-height-viewport="expand: true">
            //     <div className='uk-height-1-1'>
            //         <video style={{
            //             width: '100%',
            //             height: 'calc(100% - 80px)',
            //             }} ref={this.videoReference} src={LandingVideoFile} loop autoplay playsInline={true} uk-video={`autoplay: ${this.state.play}`}></video>
            //         {
            //     this.state.play ? (
            //         null
            //     ) : (
            //         <div onClick={this.playVideo} className="uk-overlay uk-position-center uk-flex uk-flex-center uk-flex-middle" style={{
            //             backgroundColor: 'rgba(0, 0, 0, 0.4)',
            //             width: '100%',
            //             height: '100%',
            //         }}>
            //             <span className="uk-margin-small-right" uk-icon="icon: play-circle; ratio: 4" style={{
            //                 color: "#ffffff",
            //             }}></span>
            //         </div>
            //     )
            // }
            //     </div >
        );
    }

    playVideo = () => {
        this.setState({
            play: true,
        }, () => {
            this.hideNav();
        });
    }

    stopVideo = () => {
        // this.setState({
        //     play: false,
        // });
        console.log("Stopping video");
        this.videoReference.current.pause();
    }

    handleScroll = () => {
        // this.hideNav(true);
        this.stopVideo();
    }

    // on mount attach listener
    // on first scroll reveal the navbar
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    // remove listener on unmount to prevent any memory leaks
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}

export default LandingVideo;