import React, { Component } from 'react';
import Heading from '../Heading/Heading';
import {
    VenetiaStory,
    DesiresStory,
    AlmostALieStory,
} from '../../Config/stories.config';
import ReferenceLink from '../ReferenceLink/ReferenceLink';

class Stories extends Component {
    render() {

        let stories = [
            VenetiaStory,
            DesiresStory,
            AlmostALieStory,
        ];

        return (
            <div className='uk-container'>
                <Heading>Στο Απέναντι Παγκάκι</Heading>
                <div className='uk-flex uk-flex-center uk-flex-column uk-flex-middle uk-width-1-1'>
                    <span className='uk-text-lead'>Σύντομες ιστορίες που πήραν σάρκα και οστά σε… Παγκάκια!</span>
                    <span className='uk-text-muted'> Διηγήματα όπως αυτά δημοσιεύτηκαν στην εφημερίδα «Η Ελλάδα αύριο» (2012-2014)» </span>
                </div>

                <div className="uk-grid-match uk-child-width-expand uk-text-center uk-margin-medium" uk-grid='true'>
                    {stories.map((story, i) => {
                        return (
                            <div key={'story-li-' + i}>
                                <div className="uk-card uk-card-default">
                                    <div className="uk-card-media-top">
                                        <img src={story.cover} alt={story.title}  uk-img={'1'}/>
                                    </div>
                                    <div className="uk-card-body">
                                        <h3 className="uk-card-title uk-text-small">{story.title}</h3>
                                        <p className="uk-article-meta">{story.date}</p>
                                        <p className="uk-text">{story.hook}</p>

                                        <ReferenceLink
                                            openInNewTab={false}
                                            link={'https://kyriakoskouzoumis.com/stories/' + story.id}
                                            iconL={'file-text'}
                                            iconR={'arrow-right'}
                                            text={'Διαβάστε περισσότερα'}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

            </div>
        );
    }
}

export default Stories;