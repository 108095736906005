import React, { Component } from 'react';
import LandingVideo from '../Components/LandingVideo/LandingVideo';
import Editorial from '../Components/Editorial/Editorial';
import QuoteDivider from '../Components/QuoteDivider/QuoteDivider';
import BookSectionItem from '../Components/BookSectionItem/BookSectionItem';

// import book config
import { Smaragdenia, Orkos, Xeirokrotima, Dikaiwsi, Desmos, Arxontisa, Seira1_SexpirikosGrifos, Seira1_XakiDantela, Seira1_MegaliParaskeui, Seira1_RagismeniVera, Seira1_KlemmenoKalos, Seira1_SkoteinoNepal } from '../Config/books.config';
import Footer from '../Components/Footer/Footer';
import ScrollToTopOnMount from '../Components/ScrollToTopOnMount/ScrollToTopOnMount';
import Stories from '../Components/Stories/Stories';
import ContactForm from '../Components/ContactForm/ContactForm';

let smaragdeniaConfig = {
    ...Smaragdenia,
};

let orkosConfig = {
    ...Orkos,
};

let xeirokrotimaConfig = {
    ...Xeirokrotima,
};

let dikaiwsiConfig = {
    ...Dikaiwsi,
};

let desmnosConfig = {
    ...Desmos,
};

let arxontisaConfig = {
    ...Arxontisa,
};

let sexpirikosConfig = {
    ...Seira1_SexpirikosGrifos,
};

let dantelaConfig = {
    ...Seira1_XakiDantela,
};

let megaliParaskeuiConfig = {
    ...Seira1_MegaliParaskeui,
}

let ragismeniVeraConfig = {
    ...Seira1_RagismeniVera,
}

let klemmenoKalosConfig = {
    ...Seira1_KlemmenoKalos,
}

let skoteinoNepal = {
    ...Seira1_SkoteinoNepal,
}

class HomePage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            smaragdeniaConfig,
            orkosConfig,
            xeirokrotimaConfig,
            dikaiwsiConfig,
            desmnosConfig,
            arxontisaConfig,
            sexpirikosConfig,
            dantelaConfig,
            megaliParaskeuiConfig,
            ragismeniVeraConfig,
            klemmenoKalosConfig,
            skoteinoNepal,
        };
    }

    render() {
        return (
            <React.Fragment>
                <ScrollToTopOnMount />
                <LandingVideo hideNav={this.props.hideNav} />
                <QuoteDivider />
                <Editorial />

                <div className="uk-section uk-section-default">
                    <BookSectionItem config={this.state.skoteinoNepal} />
                </div>

                <div className="uk-section uk-section-muted">
                    <BookSectionItem config={this.state.klemmenoKalosConfig} orientation="left" />
                </div>

                <div className="uk-section uk-section-default">
                    <BookSectionItem config={this.state.ragismeniVeraConfig} />
                </div>

                <div className="uk-section uk-section-muted">
                    <BookSectionItem config={this.state.megaliParaskeuiConfig} orientation="left" />
                </div>

                <div className="uk-section uk-section-default">
                    <BookSectionItem config={this.state.sexpirikosConfig} />
                </div>

                <div className="uk-section uk-section-muted">
                    <BookSectionItem config={this.state.dantelaConfig} orientation="left" />
                </div>

                <div className="uk-section uk-section-default">
                    <BookSectionItem config={this.state.arxontisaConfig} />
                </div>

                <div className="uk-section uk-section-muted">
                    <BookSectionItem config={this.state.desmnosConfig} orientation="left" />
                </div>

                <div className="uk-section uk-section-default" style={{
                    paddingBottom: '100px'
                }}>
                    <BookSectionItem config={this.state.dikaiwsiConfig} />

                </div>

                <div className="uk-section uk-section-muted" style={{
                    paddingBottom: '100px'
                }}>
                    <BookSectionItem config={this.state.xeirokrotimaConfig} orientation="left" />
                </div>

                <div className="uk-section uk-section-default" style={{
                    paddingBottom: '100px'
                }}>
                    <BookSectionItem config={this.state.orkosConfig} />

                </div>

                <div className="uk-section uk-section-muted" style={{
                    paddingBottom: '100px'
                }}>
                    <BookSectionItem config={this.state.smaragdeniaConfig} orientation="left" />
                </div>

                {/* <div className="uk-section uk-section-default">
                    <Stories />
                </div> */}

                {/* <QuoteDivider muted={false}/> */}

                <div className="uk-section uk-section-default">
                    <ContactForm />

                    <div className='uk-margin-top-large'>
                        <Footer />
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default HomePage;