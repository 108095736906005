import GALLERY_1 from '../assets/gallery/Gallery_1-min.jpg';
import GALLERY_2 from '../assets/gallery/Gallery_2-min.jpg';
import GALLERY_3 from '../assets/gallery/Gallery_3-min.jpg';
import GALLERY_4 from '../assets/gallery/Gallery_4-min.jpg';

import GALLERY_SLIDER_1 from '../assets/gallery/Gallery_Slider_1-min.jpg';
import GALLERY_SLIDER_2 from '../assets/gallery/Gallery_Slider_2-min.jpg';
import GALLERY_SLIDER_3 from '../assets/gallery/Gallery_Slider_3-min.jpg';
import GALLERY_SLIDER_4 from '../assets/gallery/Gallery_Slider_4-min.jpg';
import GALLERY_SLIDER_5 from '../assets/gallery/Gallery_Slider_5-min.jpg';

import GALLERY_GRID_1 from '../assets/gallery/Gallery_Grid_1-min.jpg';
import GALLERY_GRID_2 from '../assets/gallery/Gallery_Grid_2-min.jpg';
import GALLERY_GRID_3 from '../assets/gallery/Gallery_Grid_3-min.jpg';
import GALLERY_GRID_4 from '../assets/gallery/Gallery_Grid_4-min.jpg';
import GALLERY_GRID_5 from '../assets/gallery/Gallery_Grid_5-min.jpg';
import GALLERY_GRID_6 from '../assets/gallery/Gallery_Grid_6-min.jpg';
import GALLERY_GRID_7 from '../assets/gallery/Gallery_Grid_7-min.jpg';
import GALLERY_GRID_8 from '../assets/gallery/Gallery_Grid_8-min.jpg';
import GALLERY_GRID_9 from '../assets/gallery/Gallery_Grid_9-min.jpg';
import GALLERY_GRID_10 from '../assets/gallery/Gallery_Grid_10-min.jpg';

import GALLERY_MAIN_1 from '../assets/gallery/GalleryMain1.jpg';
import GALLERY_MAIN_2 from '../assets/gallery/GalleryMain2.jpg';
import GALLERY_MAIN_3 from '../assets/gallery/GalleryMain3.jpg';
import GALLERY_MAIN_4 from '../assets/gallery/GalleryMain4.jpg';
import GALLERY_MAIN_5 from '../assets/gallery/GalleryMain5.jpg';
import GALLERY_MAIN_6 from '../assets/gallery/GalleryMain6.jpg';
import GALLERY_MAIN_7 from '../assets/gallery/GalleryMain7.jpg';
import GALLERY_MAIN_8 from '../assets/gallery/GalleryMain8.jpg';
import GALLERY_MAIN_9 from '../assets/gallery/GalleryMain9.jpg';
import GALLERY_MAIN_10 from '../assets/gallery/GalleryMain10.jpg';
import GALLERY_MAIN_11 from '../assets/gallery/GalleryMain11.jpg';
import GALLERY_MAIN_12 from '../assets/gallery/GalleryMain12.jpg';
import GALLERY_MAIN_13 from '../assets/gallery/GalleryMain13.jpg';
import GALLERY_MAIN_14 from '../assets/gallery/GalleryMain14.jpg';
import GALLERY_MAIN_15 from '../assets/gallery/GalleryMain15.jpg';


export const GALLERY_SLIDER_ITEMS = [
    {
        img: GALLERY_MAIN_1,
        caption: 'Φωτογράφιση για τον ιστότοπο «Kyriakoskouzoumis.com»'
    },
    {
        img: GALLERY_MAIN_2,
        caption: '20/12/2010 Electra Palace Athens, Κεντρική παρουσίαση του «Σμαραγδένια Φυλακή»'
    },
    {
        img: GALLERY_MAIN_3,
        caption: '20/2/2012 N.J.V Athens Plaza, Κεντρική παρουσίαση του «Όρκος Σιωπής»'
    },
    {
        img: GALLERY_MAIN_4,
        caption: '20/2/2012 N.J.V Athens Plaza, Κεντρική παρουσίαση του «Όρκος Σιωπής»'
    },
    {
        img: GALLERY_MAIN_5,
        caption: '7/4/2014 Θέατρο Διάνα, Κεντρική παρουσίαση του «Το Τελευταίο Χειροκρότημα»'
    },
    {
        img: GALLERY_MAIN_6,
        caption: '7/4/2014 Θέατρο Διάνα, Κεντρική παρουσίαση του «Το Τελευταίο Χειροκρότημα»'
    },
    {
        img: GALLERY_MAIN_7,
        caption: '26/5/2015 Μέγαρο Μουσικής Αθηνών, Βραβεία Βιβλίου Public, Υποψηφιότητα του «Το Τελευταίο Χειροκρότημα» στην κατηγορία «Ο Πιο Ερωτικός Χαρακτήρας»'
    },
    {
        img: GALLERY_MAIN_8,
        caption: '2/11/2015 Divani Palace Acropolis, Κεντρική παρουσίαση του «Η Δικαίωση της Αθωότητας»'
    },
    {
        img: GALLERY_MAIN_9,
        caption: '2/11/2015 Divani Palace Acropolis, Κεντρική παρουσίαση του «Η Δικαίωση της Αθωότητας»'
    },
    {
        img: GALLERY_MAIN_10,
        caption: '18/4/2016 Πολιτιστικό Κέντρο Φλοίσβος, Παρουσίαση του «Η Δικαίωση της Αθωότητας»'
    },
    {
        img: GALLERY_MAIN_11,
        caption: 'Φωτογράφιση για τον ιστότοπο «Kyriakoskouzoumis.com»'
    },
    {
        img: GALLERY_MAIN_12,
        caption: 'Φωτογράφιση για τον ιστότοπο «Kyriakoskouzoumis.com»'
    },
    {
        img: GALLERY_MAIN_13,
        caption: 'Φωτογράφιση σε επαγγελματικό project της φωτογράφου Γιώτας Εφραιμίδου'
    },
    {
        img: GALLERY_MAIN_14,
        caption: 'Φωτογράφιση για τον ιστότοπο «Kyriakoskouzoumis.com»'
    },
    {
        img: GALLERY_MAIN_15,
        caption: 'Φωτογράφιση για τον ιστότοπο «Kyriakoskouzoumis.com»'
    },
];

export const GALLERY_SECTION_1 = {
    LEFT: {
        TOP: {
            IMAGE: GALLERY_1,
            CAPTION: `Φωτογράφιση για τον ιστότοπο «Kyriakoskouzoumis.com»`,
        },
        BOTTOM: {
            IMAGE: GALLERY_4,
            CAPTION: `20/2/2012   N.J.V Athens Plaza Κεντρική παρουσίαση του «Όρκος Σιωπής»`,
        }
    },
    RIGHT: {
        TOP: {
            IMAGE: GALLERY_2,
            CAPTION: `20/2/2012 N.J.V Athens Plaza Κεντρική παρουσίαση του «Όρκος Σιωπής»`,
        },
        BOTTOM: {
            IMAGE: GALLERY_3,
            CAPTION: `2/11/2015 Divani Palace Acropolis Κεντρική παρουσίαση του «Η Δικαίωση της Αθωότητας»`,
        }
    }
}

export const GALLERY_SECTION_2 = {
    collection: [
        {
            IMAGE: GALLERY_SLIDER_1,
            CAPTION: `Φωτογράφιση για τον ιστότοπο «Kyriakoskouzoumis.com»`,
        },
        {
            IMAGE: GALLERY_SLIDER_2,
            CAPTION: `Φωτογράφιση για τον ιστότοπο «Kyriakoskouzoumis.com»`,
        },
        {
            IMAGE: GALLERY_SLIDER_3,
            CAPTION: `26/4/2012 Public Πειραιά Παρουσίαση του «Όρκος Σιωπής»`,
        },
        {
            IMAGE: GALLERY_SLIDER_4,
            CAPTION: `Φωτογράφιση για τον ιστότοπο «Kyriakoskouzoumis.com»`,
        },
        {
            IMAGE: GALLERY_SLIDER_5,
            CAPTION: `20/12/2010 Electra Palace Κεντρική παρουσίαση του «Σμαραγδένια Φυλακή»`,
        },

    ],
}

export const GALLERY_SECTION_3 = {
    collection: [
        {
            IMAGE: GALLERY_GRID_1,
            CAPTION: `20/12/2010 Electra Palace Κεντρική παρουσίαση του «Σμαραγδένια Φυλακή»`,
        },
        {
            IMAGE: GALLERY_GRID_2,
            CAPTION: `2/11/2015 Divani Palace Acropolis Κεντρική παρουσίαση του «Η Δικαίωση της Αθωότητας»`,
        },
        {
            IMAGE: GALLERY_GRID_3,
            CAPTION: `7/4/2014 Θέατρο Διάνα Κεντρική παρουσίαση του «Το Τελευταίο Χειροκρότημα»`,
        },
        {
            IMAGE: GALLERY_GRID_4,
            CAPTION: `2/11/2015 Divani Palace Acropolis Κεντρική παρουσίαση του «Η Δικαίωση της Αθωότητας»`,
        },
        {
            IMAGE: GALLERY_GRID_5,
            CAPTION: `18/4/2016 Πολιτιστικό Κέντρο Φλοίσβος Παρουσίαση του «Η Δικαίωση της Αθωότητας»`,
        },
        {
            IMAGE: GALLERY_GRID_6,
            CAPTION: `7/4/2014 Θέατρο Διάνα Κεντρική παρουσίαση του «Το Τελευταίο Χειροκρότημα»`,
        },
        {
            IMAGE: GALLERY_GRID_7,
            CAPTION: `18/4/2016 Πολιτιστικό Κέντρο Φλοίσβος Παρουσίαση του «Η Δικαίωση της Αθωότητας»`,
        },
        {
            IMAGE: GALLERY_GRID_8,
            CAPTION: `7/4/2014 Θέατρο Διάνα Κεντρική παρουσίαση του «Το Τελευταίο Χειροκρότημα»`,
        },
        {
            IMAGE: GALLERY_GRID_9,
            CAPTION: `20/12/2010 Electra Palace Κεντρική παρουσίαση του «Σμαραγδένια Φυλακή»`,
        },
        {
            IMAGE: GALLERY_GRID_10,
            CAPTION: `26/5/2015 Μέγαρο Μουσικής Βραβεία Βιβλίου Public Υποψηφιότητα του «Το Τελευταίο Χειροκρότημα» στην κατηγορία «Ο Πιο Ερωτικός Χαρακτήρας»`,
        },
    ],
}