import React, { Component } from 'react'
import VideoComponent from '../VideoComponent/VideoComponent';

export class PlaylistSection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            title: props.playlist.snippet.title,
            playlistId: props.playlist.id.playlistId,
            videos: [],
            loaded: {},
        }

        this.YT_API_KEY = 'AIzaSyBgSqUAg86PCVlULv85NTYUOYUC8sdnyno';
        this.YT_CHANNEL_ID = 'UCwC6YyHrFznLl757c7OBzhg';
        this.fetchVideos = this.fetchVideos.bind(this);
    }

    componentDidMount() {
        this.fetchVideos(this.props.bookId);
    }

    fetchVideos(bookId) {

        if (this.state.loaded[bookId]) {
            this.setState({
                videos: this.state.loaded[bookId],
            });
            return;
        }

        let endpoint = `https://www.googleapis.com/youtube/v3/playlistItems?maxResults=50&part=snippet%2C+id&playlistId=${this.props.playlist.id.playlistId}&key=${this.YT_API_KEY}`
        fetch(
            endpoint
        )
            .then(res => res.json())
            .then(json => {
                if (json.items) {
                    let updatedLoaded = {};
                    updatedLoaded[bookId] = [json.items];
    
                    this.setState({
                        videos: json.items ? json.items : [],
                        loaded: updatedLoaded,
                    })
                }
                
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.bookId != this.props.bookId) {
            this.fetchVideos(this.props.bookId);
            console.log("Previous book id: "+ prevProps.bookId);
            console.log("New book id: "+ this.props.bookId);
            console.log("Component should update: ");
        }
    //     let endpoint = `https://www.googleapis.com/youtube/v3/playlistItems?maxResults=50&part=snippet%2C+id&playlistId=${this.props.playlist.id.playlistId}&key=${this.YT_API_KEY}`
    //     fetch(
    //         endpoint
    //     )
    //         .then(res => res.json())
    //         .then(json => {
    //             this.setState({
    //                 videos: json.items ? json.items : [],
    //             })
    //         });
    }

    render() {
        return (
            <div style={{ marginBottom: '50px' }}>
                <div className='uk-flex uk-flex-center'>
                    <span className='uk-margin-small-left playlist-title' style={{
                        marginBottom: '20px',

                    }}>{this.props.playlist.snippet.title}</span>
                </div>
                <div className='uk-flex uk-flex-wrap uk-margin-small-left uk-margin-small-right uk-flex-center'>
                    {this.state.videos.map((v, i) => {
                        return (<div key={'v_' + i} >
                            <VideoComponent video={v} />
                        </div>);
                    })}
                </div>
            </div>
        )
    }
}

export default PlaylistSection
