import React, { Component } from 'react';

class Comment extends Component {
    render() {

        if (this.props.children.box) {
            if (this.props.center) {
                return this.props.children.msgs.map((item, i) => {
                    return (
                        <div className='uk-width-1-1 uk-flex uk-flex-center' key={'box-item'+i+(new Date())}>
                            <div className='uk-flex uk-flex-column uk-margin-medium uk-text-italic uk-width-1-2 uk-visible@m'>
                                <div className='uk-flex uk-flex-left uk-flex-column uk-flex-right'>
                                    <span className='uk-text-right'>
                                        {item.msg}
                                        <br />
                                        <span className='uk-text-bold uk-text-right'>{item.author}</span>
                                    </span>
                                    {/* <span className='uk-text-bold uk-text-right'>{this.props.children.author}</span> */}
                                </div>
                            </div>
                            <div className='uk-flex uk-flex-column uk-margin-medium uk-text-italic uk-width-1-1 uk-hidden@m'>
                                <div className='uk-flex uk-flex-left uk-flex-column uk-flex-right'>
                                    <span className='uk-text-right'>
                                        {item.msg}
                                        <br />
                                        <span className='uk-text-bold uk-text-right'>{item.author}</span>
                                    </span>
                                    {/* <span className='uk-text-bold uk-text-right'>{this.props.children.author}</span> */}
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            if (!this.props.left) {
                return this.props.children.msgs.map((item, i) => {
                    return (
                        <div className='uk-width-1-1 uk-flex uk-flex-left' key={'box-item'+i+(new Date())}>
                            <div className='uk-flex uk-flex-column uk-margin-medium uk-text-italic uk-width-1-2'>
                                <div className='uk-flex uk-flex-left uk-flex-column uk-flex-middle'>
                                    <span className='uk-text-right'>
                                        {item.msg}
                                        <br />
                                        <span className='uk-text-bold uk-text-right'>{item.author}</span>
                                    </span>
                                    {/* <span className='uk-text-bold uk-text-right'>{this.props.children.author}</span> */}
                                </div>
                            </div>
                        </div>
                    )
                });
            }

            return this.props.children.msgs.map((item, i) => {
                return (
                    <div className='uk-width-1-1 uk-flex uk-flex-right' key={'box-item'+i+(new Date())}>
                        <div className='uk-flex uk-flex-column uk-margin-medium uk-text-italic uk-width-1-2'>
                            <div className='uk-flex uk-flex-left uk-flex-column uk-flex-middle'>
                                <span className='uk-text-right'>
                                    {item.msg}
                                    <br />
                                    <span className='uk-text-bold uk-text-right'>{item.author}</span>
                                </span>
                                {/* <span className='uk-text-bold uk-text-right'>{this.props.children.author}</span> */}
                            </div>
                        </div>
                    </div>
                )
            });
        }

        if (this.props.center) {
            return (
                <div className='uk-width-1-1 uk-flex uk-flex-center'>
                    <div className='uk-flex uk-flex-column uk-margin-medium uk-text-italic uk-width-1-1@s uk-width-1-2@m'>
                        <div className='uk-flex uk-flex-left uk-flex-column uk-flex-middle'>
                            <span className='uk-text-right'>
                                {this.props.children.msg}
                                <br />
                                <span className='uk-text-bold uk-text-right'>{this.props.children.author}</span>
                            </span>
                            {/* <span className='uk-text-bold uk-text-right'>{this.props.children.author}</span> */}
                        </div>
                    </div>
                </div>
            )
        }

        return (
            !this.props.left ? (
                <div className='uk-width-1-1 uk-flex uk-flex-left'>
                    <div className='uk-flex uk-flex-column uk-margin-medium uk-text-italic uk-width-1-2'>
                        <div className='uk-flex uk-flex-left uk-flex-column uk-flex-middle'>
                            <span className='uk-text-right'>
                                {this.props.children.msg}
                                <br />
                                <span className='uk-text-bold uk-text-right'>{this.props.children.author}</span>
                            </span>
                            {/* <span className='uk-text-bold uk-text-right'>{this.props.children.author}</span> */}
                        </div>
                    </div>
                </div>
            ) : (
                    <div className='uk-width-1-1 uk-flex uk-flex-right'>
                        <div className='uk-flex uk-flex-column uk-margin-medium uk-text-italic uk-width-1-2'>
                            <div className='uk-flex uk-flex-right uk-flex-column'>
                                <span className='uk-text-right'>
                                    {this.props.children.msg}
                                    <br />

                                    <span className='uk-text-bold uk-text-right'>{this.props.children.author}</span>

                                </span>
                                {/* <span className='uk-text-bold uk-text-right'>{this.props.children.author}</span> */}
                            </div>
                        </div>
                    </div>
                )
        );
    }
}

export default Comment;