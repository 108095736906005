import React, { Component } from 'react';
import { mapUrl } from '../../Config/url.config';

class NavigationBar extends Component {

    constructor(props) {
        super(props)

        this.state = {
            visible: false,
        }

        this.toggleVisibility = this.toggleVisibility.bind(this);
    }

    toggleVisibility() {
        if (this.props.scrolled) {
            return;
        }

        this.setState({
            visible: !this.state.visible,
        });
    }

    render() {
        let currentLocation = window.location.pathname;
        let isHome = false; //(currentLocation === '/') || (currentLocation === '/home');
        let mobileNavHeader = mapUrl();

        let shouldAnimate = this.state.shouldAnimate;
        let animationClass = 'uk-animation-slide-top';
        let defaultClass = 'cu-top-index';
        let spanColorClass = !this.state.visible && isHome ? 'nav-color-span' : '';
        let classesToAdd = shouldAnimate ? [defaultClass, spanColorClass, animationClass].join(' ') : [defaultClass, spanColorClass].join(' ');

        return (this.props.visible ? (
            <React.Fragment>
                <div className='uk-background-secondary' onMouseOver={this.toggleVisibility} onMouseOut={this.toggleVisibility}>
                    <nav className={`uk-visible@s ${classesToAdd} uk-position-fixed uk-width-1-1 uk-navbar-container uk-box-shadow-medium`} uk-navbar='true' style={{
                        backgroundColor: !this.state.visible && isHome ? 'rgba(0, 0, 0, 0.4)' : 'white',
                        transition: 'all ease-in-out 0.3s',
                    }}>
                        <div className="uk-navbar-center uk-flex uk-flex-center">
                            <ul className="uk-navbar-nav">
                                {this.props.children}
                            </ul>
                        </div>
                    </nav>

                    <nav className={`uk-hidden@s ${classesToAdd} uk-position-fixed uk-width-1-1 uk-navbar-container uk-box-shadow-medium`} uk-navbar='true' style={{
                        backgroundColor: !this.state.visible && isHome ? 'rgba(0, 0, 0, 0.4)' : 'white',
                        transition: 'all ease-in-out 0.3s',
                    }}>
                        <div className="uk-navbar-left">
                            <a className="uk-navbar-toggle" href="#" uk-toggle="target: #offcanvas-overlay">
                                <span uk-navbar-toggle-icon={'1'}></span> <span className="uk-margin-small-left cu-custom-transition">{mobileNavHeader}</span>
                            </a>
                        </div>

                        <div id="offcanvas-overlay" uk-offcanvas="overlay: true">
                            <div className="uk-offcanvas-bar uk-flex uk-flex-center uk-flex-middle" style={{
                                
                            }}>

                                <button className="uk-offcanvas-close" type="button" uk-close={1}></button>


                                <ul className="uk-nav uk-nav-primary uk-nav-left uk-margin-auto-vertical">
                                    {this.props.children}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                <div style={{ height: '80px', zIndex: -1, position: isHome ? 'absolute' : 'relative' }}></div>
            </React.Fragment>
        ) : (
                null
            )
        );
    }

    handleScroll = () => {
        this.showNavigation();
    }

    showNavigation = () => {
        this.setState({
            visible: true,
        });
    }

    hideNavigation = () => {
        this.setState({
            visible: false,
        });
    }

    // on mount attach listener
    // on first scroll reveal the navbar
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

    }

    // remove listener on unmount to prevent any memory leaks
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}

export default NavigationBar;