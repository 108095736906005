import React, { Component } from 'react';
import ReferenceLink from '../ReferenceLink/ReferenceLink';

import './BookSectionItem.css';

class BookSectionItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: props.config.id,
            orientation: props.orientation ? props.orientation : 'right',
            title: props.config.title,
            cover: props.config.cover,
            shortDescription: props.config.shortDescription,
        };
    }

    getBookCover = () => {
        return (<React.Fragment>
            <div className={`uk-flex ${this.state.orientation === 'left' ? 'uk-flex-left' : ' uk-flex-right'} uk-visible@m`}>
                <img src={this.state.cover} alt={this.state.title} className="uk-box-shadow-large cu-book-cover" uk-img={'1'} />
            </div>

            <div className={`uk-flex uk-flex-center uk-hidden@m`}>
                <img src={this.state.cover} alt={this.state.title} className="uk-box-shadow-large cu-book-cover" uk-img={'1'} />
            </div>
        </React.Fragment>);
    }

    getBookDescription = () => {
        return (
            <div className='uk-text-left'>
                {/* <h1 className="uk-heading-bullet">{this.state.title}</h1> */}
                <h1 className="uk-article-title font-cali uk-text-bolder uk-visible@m">{this.state.title}</h1>

                <p className='cu-tab'>{this.state.shortDescription}</p>

                <div className='uk-width-1-1 uk-flex uk-flex-start'>
                    <ReferenceLink
                        openInNewTab={false}
                        link={`/book/${this.state.id}`}
                        iconL={'file-text'}
                        text={'Διαβάστε περισσότερα στη σελίδα του βιβλίου.'}
                    />
                </div>
            </div>
        );
    }
    render() {
        return (
            <React.Fragment>
                <div className='uk-container uk-visible@m'>
                    <React.Fragment>{this.state.orientation === 'left' ? (
                        <React.Fragment>
                            <div className='uk-flex uk-flex-between uk-flex-center'>
                                <div className="uk-text-center uk-width-1-2 uk-flex-start">
                                    {this.getBookCover()}
                                </div>
                                <div className="uk-text-center uk-width-1-2">
                                    {this.getBookDescription()}
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                            <React.Fragment>
                                <div className='uk-flex uk-flex-around uk-flex-center'>
                                    <div className="uk-text-center uk-width-1-2">
                                        {this.getBookDescription()}
                                    </div>
                                    <div className="uk-text-center uk-width-1-2">
                                        {this.getBookCover()}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}</React.Fragment>
                </div>
                <div className='uk-container uk-hidden@m'>
                    <React.Fragment>{this.state.orientation === 'left' ? (
                        <React.Fragment>
                            <div className='uk-flex uk-flex-between uk-flex-center uk-flex-column'>
                                <h1 className="uk-article-title font-cali uk-text-bolder">{this.state.title}</h1>

                                <div className="uk-text-center uk-width-1-1 uk-flex-start">
                                    {this.getBookCover()}
                                </div>

                                <div className="uk-text-center uk-width-1-1">
                                    {this.getBookDescription()}
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                            <React.Fragment>
                                <div className='uk-flex uk-flex-around uk-flex-center uk-flex-column'>
                                    <h1 className="uk-article-title font-cali uk-text-bolder">{this.state.title}</h1>

                                    <div className="uk-text-center uk-width-1-1">
                                        {this.getBookCover()}
                                    </div>

                                    <div className="uk-text-center uk-width-1-1">
                                        {this.getBookDescription()}
                                    </div>

                                </div>
                            </React.Fragment>
                        )}</React.Fragment>
                </div>
            </React.Fragment>
        );
    }
}

export default BookSectionItem;