import React, { Component } from 'react'
import { GALLERY_SECTION_1, GALLERY_SECTION_2, GALLERY_SECTION_3, GALLERY_SLIDER_ITEMS } from '../Config/gallery.config';
import Footer from '../Components/Footer/Footer';
import CustomSlider from '../Components/CustomSlider/CustomSlider';
import ScrollToTopOnMount from '../Components/ScrollToTopOnMount/ScrollToTopOnMount';

export class Gallery extends Component {
    render() {

        let slides = GALLERY_SLIDER_ITEMS;

        let slides2 = GALLERY_SLIDER_ITEMS.map((el, i) => {
            return <img src={el.img} alt={this.props.title} key={'el' + i} />
        })

        return (<React.Fragment>
            <ScrollToTopOnMount />

            <div uk-lightbox={'1'} className='uk-visible@m'>
                <CustomSlider slides={slides} />
            </div>
            <div className='uk-container uk-flex uk-flex-center uk-flex-column uk-flex-middle uk-visible@m'>

                <div className='uk-margin-top-medium uk-flex uk-flex-center'>
                    <div className="uk-grid-small uk-child-width-1-6 uk-flex-center uk-text-center" uk-grid='true' uk-lightbox='animation: scale' style={{
                        // backgroundColor: 'black',
                        padding: '10px',
                        maxWidth: '60vw'
                    }}>
                        {
                            GALLERY_SLIDER_ITEMS.map((el, i) => {
                                return (
                                    <a href={el.img}
                                        className="uk-inline uk-padding-remove uk-background-transparent uk-flex uk-flex-center uk-flex-middle"
                                        data-caption={el.caption}
                                        key={'book-image-' + i}
                                        uk-scrollspy="cls: uk-animation-scale-up; repeat: false"
                                        style={{
                                            backgroundColor: 'black',
                                            margin: '12.5px',
                                        }}
                                    >
                                        <img
                                            className='uk-box-shadow-medium'
                                            src={el.img}
                                            alt={el.caption}
                                            style={{
                                                height: '200px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </a>
                                );
                            })
                        }
                    </div>

                </div>

                <div className="uk-section uk-section-default uk-margin-medium-top">
                    <Footer />
                </div>
            </div>

            <div className='uk-hidden@m'>
                {/* <div className='' style={{
                    marginTop: '12.5px',
                }}>
                    <div class="uk-position-relative uk-light" tabindex="-1" uk-slider={'autoplay: true'}>

                        <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m" uk-lightbox="animation: slide">
                            {GALLERY_SECTION_2.collection.map((item, i) => {
                                return (
                                    <li key={'photo-galler-i' + i} >
                                        <a href={item.IMAGE} data-caption={item.CAPTION}>
                                            <img src={item.IMAGE} alt={item.CAPTION} uk-img={'1'} />
                                            <div class="uk-overlay uk-light uk-overlay-primary uk-position-bottom">
                                                <div style={{
                                                    height: '15px',
                                                }}>
                                                    <p className='uk-text-truncate uk-text-small' >{item.CAPTION} </p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                );
                            })}

                        </ul>

                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous={'1'} uk-slider-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next={'1'} uk-slider-item="next"></a>

                    </div>
                </div> */}

                <div className='uk-flex uk-flex-top uk-flex-wrap' uk-lightbox={'1'} style={{
                    marginTop: '12.5px',
                }}>
                    {slides.map((item, i) => {
                        return (<div style={{
                            width: '100%',
                            height: '300px',
                            marginBottom: '12.5px',
                        }} key={'gallery-grid-item-' + i}>
                            <a href={item.img} data-caption={item.caption} >
                                <div className="uk-inline" style={{
                                    width: '100%',
                                    height: '100%',
                                }}>
                                    <img src={item.img} alt={item.caption} style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'cover',
                                    }} uk-img={'1'} />
                                    <div class="uk-overlay uk-light uk-overlay-primary uk-position-bottom">
                                        <p className='uk-text-small'>{item.caption} </p>
                                    </div>
                                </div>
                            </a>
                        </div>)
                    })}
                </div>

            </div>

            <div className="uk-section uk-section-default uk-margin-medium-top">
                <Footer />
            </div>
            {/* <div className='uk-hidden@m' uk-lightbox={'1'}>
                {slides.map((el, i) => {
                    return (<React.Fragment key={'slide-mobile-' + i}>
                        <div>
                            <div className='uk-width-2-3'>
                                <span className='uk-text-truncate'>{el.caption}</span>
                            </div>

                            <div data-caption={el.caption} className='uk-width-1-1'>
                                <img className='uk-box-shadow-medium'
                                    src={el.img}
                                    alt={el.caption}
                                    style={{
                                        height: '300px',
                                        objectFit: 'cover',
                                    }}
                                ></img>
                            </div>
                        </div>
                    </React.Fragment>);
                })}
            </div> */}
        </React.Fragment>
        );

        // return (
        // <div>
        //     <div className='uk-flex uk-flex-top'>
        //         <div style={{
        //             width: '33%',
        //         }}>
        //             <div style={{
        //                 height: '700px',
        //             }} uk-lightbox="animation: slide">
        //                 <a href={GALLERY_SECTION_1.LEFT.TOP.IMAGE} data-caption={GALLERY_SECTION_1.LEFT.TOP.CAPTION} >
        //                     <div className="uk-inline" style={{
        //                         width: '100%',
        //                         height: '100%',
        //                     }}>
        //                         <img src={GALLERY_SECTION_1.LEFT.TOP.IMAGE} alt={GALLERY_SECTION_1.LEFT.TOP.CAPTION} style={{
        //                             height: '100%',
        //                             width: '100%',
        //                             objectFit: 'cover',
        //                         }}  uk-img={'1'}/>
        //                         <div class="uk-overlay uk-light uk-overlay-primary uk-position-bottom">
        //                             <p>{GALLERY_SECTION_1.LEFT.TOP.CAPTION} </p>
        //                         </div>
        //                     </div>
        //                 </a>
        //             </div>

        //             <div style={{
        //                 height: '500px',
        //             }} uk-lightbox="animation: slide">
        //                 <a href={GALLERY_SECTION_1.LEFT.BOTTOM.IMAGE} data-caption={GALLERY_SECTION_1.LEFT.BOTTOM.CAPTION} >
        //                     <div className="uk-inline" style={{
        //                         width: '100%',
        //                         height: '100%',
        //                     }}>
        //                         <img src={GALLERY_SECTION_1.LEFT.BOTTOM.IMAGE} alt={GALLERY_SECTION_1.LEFT.BOTTOM.CAPTION} style={{
        //                             height: '100%',
        //                             width: '100%',
        //                             objectFit: 'cover',
        //                         }}  uk-img={'1'}/>
        //                         <div class="uk-overlay uk-light uk-overlay-primary uk-position-bottom">
        //                             <p>{GALLERY_SECTION_1.LEFT.BOTTOM.CAPTION} </p>
        //                         </div>
        //                     </div>
        //                 </a>
        //             </div>
        //         </div>

        //         <div style={{
        //             width: '67%',
        //         }}>
        //             <div style={{
        //                 height: '600px',
        //             }} uk-lightbox="animation: slide">
        //                 <a href={GALLERY_SECTION_1.RIGHT.TOP.IMAGE} data-caption={GALLERY_SECTION_1.RIGHT.TOP.CAPTION}>
        //                     <div className="uk-inline" style={{
        //                         width: '100%',
        //                         height: '100%',
        //                     }}>
        //                         <img src={GALLERY_SECTION_1.RIGHT.TOP.IMAGE} alt={GALLERY_SECTION_1.RIGHT.TOP.CAPTION} style={{
        //                             height: '100%',
        //                             width: '100%',
        //                             objectFit: 'cover',
        //                         }}  uk-img={'1'}/>
        //                         <div class="uk-overlay uk-light uk-overlay-primary uk-position-bottom">
        //                             <p>{GALLERY_SECTION_1.RIGHT.TOP.CAPTION} </p>
        //                         </div>
        //                     </div>
        //                 </a>
        //             </div>
        //             <div style={{
        //                 height: '600px',
        //             }} uk-lightbox="animation: slide">
        //                 <a href={GALLERY_SECTION_1.RIGHT.BOTTOM.IMAGE} data-caption={GALLERY_SECTION_1.RIGHT.BOTTOM.CAPTION} >
        //                     <div className="uk-inline" style={{
        //                         width: '100%',
        //                         height: '100%',
        //                     }}>
        //                         <img src={GALLERY_SECTION_1.RIGHT.BOTTOM.IMAGE} alt={GALLERY_SECTION_1.RIGHT.BOTTOM.CAPTION} style={{
        //                             height: '100%',
        //                             width: '100%',
        //                             objectFit: 'cover',
        //                         }}  uk-img={'1'}/>
        //                         <div class="uk-overlay uk-light uk-overlay-primary uk-position-bottom">
        //                             <p>{GALLERY_SECTION_1.RIGHT.BOTTOM.CAPTION} </p>
        //                         </div>
        //                     </div>
        //                 </a>
        //             </div>
        //         </div>
        //     </div>


        // <div className='uk-margin-medium-top'>
        //     <div class="uk-position-relative uk-light" tabindex="-1" uk-slider={'autoplay: true'}>

        //         <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m" uk-lightbox="animation: slide">
        //             {GALLERY_SECTION_2.collection.map((item, i) => {
        //                 return (
        //                     <li key={'photo-galler-i' + i} >
        //                         <a href={item.IMAGE} data-caption={item.CAPTION}>
        //                             <img src={item.IMAGE} alt={item.CAPTION}  uk-img={'1'}/>
        //                             <div class="uk-overlay uk-light uk-overlay-primary uk-position-bottom">
        //                                 <p>{item.CAPTION} </p>
        //                             </div>
        //                         </a>
        //                     </li>
        //                 );
        //             })}

        //         </ul>

        //         <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous={'1'} uk-slider-item="previous"></a>
        //         <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next={'1'} uk-slider-item="next"></a>

        //     </div>
        // </div>

        //     </div>
        // )
    }
}

export default Gallery;


