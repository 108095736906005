export const mapUrl = () => {
    console.log('Current url:' + window.location.href);
    let url = window.location.href;
    if (url.includes('/book/')) {
        return 'Συγγραφικό Έργο';
    }

    if (url.includes('/newsfeed')) {
        return 'Νέα';
    }

    if (url.includes('/gallery')) {
        return 'Photo Gallery';
    }

    if (url.includes('/gallery')) {
        return 'Photo Gallery';
    }

    if (url.includes('/presentation/')) {
        return 'Βιβλιοπαρουσιάσεις';
    }

    if (url.includes('/bio')) {
        return 'Για ΄μένα...';
    }

    return 'Αρχική';
}