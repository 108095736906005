import React, { Component } from 'react';
import PenImage from '../../assets/images/transparent-pen.png';
import './QuoteDivider.css';

class QuoteDivider extends Component {

    constructor(props) {
        super(props)

        this.state = {
            orientation: props.orientation ? props.orientation : 'right',
        }
    }

    render() {
        let sectionClassList = this.props.muted ? 'uk-section uk-section-muted' : 'uk-section uk-section-default';

        return (
            this.state.orientation === 'right' ? (
                <div className={sectionClassList}>
                    <div className='uk-width-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-hidden@m'>
                        <img src={PenImage} alt="KyriakosEditorial" id='penImage' className="uk-background-transparent"  uk-img={'1'}/>
                        <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                            <span className='cu-font uk-text-bolder uk-text-center' style={{
                                fontSize: '2.025rem',
                            }}>"Ανάμεσα στην ευτυχία και την αλήθεια επιλέγω πάντα το δεύτερο"</span>
                            <span className='uk-width-1-1 uk-text-center'>
                                <span className='cu-font uk-text-bold uk-text-center uk-text-large uk-text-italic' style={{
                                    fontSize: '2.025rem',
                                }}> Κυριάκος Κουζούμης</span>
                            </span>
                        </div>
                    </div>

                    <div className='uk-width-1-1 uk-flex uk-flex-center uk-flex-middle uk-visible@m'>
                        <img src={PenImage} alt="KyriakosEditorial" id='penImage' className="uk-background-transparent"  uk-img={'1'}/>
                        <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                            <span className='cu-font uk-text-bolder uk-text-center' style={{
                                fontSize: '2.025rem',
                            }}>"Ανάμεσα στην ευτυχία και την αλήθεια επιλέγω πάντα το δεύτερο"</span>
                            <span className='uk-width-1-1 uk-text-right'>
                                <span className='cu-font uk-text-bold uk-text-center uk-text-large uk-text-italic' style={{
                                    fontSize: '2.025rem',
                                }}> Κυριάκος Κουζούμης</span>
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                    <div className={sectionClassList}>
                        <div className='uk-width-1-1 uk-flex uk-flex-center uk-flex-middle'>
                            <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                                <span className='cu-font uk-text-bolder uk-text-center uk-text-large' style={{
                                    fontSize: '2.025rem',
                                }}>"Ανάμεσα στην ευτυχία και την αλήθεια επιλέγω πάντα το δεύτερο"</span>
                                <span className='uk-width-1-1 uk-text-right'>
                                    <span className='cu-font uk-text-bold uk-text-center uk-text-large uk-text-italic' style={{
                                        fontSize: '2.025rem',
                                    }}>Κυριάκος Κουζούμης</span>
                                </span>
                            </div>
                            <img src={PenImage} alt="KyriakosEditorial" id='penImage' className="uk-background-transparent"  uk-img={'1'}/>
                        </div>
                    </div>
                )

        );
    }
}

export default QuoteDivider;