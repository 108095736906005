import New_thumb_001 from '../assets/news/news_1.jpg';
import New_thumb_002 from '../assets/news/news_2.jpg';

const New_001 = {
    id: 1,
    thumbnail: New_thumb_001,
    alignLeft: false,
    thumbnailAlt: 'Ο Κυριάκος Κουζούμης και οι εκδόσεις Υδροπλάνο',
    date: '01/06/2020',
    title: 'Συγγραφικό Ταξίδι με «Υδροπλάνο»',
    heading: 'Τον Φεβρουάριο του 2020 ένωσα τα χέρια με τον εκδοτικό οίκο Υδροπλάνο.',
    preview: [
        `Ένας νεοσύστατος εκδοτικός οίκος, πολλά υποσχόμενος και με άφθονο μεράκι στα φόρα του βιβλίου δηλώνει το «παρών» και πλέον με έχει επιβάτη του πληρώματός του.`,
        `Το επόμενο αστυνομικό κοινωνικό μυθιστόρημά μου με τίτλο «Ο Γόρδιος Δεσμός των Αγγέλων» θα φέρει τη σφραγίδα του Υδροπλάνου και θα ταξιδέψει στα βιβλιοπωλεία αλλά και στα χέρια των αναγνωστών εντός του καλοκαιριού του ίδιου έτους. Ποικίλοι χαρακτήρες, πλούσια πλοκή, μυστήριο, ανατροπές και μία απόδειξη ότι η μοίρα ενώνει καθετί αταίριαστο, κουβαριάζει τις ψυχές και μένει μονάχα ο χρόνος για να απονείμει τη δικαιοσύνη!`,
        `Ένα ειλικρινές ευχαριστώ στη Τύχη, διότι εκείνη μου όρισε το πρώτο ραντεβού με την Αγγελική Χατζηρήγα. Σύμπτωση το όνομά της; Χμ, δεν νομίζω! Τελικά τίποτα δεν είναι τυχαίο! Ένα τίμιο ευχαριστώ στην Αναστασία Κορινθίου που με αγκάλιασε και με έχρισε μέλος του συγγραφικού δυναμικού και τέλος στον εκδότη, κύριο Θοδωρή Ευθυμιάδη που με υποδέχτηκε με χαρά.`,
        `Η όλη συνεργασία μου με τα πρόσωπα του εκδοτικού ήταν άρτια και με έπεισε πως έκανα τη σωστή επιλογή.`,
        `Ελπίζω το νέο μου «παιδί» να αποδειχθεί αντάξιο των προσδοκιών και να συντροφεύσει τους αναγνώστες.`,
        `Καλό μας ταξίδι λοιπόν…`,
    ],
    text: [],
};

const New_002 = {
    id: 2,
    thumbnail: New_thumb_002,
    alignLeft: false,
    thumbnailAlt: 'Ο Κυριάκος Κουζούμης και οι εκδόσεις Υδροπλάνο',
    date: '01/09/2021',
    title: '«Η Αρχόντισσα των Αερικών»',
    heading: '',
    preview: [
        `Ένα μεταφυσικό αστυνομικό μυθιστόρημα με φόντο τη γραφική Μακρινίτσα και με ήρωες πρόσωπα της διπλανής πόρτας. Με μυστήριο, ανατροπές, αναπάντεχα περιστατικά και την απόδειξη ότι η λογική της φύσης -ενίοτε- παραδίνεται στη μαγεία στη μεταφυσικής!`,
        `Ένα ειλικρινέστατο ευχαριστώ στις εκδόσεις Υδροπλάνο που με εμπιστεύτηκαν για δεύτερη φορά και πίστεψαν στο νέο μου «παιδάκι»!`,
        `Αναμένεται να κυκλοφορήσει τον Σεπτέμβριο του 2021.`,
        `Είθε να έχουμε σύμμαχο τον καιρό και… καλά μας ταξίδια!!!`,
    ],
    text: [],
};

export const NEWS_ALBUM = [
    New_002,
    New_001,
];