import React, { Component } from 'react';
import PagesLeft from '../assets/books/pages-left.png';
import PagesRight from '../assets/books/pages-right.jpg';
import Heading from '../Components/Heading/Heading';
import Footer from '../Components/Footer/Footer';
import Comment from '../Components/Comment/Comment';
import ScrollToTopOnMount from '../Components/ScrollToTopOnMount/ScrollToTopOnMount';
import ReferenceLink from '../Components/ReferenceLink/ReferenceLink';
import PlaylistSection from '../Components/PlaylistSection/PlaylistSection';
import CustomSlider from '../Components/CustomSlider/CustomSlider'

class BookPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            landingImageWidth: 100,
            landingImageHeight: 100,
        }

        this.landingImageReference = React.createRef();
    }

    componentDidMount() {
        this.forceUpdate();
    }

    getSynopsis = () => {
        return (
            <div className='uk-flex uk-flex-column uk-flex-middle' style={{
                color: 'black',
                height: '560px',
                padding: '20px 40px 20px 20px',
            }}>
                <span className="uk-text-center" style={{
                    marginBottom: this.props.id == 5 ? '10px' : 'auto',
                }}><span className={`book-landing-heading`} style={{
                    color: 'black',

                }}>Σύνοψις</span></span>
                <div className='uk-width-1-1 uk-flex uk-flex-column uk-flex-center uk-flex-around' style={{
                    height: '500px',
                }}>
                    {this.props.fullDescription.map((el, i) => {
                        if (i === 0) {
                            return (
                                <span key={i} className='uk-dropcap uk-margin-small-bottom uk-text-small uk-text-justify' style={{
                                    fontSize: '13px',
                                }}>{el}</span>
                            );
                        } else {
                            return (
                                <span key={i} className='uk-text-small uk-margin-small-bottom uk-text-justify' style={{
                                    fontSize: '13px',
                                }}>{el}</span>
                            );
                        }
                    })}
                </div>
            </div>
        )
    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    setDimensions = (event) => {
        this.setState({
            landingImageWidth: event.target.width,
            landingImageHeight: event.target.height,
        })
    }

    render() {
        return (
            <React.Fragment>
                <ScrollToTopOnMount />

                <div className="uk-cover-container uk-position-relative uk-visible@m">
                    <canvas width="100%" height="680px"></canvas>
                    <img src={this.props.pageCoverFull} alt="" uk-cover={1} uk-img={'1'} />
                    <div className="uk-overlay uk-light uk-position-center">
                        <div className='uk-flex uk-flex-center uk-flex-middle' style={{
                        }}>
                            <div>
                                <img src={PagesLeft} alt={this.props.title} style={{
                                    height: `${600}px`,
                                }} uk-img={'1'} />

                                <img src={this.props.coverRealSize} alt={this.props.title}
                                    className='book-cover-landing uk-box-shadow-medium'
                                    style={{
                                        height: `${620}px`,
                                        position: 'absolute',
                                        top: '20px',
                                        left: '20px',
                                        right: '0px',
                                        bottom: '0px',
                                    }} uk-img={'1'} />
                            </div>
                            <div
                                className="uk-background-center-center uk-background-cover"
                                style={{
                                    backgroundImage: `url(${PagesRight})`,
                                    height: `${600}px`,
                                    width: `480px`,
                                }}>
                                {this.getSynopsis()}
                            </div>



                        </div>
                    </div>
                </div>

                <div class='uk-flex uk-flex-column uk-flex-center uk-hidden@m'>
                    <div className='uk-cover-container'>
                        <canvas width="100%" height="500px"></canvas>
                        <img src={this.props.pageCoverFull} alt="" uk-cover={1} uk-img={'1'} />
                        <div className="uk-overlay uk-light uk-position-center">
                            <div className='uk-flex uk-flex-center uk-flex-middle' style={{
                            }}>
                                <img src={this.props.coverRealSize} alt={this.props.title} className='book-cover-landing uk-box-shadow-medium' style={{
                                    height: `${400}px`,
                                }} uk-img={'1'} />
                            </div>
                        </div>
                    </div>

                    <div className='uk-flex uk-flex-column uk-flex-center' style={{
                        padding: '12.5px 20px',
                    }}>
                        <span className={`book-landing-heading uk-width-1-1 uk-text-center`}
                            style={{
                                color: 'black',
                            }}>
                            Σύνοψις
                        </span>

                        <div className='uk-width-1-1 uk-flex uk-flex-column uk-flex-center uk-flex-around'>
                            {this.props.fullDescription.map((el, i) => {
                                if (i === 0) {
                                    return (
                                        <span key={i} className='uk-dropcap uk-margin-small-bottom uk-text-small uk-text-justify' style={{
                                            fontSize: '13px',
                                        }}>{el}</span>
                                    );
                                } else {
                                    return (
                                        <span key={i} className='uk-text-small uk-margin-small-bottom uk-text-justify' style={{
                                            fontSize: '13px',
                                        }}>{el}</span>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>

                {/* <div className="uk-section-default">
                    <div>
                        <div className='uk-position-relative' style={{
                        }}>
                            <img ref={this.landingImageReference}
                                src={this.props.pageCoverFull}
                                width={window.innerWidth}
                                onLoad={this.setDimensions} alt=""
                                style={{
                                    width: window.innerWidth,
                                    maxHeight: '60vh'
                                }} />

                            <div className="uk-overlay uk-light uk-position-center">
                                <div className='uk-flex' style={{
                                }}>
                                    <img src={PagesLeft} alt={this.props.title} style={{
                                        height: `${this.state.landingImageHeight - 50}px`,
                                    }} />

                                    <div
                                        className="uk-background-center-center uk-background-cover"
                                        style={{
                                            backgroundImage: `url(${PagesRight})`,
                                            height: `${this.state.landingImageHeight - 50}px`,
                                            width: `${this.state.landingImageWidth / 4}px`,
                                        }}>
                                        {this.getSynopsis()}
                                    </div>


                                    <img src={this.props.coverRealSize} alt={this.props.title}
                                        className='book-cover-landing uk-box-shadow-medium'
                                        style={{
                                            height: `${this.state.landingImageHeight - 25}px`,
                                            position: 'absolute',
                                            top: '17.5px',
                                            left: '0'
                                        }} />
                                </div>
                            </div>
                        </div>


                        <div className='uk-width-1-1 uk-text-right uk-flex uk-flex-right'>
                            <div className="uk-flex uk-flex-column uk-width-1-3 uk-flex-end uk-text-bolder uk-margin-medium-top uk-margin-medium-bottom uk-margin-medium-right">
                                <span className='cu-font uk-text-center uk-text-large uk-text-bolder' style={{ color: 'black' }}>{this.props.landingQuote}</span>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="uk-section uk-section-muted">
                    <div className='uk-container'>
                        <Heading>Σημείωμα Συγγραφέα</Heading>

                        <div className="uk-grid-match uk-child-width-expand@s uk-visible@m" uk-grid="true">
                            <div>
                                <p className='uk-text-lead uk-text-small uk-text-justify uk-text-center uk-flex uk-flex-column uk-flex-start uk-flex-left'>
                                    {this.props.writersMessage.map((par, i) => {
                                        return (<span key={'writers-message-par' + i} className='cu-tab uk-margin-small-bottom'>
                                            {par}
                                        </span>);
                                    })}

                                    <span className='uk-width-1-1 uk-text-right cu-font uk-text-bold uk-text-large'>
                                        Εις το επανιδείν<br></br>
                                        Κυριάκος Κουζούμης
                                    </span>
                                </p>
                            </div>

                            <div>
                                <div id="editorialImageWrapper" className='uk-flex uk-flex-center'>
                                    <img src={this.props.writersMessagePhoto} alt="KyriakosEditorial" id="editorialImage" className="uk-box-shadow-medium" uk-img={'1'} />
                                </div>
                            </div>
                        </div>

                        <div className="uk-grid-match uk-child-width-expand@s uk-hidden@m" uk-grid="true">
                            <div>
                                <div id="editorialImageWrapper" className='uk-flex uk-flex-center'>
                                    <img src={this.props.writersMessagePhoto} alt="KyriakosEditorial" id="editorialImage" className="uk-box-shadow-medium" uk-img={'1'} />
                                </div>
                            </div>

                            <div>
                                <p className='uk-text-lead uk-text-small uk-text-justify uk-text-center uk-flex uk-flex-column uk-flex-start uk-flex-left'>
                                    {this.props.writersMessage.map((par, i) => {
                                        return (<span key={'writers-message-par' + i} className='cu-tab uk-margin-small-bottom'>
                                            {par}
                                        </span>);
                                    })}

                                    <span className='uk-width-1-1 uk-text-right cu-font uk-text-bold uk-text-large'>
                                        Εις το επανιδείν<br></br>
                                        Κυριάκος Κουζούμης
                                    </span>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="uk-section uk-section-default">
                    <div className='uk-container'>
                        <Heading>Πρόλογος</Heading>

                        <div className="uk-grid-match uk-child-width-expand@s" uk-grid="true">
                            <div>
                                <p className='uk-text-lead uk-text-small uk-text-justify uk-text-center uk-flex uk-flex-column uk-flex-start uk-flex-left'>

                                    {this.props.prologue.map((el) => {
                                        return (<span className='cu-tab uk-margin-small-bottom'>
                                            {el}
                                        </span>)
                                    })}
                                </p>

                                <div className="uk-flex uk-flex-right uk-margin-remove uk-padding-small-top">

                                    {/* <ReferenceLink
                                        openInNewTab={false}
                                        link={'/presentation/' + this.props.id}
                                        iconL={'album'}
                                        iconR={'arrow-right'}
                                        text={'Δείτε τις παρουσιάσεις του βιβλίου'}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.comments && this.props.comments.length ? (
                    <div className="uk-section uk-section-muted">
                        <Heading>Αποσπάσματα</Heading>
                        <div className="uk-container" style={{
                            // margin: '0 20px',
                        }}>

                            <div uk-slideshow="animation: scale; min-height: 300; max-height: 500" className='uk-visible@m'>
                                <div className="uk-position-relative uk-visible-toggle uk-dark" tabindex="-1">
                                    <ul className="uk-slideshow-items">
                                        {this.props.comments.map((elx, j) => {
                                            return (<li key={this.props.id + '-comment-box' + j} style={{ color: 'black', backgroundColor: '#f8f8f8' }}>
                                                <div className='uk-flex uk-flex-column uk-flex-center uk-flex-middle' style={{ height: '100%' }}>
                                                    {(elx && elx.length > 1) ? (
                                                        elx.map((el, i) => {
                                                            if (i % 2 !== 0) {
                                                                return <Comment key={this.props.id + '-comment-' + i} left={true}>{el}</Comment>
                                                            } else {
                                                                return <Comment key={this.props.id + '-comment-' + i}>{el}</Comment>
                                                            }
                                                        }
                                                        )) : (
                                                        elx.map((el, i) => {
                                                            return <Comment key={this.props.id + '-comment-' + i} center={true}>{el}</Comment>
                                                        })
                                                    )}
                                                </div>
                                            </li>)
                                        })}
                                    </ul>


                                    <a className="uk-position-center-left-out uk-position-small uk-margin-small-right" href="#" uk-slidenav-previous={1} uk-slideshow-item="previous"></a>
                                    <a className="uk-position-center-right-out uk-position-small uk-margin-small-left" href="#" uk-slidenav-next={2} uk-slideshow-item="next"></a>

                                </div>

                                <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>

                            </div>

                            <div uk-slideshow="animation: scale; min-height: 750" className='uk-hidden@m'>
                                <div className="uk-position-relative uk-visible-toggle uk-dark" tabindex="-1">
                                    <ul className="uk-slideshow-items">
                                        {this.props.comments.map((elx, j) => {
                                            return (<li key={this.props.id + '-comment-box' + j} style={{ color: 'black', backgroundColor: '#f8f8f8' }}>
                                                <div className='uk-flex uk-flex-column uk-flex-center uk-flex-middle' style={{ height: '100%' }}>
                                                    {elx.map((el, i) => {
                                                        return <Comment key={this.props.id + '-comment-' + i} center={true}>{el}</Comment>
                                                    })}
                                                </div>
                                            </li>)
                                        })}
                                    </ul>

                                </div>

                                <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>

                            </div>


                        </div>

                    </div>

                ) : (null)}

                {this.props.playlists && this.props.playlists.length ? (
                    <div className="uk-section uk-section-default">
                        <Heading>Παρουσιάσεις</Heading>

                        <div>
                            {this.props.playlists.map((p, i) => {
                                return (<div key={i}>
                                    <PlaylistSection playlist={p} bookId={this.props.id} />
                                </div>)
                            })}
                        </div>
                    </div>
                ) : (null)}


                {this.props.bookImages && this.props.bookImages.length ? (
                    <div className="uk-section uk-section-muted">
                        <Heading>Φωτογραφίες</Heading>

                        <div className='uk-flex uk-flex-center uk-flex-middle'>
                            {/* <div class="uk-position-relative uk-dark" tabindex="-1" uk-slideshow="animation: fade; min-height: 300; max-height: 600" style={{
                            width: '1080px',
                        }}>
                            <ul class="uk-slideshow-items">
                                {
                                    this.props.bookImages.map((el, i) => {
                                        return (
                                            <li className='uk-flex uk-flex-center uk-flex-middle' key={'book-image-' + i}>
                                                <div uk-lightbox={'1'} style={{ height: '100%' }} >
                                                    <a
                                                        className="uk-inline uk-padding-remove uk-background-transparent uk-flex uk-flex-center uk-flex-middle"
                                                        href={el} data-caption="Caption 1" style={{ height: '100%' }} >
                                                        <img src={el} alt={this.props.title} style={{ height: '100%' }} uk-img={'1'} />
                                                    </a>
                                                </div>
                                            </li>
                                        );
                                    })
                                }
                            </ul>

                            <a class="uk-position-center-left-out uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous={'1'} uk-slideshow-item="previous"></a>
                            <a class="uk-position-center-right-out uk-position-small uk-hidden-hover" href="#" uk-slidenav-next={'1'} uk-slideshow-item="next"></a>
                            <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>

                        </div> */}


                            <div uk-lightbox={'1'} className='uk-width-1-1 uk-visible@m'>
                                <CustomSlider slides={this.props.bookImages} />
                            </div>

                            <div class="uk-position-relative uk-light uk-hidden@m" tabindex="-1" uk-slider="autoplay: true; center: true">
                                <div className='uk-container' uk-lightbox={'1'}>
                                    <ul class="uk-slider-items uk-grid">

                                        {
                                            this.props.bookImages.map((el, i) => {
                                                // return (
                                                //     <li className='uk-flex uk-flex-center uk-flex-middle' key={'book-image-' + i}>
                                                //         <div uk-lightbox={'1'} style={{ height: '100%' }} >
                                                //             <a
                                                //                 className="uk-inline uk-padding-remove uk-background-transparent uk-flex uk-flex-center uk-flex-middle"
                                                //                 href={el} data-caption="Caption 1" style={{ height: '100%' }} >
                                                //                 <img src={el} alt={this.props.title} style={{ height: '100%' }} uk-img={'1'} />
                                                //             </a>
                                                //         </div>
                                                //     </li>
                                                // );

                                                return (<li class="" key={'book-image-' + i}>
                                                    <div class="uk-panel">
                                                        <div>
                                                            <a
                                                                className="uk-inline uk-padding-remove uk-background-transparent uk-flex uk-flex-center uk-flex-middle"
                                                                href={el.img} data-caption={el.caption} >
                                                                <img src={el.img} alt={this.props.title} uk-img={'1'} style={{
                                                                    height: '400px',
                                                                }} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </li>);
                                            })
                                        }
                                    </ul>

                                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous={1} uk-slider-item="previous"></a>
                                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next={1} uk-slider-item="next"></a>

                                </div>
                            </div>
                        </div>
                        {/* <div className="uk-grid-small uk-child-width-1-4 uk-child-height-1-2 uk-flex-center uk-text-center" uk-grid='true' uk-lightbox='animation: scale' style={{
                        backgroundColor: 'black',
                        padding: '10px',
                    }}>
                        {
                            this.props.bookImages.map((el, i) => {
                                return (
                                    <a href={el}
                                        className="uk-inline uk-padding-remove uk-background-transparent uk-flex uk-flex-center uk-flex-middle"
                                        data-caption="Caption 1"
                                        key={'book-image-' + i}
                                        uk-scrollspy="cls: uk-animation-scale-up; repeat: false"
                                    >
                                        <img
                                            className='uk-box-shadow-medium'
                                            src={el}
                                            alt={this.props.title}
                                            style={{
                                                // border: '2px solid white',
                                                // borderRadius: '10px',
                                                width: '100%',
                                            }}
                                        />
                                    </a>
                                );
                            })
                        }
                    </div> */}
                    </div>
                ) : (null)}

                <div className="uk-section uk-section-default uk-flex uk-flex-center uk-margin-remove uk-padding-small">
                    <ReferenceLink
                        openInNewTab={true}
                        link={`https://www.facebook.com/%CE%9A%CF%85%CF%81%CE%B9%CE%AC%CE%BA%CE%BF%CF%82-%CE%9A%CE%BF%CF%85%CE%B6%CE%BF%CF%8D%CE%BC%CE%B7%CF%82-%CE%A4%CE%B1-%CE%B2%CE%B9%CE%B2%CE%BB%CE%AF%CE%B1-%CE%BC%CE%BF%CF%85-109576094177025`}
                        iconL={'facebook'}
                        text={'Ακολουθήστε τη σελίδα του βιβλίου στο Facebook'}
                    />
                </div>

                {this.props.purchaseUrl && (
                    <div className='uk-flex uk-flex-center uk-padding-small'>
                        <p>Μπορείτε να προμηθευτείτε το βιβλίο <strong>{this.props.title}</strong> <a href={`${this.props.purchaseUrl}`}>εδώ</a>.</p>
                    </div>
                )}

                <div className="uk-section uk-section-muted">
                    <Footer />
                </div>
            </React.Fragment >
        );
    }
}

export default BookPage;