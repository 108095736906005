import React, { Component } from 'react'

export class CustomSlider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            slides: props.slides,
            active: 0,
            shouldAnimate: false,
        }
    }

    componentDidMount() {
        // this.interval = setInterval(this.goRight, 5000);
    }


    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getActiveSlides = () => {
        let first = this.state.active;
        let second = this.state.active + 1;
        let third = this.state.active + 2;
        let four = this.state.active + 3;
        let last = this.state.active + 4;

        if (first >= this.state.slides.length) {
            first = first % this.state.slides.length;
        }

        if (second >= this.state.slides.length) {
            second = second % this.state.slides.length;
        }

        if (third >= this.state.slides.length) {
            third = third % this.state.slides.length;
        }

        if (four >= this.state.slides.length) {
            four = four % this.state.slides.length;
        }

        if (last >= this.state.slides.length) {
            last = last % this.state.slides.length;
        }

        console.log("--------");
        console.log(this.state.slides.length);
        console.log(first, second, last);
        console.log("--------");


        return [
            this.state.slides[first],
            this.state.slides[second],
            this.state.slides[third],
            this.state.slides[four],
            this.state.slides[last],
        ];
    }

    resetInterval = () => {
        if (this.interval) {
            clearInterval(this.interval);
        }
        // this.interval = setInterval(this.goRight, 3000);
    }

    goLeft = () => {
        if (this.state.active == 0) {
            this.setState({
                active: this.state.slides.length - 1,
                shouldAnimate: true,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        shouldAnimate: false,
                    })
                }, 200)
            })
        }

        this.setState({
            active: this.state.active - 1,
            shouldAnimate: true,
        }, () => {
            setTimeout(() => {
                this.setState({
                    shouldAnimate: false,
                })
            }, 500)
        })
    }

    goRight = () => {
        if (this.state.active == this.state.slides.length - 1) {
            this.setState({
                active: 0,
                shouldAnimate: true,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        shouldAnimate: false,
                    })
                }, 500)
            })
        }

        this.setState({
            active: this.state.active + 1,
            shouldAnimate: true,
        }, () => {
            setTimeout(() => {
                this.setState({
                    shouldAnimate: false,
                })
            }, 500)
        })
    }

    stopInterval = () => {
        console.log("Stopping interval");
        // clearInterval(this.interval);
    }

    startInterval = () => {
        console.log("Starting interval");
        // this.interval = setInterval(this.goRight, 5000);
    }

    render() {
        let slides = this.getActiveSlides();
        let first = slides[0];
        let second = slides[1];
        let third = slides[2];
        let four = slides[3];
        let last = slides[4];
        let animate = this.state.shouldAnimate ? 'uk-animation-fast uk-animation-scale-up' : '';

        return (
            <React.Fragment>
                <div className='uk-width-1-1 uk-flex uk-flex-center' onMouseOver={this.stopInterval} onMouseOut={this.startInterval}>
                    <div className='uk-flex uk-flex-center uk-flex-middle uk-margin-medium-top' style={{
                        width: '100%',
                    }}>
                        <div style={{
                            marginLeft: '40px',
                        }} onClick={() => requestAnimationFrame(() => {
                            this.resetInterval();
                            this.goLeft();
                        })}>
                            <span uk-icon="icon:  chevron-left; ratio: 2"></span>
                        </div>

                        <div className={`uk-flex uk-flex-right uk-width-1-5`}>
                            <a
                                className={`uk-inline uk-padding-remove uk-background-transparent uk-flex uk-flex-center uk-flex-middle cu-animation  ${1}`}
                                href={first.img} data-caption={first.caption}
                            >
                                {/* <div class="uk-overlay-primary uk-position-cover"></div> */}
                                <img className='gray-scale'
                                src={first.img} alt={first.caption} style={{
                                    height: '300px',
                                    width: '250px',
                                    objectFit: 'cover',
                                }} />
                            </a>
                        </div>

                        <div className={`uk-flex uk-flex-center uk-width-1-5`}>
                            <a
                                className={`uk-inline uk-padding-remove uk-background-transparent uk-flex uk-flex-center uk-flex-middle cu-animation  ${1}`}
                                href={second.img} data-caption={second.caption}
                            >
                                <img className='gray-scale'
                                src={second.img} alt={second.caption} style={{
                                    height: '450px',
                                    width: '300px',
                                    objectFit: 'cover',
                                }} />
                            </a>
                        </div>

                        <div className={`uk-flex uk-flex-center uk-width-1-3`}>
                            <a
                                className={`uk-inline uk-padding-remove uk-background-transparent uk-flex uk-flex-center uk-flex-middle cu-animation  ${animate}`}
                                href={third.img} data-caption={third.caption}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <img 
                                src={third.img} alt={third.caption} style={{
                                    height: '600px',
                                    // width: '100%',
                                    // maxHeight: '600px',
                                    width: '100%',
                                    objectFit: 'cover',
                                }} />
                            </a>
                        </div>

                        <div className={`uk-flex uk-flex-center uk-width-1-5`}>
                            <a
                                className={`uk-inline uk-padding-remove uk-background-transparent uk-flex uk-flex-center uk-flex-middle cu-animation  ${1}`}
                                href={four.img} data-caption={four.caption}
                            >
                                <img className='gray-scale'
                                src={four.img} alt={four.caption} style={{
                                    height: '450px',
                                    width: '300px',
                                    objectFit: 'cover',
                                }} />
                            </a>
                        </div>

                        <div className={`uk-flex uk-flex-left uk-width-1-5`}>
                            <a
                                className={`uk-inline uk-padding-remove uk-background-transparent uk-flex uk-flex-center uk-flex-middle cu-animation ${1}`}
                                href={last.img} data-caption={last.caption}
                            >
                                {/* <div class="uk-overlay-primary uk-position-cover"></div> */}
                                <img className='gray-scale' src={last.img} alt={last.caption} style={{
                                    height: '300px',
                                    width: '250px',
                                    objectFit: 'cover',
                                }} />
                            </a>
                        </div>

                        <div style={{
                            marginRight: '40px',
                        }} onClick={() => requestAnimationFrame(() => {
                            this.resetInterval();
                            this.goRight();
                        })}>
                            <span uk-icon="icon:  chevron-right; ratio: 2"></span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CustomSlider
