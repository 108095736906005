import React, { Component } from 'react'
import Heading from '../Heading/Heading'

export class ContactForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            from: '',
            text: '',
        };

        this.sendEmail = this.sendEmail.bind(this);
        this.changeFromInput = this.changeFromInput.bind(this);
        this.changeTextInput = this.changeTextInput.bind(this);
    }

    changeFromInput(event) {

        if (!event.target.value) {
            this.setState({
                from: null,
            });
            return;
        }

        this.setState({
            from: event.target.value,
        })
    }

    changeTextInput(event) {

        if (!event.target.value) {
            this.setState({
                text: null,
            });
            return;
        }

        this.setState({
            text: event.target.value,
        })
    }

    sendEmail() {
        console.log();
        let from = this.state.from;
        let text = this.state.text;

        if (!from || !text) {
            alert('Το μήνυμα σας δε στάλθηκε! Παρακαλώ συμπληρώστε όλα τα πεδία!');
            return;
        }

        let endpoint = '/api/service/mail/notice';
        return fetch(endpoint, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({
                from,
                text,
            }) // body data type must match "Content-Type" header
        }).then((response) => {
            console.log(response.json());
            alert('Το μήνυμα σας στάλθηκε επιτυχώς!');
            return;
            // UIkit.notification({
            //     message: 'Το μήνυμα σας στάλθηκε επιτυχώς!',
            //     status: 'danger',
            //     pos: 'top-right',
            //     timeout: 5000,
            // });
        }).catch((e) => {
            console.log(e);
            alert('Το μήνυμα σας δε στάλθηκε! Παρακαλώ δοκιμάστε ξανά αργότερα!');
            return;
            // UIkit.notification({
            //     message: 'Το μήνυμα σας δε στάλθηκε! Παρακαλώ δοκιμάστε ξανά αργότερα!',
            //     status: 'danger',
            //     pos: 'top-right',
            //     timeout: 5000,
            // });
        });
    }

    render() {
        console.log(this.state);
        return (
            <div className='uk-flex uk-flex-center uk-flex-column uk-flex-middle' style={{
                marginTop: '-100px',
                marginBottom: '75px',
            }}>
                <div class="uk-card uk-card-default uk-card-body uk-width-2-3@m" style={{
                    boxShadow: '0 10px 30px 0 rgba(0, 0, 0, 0.3)',
                }}>
                    <div>
                        <Heading>Θα χαρώ πολύ να επικοινωνήσετε μαζί μου...</Heading>
                        <div class="uk-form-controls">
                            <input class="uk-input" id="form-horizontal-text" type="text" placeholder="Σημειώστε το email σας" onChange={this.changeFromInput}/>
                        </div>

                        <div class="uk-margin">
                            <textarea name="contact" class="uk-textarea" rows="10" maxLength="800" placeholder="Γράψτε μου..." onChange={this.changeTextInput}></textarea>
                        </div>

                        <div className='uk-flex uk-flex-right'>
                            <button class="uk-button uk-button-default" onClick={this.sendEmail}>Αποστολη</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactForm
