import VenetiaCover from '../assets/stories/venetia.jpg';
import DesiresCover from '../assets/stories/desires.jpg';
import AlmostALieCover from '../assets/stories/almost_a_lie.jpg';

export const VenetiaStory = {
    id: 1,
    title: 'ΒΕΝΕΤΙΑ! Η ΠΟΛΗ ΤΩΝ ΑΙΩΝΙΩΝ ΕΡΑΣΤΩΝ!',
    cover: VenetiaCover,
    date: '3 ΝΟΕΜΒΡΙΟΥ 2012',
    hook: '23:00. Ο ουρανός άρχισε να κλαίει κι εγώ μόνος κάτω απ’ το παράθυρο με λίγο κρασί στο κολονάτο. Στο απέναντι παγκάκι ένας κύριος γύρω στα 40 με το κεφάλι ανάμεσα στα γόνατα...',
    content: [
        '23:00. Ο ουρανός άρχισε να κλαίει κι εγώ μόνος κάτω απ’ το παράθυρο με λίγο κρασί στο κολονάτο. Στο απέναντι παγκάκι ένας κύριος γύρω στα 40 με το κεφάλι ανάμεσα στα γόνατα. 23:15. Βρέθηκα δίπλα του, συστηθήκαμε και το δέσιμο των χεριών μας ένα άλλο σφουγγάρι, που έσταζε βροχή. Άναψα τσιγάρο. «Έχεις δεύτερο;» Πήρε τζούρα και πέταξε τον αναστεναγμό του στον δρόμο. «Μερικές φορές κάποιοι άγνωστοι μοιάζουν με ιερείς…» μου είπε κι άρχισε την εξομολόγησή του.',
        '«Ξέρεις τι σημαίνει απόγνωση, τι σημαίνει τρέλα; Πριν από δύο μήνες η εννιάχρονη κόρη μου χειρουργήθηκε. Όγκος στη μήτρα. Χθες ήταν η δεύτερη χημειοθεραπεία και σήμερα…» Κόμπιασε. «Σήμερα της ξύρισα το κεφάλι. Της είπα πως τα κουρεύουμε πολύ κοντά για να δυναμώσουν, μα τα δάκρυά μου με πρόδωσαν. Όταν κοιτάχτηκε στον καθρέφτη, ούρλιαξε. «Όλοι θα με δείχνουν στο σχολείο. Μόνο εγώ είμαι έτσι» μου είπε με πύρινα κλάματα. Της έκανα μια μεγάλη αγκαλιά και της υποσχέθηκα πως τα επόμενα μαλλιά θα είναι πιο ωραία, πιο δυνατά. Πριν λίγο την έβαλα για ύπνο και το ‘σκασα σαν κλέφτης». «Η μητέρα της;» ρώτησα αποσβολωμένος. «Εδώ κι έναν χρόνο αγνοείται, όπως κι ο καλύτερός μου φίλος. Ναι… Σωστά υποθέτεις». Ρούφηξε νικοτίνη για οξυγόνο και σηκώθηκε αποφασισμένος. «Φεύγω. Πάω να ξυρίσω και το δικό μου κεφάλι. Τουλάχιστον έτσι, δεν θα νιώθει μόνη». Χάθηκε στην στροφή.',
        'Η βροχή πότισε μέσα μου μια απορία. Αν κάποιοι λένε πως μάνα είναι μόνο μία, τότε τι να πουν γι’ αυτόν τον πατέρα; '
    ],
};

export const AlmostALieStory = {
    id: 2,
    title: `ΕΝΑ ΠΑΡ' ΟΛΙΓΟΝ ΛΑΘΟΣ, ΕΝΑ ΠΑΡ' ΟΛΙΓΟΝ ΨΕΜΑ!`,
    cover: AlmostALieCover,
    date: '1 ΔΕΚΕΜΒΡΙΟΥ 2012',
    hook: 'Σαββάτο 23:00. Η επίσκεψή μου σε νωπούς φίλους στον Αυλώνα έλαβε τέλος. Αντί να επιστρέψω άμεσα στην Αθήνα, αποφάσισα έναν μικρό περίπατο...',
    content: [
        'Σαββάτο 23:00. Η επίσκεψή μου σε νωπούς φίλους στον Αυλώνα έλαβε τέλος. Αντί να επιστρέψω άμεσα στην Αθήνα, αποφάσισα έναν μικρό περίπατο. Πιο κάτω μια μικρή πλατεία. Πήρα θέση σ’ ένα παγκάκι, αφήνοντας το φύσημα του αέρα να πολεμάει με μαλλιά και δόντια…',
        '00:00. Σαν άλλη Σταχτοπούτα μια νεαρή «κουκλίτσα» μ’ έναν σάκο κάθισε στο απέναντι παγκάκι. Άναψε τσιγάρο και έτριψε το μέτωπό της. Λίγο αργότερα πιάσαμε την κουβέντα. «Πάντα ήμουν η δεύτερη, η παράνομη, η ερωμένη. Εκείνος παντρεμένος και μ’ ένα παιδί. Δεν πρόκειται να χωρίσει κι ας μου το τάζει συνέχεια. Ορκίστηκα να τον εκδικηθώ και… και να που αύριο παντρεύομαι. Αν το κάνω, θα είναι λάθος. Δεν έχω το δικαίωμα να παίξω με τη ζωή ενός άλλου. Αποφάσισα λοιπόν να πάρω όση αξιοπρέπεια μου έχει απομείνει και να φύγω σαν κλέφτης… Δεν ξέρω αν είναι τίμιο αυτό που κάνω, μα είναι τιμιότερο απ’ το να ζήσω στο ψέμα και πλάι στο δικό μου ψέμα να χτιστεί η ευτυχία του παρολίγον συζύγου μου. Άφησα κι ένα γράμμα στον πατέρα μου. Του έγραψα να με συγχωρέσει, του ζήτησα την ευχή του και του είπα πως τον αγαπώ πολύ. Άλλωστε, αυτός θα βγάλει το φίδι απ’ την τρύπα. Χίλιες φορές μόνη και ειλικρινής με τον εαυτό μου, παρά δεύτερη».',
        'Την είδα να χάνεται στο βάθος της παλιάς εθνικής οδού. Ξεφύσησα. 00:30. Γύρισα το κλειδί στη μίζα και στα ερτζιανά η φωνή της Χαρούλας. «Φεύγω! Φεύγω κι αφήνω πίσω μου συντρίμμια, αρρωστημένους και αγρίμια… Φεύγω…!»',
    ],
};

export const DesiresStory = {
    id: 3,
    title: 'ΑΝΑΜΕΣΑ ΣΤΗΝ ΗΘΙΚΗ ΚΑΙ ΤΙΣ ΕΠΙΘΥΜΙΕΣ!',
    cover: DesiresCover,
    date: '25 ΟΚΤΩΒΡΙΟΥ 2013',
    hook: '19:00. Πλατεία Κοραή. Σ’ ένα παγκάκι είδα μια γυναίκα γύρω στα 35 να κάθεται μόνη της. Έδειχνε προβληματισμένη. Τη ρώτησα αν είχε ανάγκη από κάτι και μου απάντησε...',
    content: [
        '19:00. Πλατεία Κοραή. Σ’ ένα παγκάκι είδα μια γυναίκα γύρω στα 35 να κάθεται μόνη της. Έδειχνε προβληματισμένη. Τη ρώτησα αν είχε ανάγκη από κάτι και μου απάντησε. «Ναι! Από λίγη παρέα…». Ύστερα έμελε να ακούσω το ηθικό δίλημμά της…',
        '«Καμιά φορά συλλογίζομαι πως όταν οι άντρες μας κατηγορούν και υποστηρίζουν πως ποτέ τους δεν θα μπορέσουν να μας καταλάβουν έχουν δίκιο. Σάμπως μπορούμε εμείς; Τελικά η γυναικεία φύση είναι τόσο αλλόκοτη όσο καμιά άλλη. Προχθές είδα σε μια δεξίωση έναν παλιό συνάδελφο που κάποτε με διεκδικούσε τρελά. Εγώ; Τον απέρριπτα συνεχώς. Δεν τον ήθελα καθόλου. Χάρη στα φλερτ του όμως εισέπραττα τη γυναικεία μου επιβεβαίωση και μπάλωνα κάπως τις εσωτερικές μου ανασφάλειες. Προχθές όμως, τον είδα να συνοδεύεται από… τη σύζυγό του. Κάτι έτριξε μέσα μου. Ξαφνικά αισθάνθηκα να τον θέλω. Γιατί να πρέπει να μας αγνοήσουν για να «τσιμπήσουμε»; Γιατί να είναι αθάνατο το συναίσθημα της ζήλειας; Τώρα είναι αργά. Αισθάνομαι τόσο μόνη! Φοβάμαι εμένα και το μυαλό που κουβαλάω. Φοβάμαι μήπως τον θέλω πάλι στον ρόλο του μικρού σκυλιού που κάποτε έτρεχε πίσω μου… Και κυρίως φοβάμαι τις ίδιες αθεράπευτες εσωτερικές μου ανασφάλειες. Ξέρεις, η αξιοπρέπειά μου δεν μου επιτρέπει να γίνω το τρίτο πρόσωπο, αλλά η ψυχή μου με προστάζει να τον διεκδικήσω. Δεν ξέρω, τι να κάνω…»',
        '«Δεν σε προστάζει η ψυχή σου. Σε προστάζει ο εγωισμός σου… Το να γίνεις το τρίτο πρόσωπο είναι ευτέλεια. Το να τα βρεις όμως με τον εαυτό σου είναι πολυτέλεια. Κρίνε εσύ…»',
    ],
};