import React, { Component } from 'react';
import moment from 'moment';

export class NewsArticle extends Component {
    render() {
        let date = moment(this.props.article.date, 'DD/MM/YYYY').lang('fr').format("dddd, MMMM Do YYYY");;
        return (
            <React.Fragment>
                <div className='uk-card uk-card-body uk-card-default uk-margin-medium-bottom uk-box-shadow-small uk-hidden@m'>
                    <article className="uk-article">
                        <h1 className="uk-article-title font-cali uk-text-bolder" style={{
                            fontSize: '1.25rem !important',
                        }}>{this.props.article.title}</h1>

                        <div style={{
                            height: '300px',
                            backgroundColor: 'black',
                            margin: '20px 0'
                        }}>
                            <img src={this.props.article.thumbnail} alt={this.props.article.thumbnailAlt} style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                boxShadow: '0 10px 30px 0 rgba(0, 0, 0, 0.3)',
                                borderRadius: '2.5px',
                            }} uk-img={'1'} />
                        </div>

                        {this.props.article.heading ? (
                            <div className='uk-width-1-1 uk-text-center'>
                                <p className="uk-text-small">{this.props.article.heading}</p>
                            </div>
                        ) : (null)}

                        <div>
                            {this.props.article.preview.map((par, i) => {
                                return (<p className={'par_' + this.props.article.id + '_' + i}>
                                    {par}
                                </p>);
                            })}
                        </div>
                    </article>
                </div>
                <div className='uk-card uk-card-body uk-card-default uk-margin-large uk-box-shadow-medium uk-visible@m'>
                    <article className="uk-article">
                        <h1 className="uk-article-title font-cali uk-text-bolder">{this.props.article.title}</h1>
                        {this.props.article.heading ? (
                            <p className="uk-text-lead">{this.props.article.heading}</p>
                        ) : (null)}

                        {this.props.article.alignLeft ? (
                            <div className='uk-flex uk-flex-top' style={{
                            }}>
                                <div className='uk-flex uk-flex-top' style={{
                                    margin: '0 20px',
                                }}>
                                    <img src={this.props.article.thumbnail} alt={this.props.article.thumbnailAlt} style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        boxShadow: '0 10px 30px 0 rgba(0, 0, 0, 0.3)',
                                    }} uk-img={'1'} />
                                </div>
                                <div style={{
                                }}>
                                    {this.props.article.preview.map((par, i) => {
                                        return (<p className={'par_' + this.props.article.id + '_' + i}>
                                            {par}
                                        </p>);
                                    })}
                                </div>
                            </div>
                        ) : (
                                <div className='uk-flex uk-flex-top' style={{
                                }}>
                                    <div style={{
                                    }}>
                                        {this.props.article.preview.map((par, i) => {
                                            return (<p className={'par_' + this.props.article.id + '_' + i}>
                                                {par}
                                            </p>);
                                        })}
                                    </div>
                                    <div className='uk-flex uk-flex-top' style={{
                                        marginLeft: '10px',
                                    }}>
                                        <img src={this.props.article.thumbnail} alt={this.props.article.thumbnailAlt} style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            boxShadow: '0 10px 30px 0 rgba(0, 0, 0, 0.3)',
                                        }} uk-img={'1'} />
                                    </div>

                                </div>
                            )}
                    </article>
                </div >

            </React.Fragment>
        )
    }
}

export default NewsArticle
