import React, { Component } from 'react';
import ScrollToTopOnMount from '../Components/ScrollToTopOnMount/ScrollToTopOnMount';
import PlaylistSection from '../Components/PlaylistSection/PlaylistSection';
import Footer from '../Components/Footer/Footer';

class Presentation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
            // playlists: [],
        };

        // this.YT_API_KEY = 'AIzaSyBgSqUAg86PCVlULv85NTYUOYUC8sdnyno';
        this.YT_API_KEY = 'AIzaSyCECgTXKDmrPmy06jL9Rj4KNemnkg8cwhc';
        this.YT_CHANNEL_ID = 'UCwC6YyHrFznLl757c7OBzhg';
    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        this.forceUpdate();
        // this.fetchActivePlaylists(null);
    }

    fetchActivePlaylists(nextPageToken) {
        // let endpoint = `https://www.googleapis.com/youtube/v3/search?key=${this.YT_API_KEY}&channelId=${this.YT_CHANNEL_ID}&part=snippet,id&maxResults=25`

        // if (nextPageToken) {
        //     endpoint = `https://www.googleapis.com/youtube/v3/search?pageToken=${nextPageToken}&key=${this.YT_API_KEY}&channelId=${this.YT_CHANNEL_ID}&part=snippet,id&maxResults=25`
        // }

        // fetch(
        //     endpoint
        // )
        //     .then(res => res.json())
        //     .then(json => {
        //         this.setState({
        //             isLoaded: true,
        //             playlists: json.items ? [...this.state.playlists, ...json.items] : [], 
        //         }, () => {
        //             if (json.nextPageToken && json.pageInfo && (json.pageInfo.resultsPerPage > 0)) {
        //                 // this.fetchActivePlaylists(json.nextPageToken);
        //             }
        //         });
        //     });
    }

    render() {
        return (
            <React.Fragment>
                <ScrollToTopOnMount />
                <div className="uk-section uk-section-default">
                    {this.props.playlists.length ? (
                        <div>
                            {this.props.playlists.map((p, i) => {
                                return (<div key={'presentation_set_' + this.props.id + "_video_" + i}>
                                    <PlaylistSection playlist={p} />
                                </div>)
                            })}
                        </div>
                    ) : (<div className='uk-width-1-1 uk-flex uk-flex-center uk-flex-middle'>
                        Δεν υπάρχουν ακόμα παρουσιάσεις.
                    </div>)}
                </div>

                <div className='uk-section'>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

export default Presentation;