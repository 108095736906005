import React, { Component } from 'react'

export class VideoComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            playing: false,
            visible: false,
        }

        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.triggerLightRoom = this.triggerLightRoom.bind(this);
        this.lightRoomElement = React.createRef();
    }

    componentDidMount() {

    }

    toggleVisibility(bool) {
        this.setState({
            visible: bool,
        });
    }
    
    triggerLightRoom() {
        this.lightRoomElement.current.click();
    }

    render() {

        let thumbUrl = this.props.video.snippet.thumbnails.standard ?
            this.props.video.snippet.thumbnails.standard.url :
            this.props.video.snippet.thumbnails.high.url;

        let title = this.props.video.snippet.title;
        let description = this.props.video.snippet.description;

        if (!thumbUrl) {
            return null;
        }

        return (
            <div className={`${!this.state.visible ? '' : 'uk-box-shadow-medium'} cu-smooth-transition cu-hover`} 
                uk-tooltip="title: Δείτε το βίντεο!; pos: bottom-center"
                onMouseOver={() => this.toggleVisibility(true)}
                onMouseLeave={() => this.toggleVisibility(false)} 
                onClick={this.triggerLightRoom}
                style={{
                    margin: '20px 20px 0'
                }}>
                <div className='uk-width-medium' uk-lightbox={'1'}>
                    <div className='uk-inline uk-width-1-1' >


                        <a ref={this.lightRoomElement} class="uk-flex uk-flex-center"
                            href={`https://www.youtube-nocookie.com/embed/${this.props.video.snippet.resourceId.videoId}`}
                            data-caption={title} data-type="iframe" uk-icon="triangle-right">
                            <img
                                style={{
                                    width: '100%',
                                    objectFit: 'fill',
                                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 10px 30px 0px',
                                }}
                                src={thumbUrl}
                                uk-img={'1'}
                                alt="Presentation Video Image"
                            />
                        </a>
                        {/* <div className={`${this.state.visible ? '' : 'uk-hidden'} uk-overlay uk-overlay-primary uk-position-cover uk-animation-scale-up uk-animation-fast uk-flex uk-flex-center uk-flex-middle`}>
                            <div uk-lightbox={'1'} className='uk-width-1-1 uk-flex uk-flex-center'>
                                <a class="uk-icon-button uk-flex uk-flex-center"  href={`https://www.youtube-nocookie.com/embed/${this.props.video.snippet.resourceId.videoId}`} data-caption={title} data-type="iframe" uk-icon="triangle-right"></a>
                            </div>
                        </div> */}
                    </div>

                    <div className='uk-flex uk-flex-column' style={{
                        padding: '25px 20px',
                        maxWidth: '270px',
                        flexGrow: 1,
                        // boxShadow: 'rgba(0, 0, 0, 0.3) 0px 10px 30px 0px',
                    }}>
                        <span style={{
                            fontSize: '16px',
                            fontWeight: 'bolder',
                            marginBottom: '12px',
                        }}>{title}</span>

                        <span className='uk-text-muted' style={{
                            fontSize: '12px',

                        }}>{description}</span>
                    </div>
                </div>
            </div>
        );

        return (

            <div className="uk-card uk-card-body uk-card-default uk-flex uk-flex-column" style={{
                padding: '0',
                margin: '0 10px',
                width: 'calc(19vw - 20px)',
            }}>
                <div>
                    <img src={thumbUrl} alt='test' style={{
                        width: '100%',
                        objectFit: 'fill',
                        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 10px 30px 0px',
                    }}  uk-img={'1'}/>
                </div>

                <div className='uk-flex uk-flex-column' style={{
                    padding: '25px 20px',
                    maxWidth: '270px',
                    flexGrow: 1,
                }}>
                    <span style={{
                        fontSize: '16px',
                        fontWeight: 'bolder',
                        marginBottom: '10px',
                    }}>{title}</span>

                    <span className='uk-text-muted' style={{
                        fontSize: '10px',

                    }}>{description}</span>
                </div>

                <div className='uk-flex uk-flex-right'>
                    <button style={{
                        margin: '10px',
                        backgroundColor: '#86888A',
                        borderRadius: '5px',
                        border: '1px solid #86888A',
                        color: 'white',
                        cursor: 'pointer',
                        padding: '10px 10px',
                        width: '45%',
                        fontWeight: 'bold',
                        fontSize: '12px',
                    }}> Περισσότερα</button>
                </div>
            </div>
        );

        return (
            <div style={{
                width: '20vw',
                height: '270px',
            }}>
                <img src={thumbUrl} alt='test' style={{
                    width: '100%',
                    height: '100%',
                }}  uk-img={'1'}></img>
                {/* <iframe height="480" width="500"
                    title='test'
                    src={`https://www.youtube.com/embed/${this.props.video.snippet.resourceId.videoId}`}>
                </iframe> */}
            </div>
        )
    }
}

export default VideoComponent
