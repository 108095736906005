import React, { Component } from 'react';
import PagesLeft from '../assets/books/pages-left.png';
import PagesRight from '../assets/books/pages-right.jpg';
import { BIO_INFO } from '../Config/bio.config';
import BioSectionItem from '../Components/BioSectionItem/BioSectionItem';
import Footer from '../Components/Footer/Footer';

class Bio extends Component {
    render() {

        let lSections = [
            BIO_INFO.intro,
            BIO_INFO.study,
            BIO_INFO.journalism,
        ]

        let rSections = [
            BIO_INFO.writingStart,
            BIO_INFO.writingEnd,
            BIO_INFO.books,
            // BIO_INFO.interests,
        ];

        return (<React.Fragment>
            <div className='uk-container uk-margin-medium uk-visible@m'>
                <div className='uk-box-shadow-bottom uk-box-shadow-large uk-width-1-1'>
                    <div className='uk-flex uk-flex-middle uk-flex-center'>
                        <div
                            className="uk-background-center-center uk-background-cover"
                            style={{
                                backgroundImage: `url(${PagesLeft})`,
                                height: '720px',
                                width: `70%`,
                                paddingLeft: '20px',
                            }}>

                            <div style={{
                                margin: '0 30px 0 20px',
                            }}>
                                <h1 className="uk-article-title font-cali uk-text-bolder uk-text-center uk-margin-small-top">Βιογραφικό</h1>


                                {lSections.map((section, i) => {
                                    return (<React.Fragment key={`bio_l_section_` + i}>
                                        <BioSectionItem section={section} />
                                    </React.Fragment>)
                                })}
                            </div>
                        </div>

                        <div
                            className="uk-background-center-center uk-background-cover"
                            style={{
                                backgroundImage: `url(${PagesRight})`,
                                height: '720px',
                                width: `70%`,
                                paddingRight: '20px',
                            }}>

                            <div style={{
                                margin: '0 20px 0 30px',
                            }}>

                                <h1 className="uk-article-title font-cali uk-text-bolder uk-text-center" style={{
                                    fontSize: '24px',
                                    marginTop: '30px',
                                }}>Συγγραφή</h1>

                                {rSections.map((section, i) => {
                                    return (<React.Fragment key={`bio_r_section_` + i}>
                                        <BioSectionItem section={section} />
                                    </React.Fragment>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="uk-section uk-section-default uk-margin-medium-top">
                    <Footer />
                </div>
            </div>

            <div className='uk-hidden@m'>
                <div className="uk-section uk-section-muted uk-width-1-1 uk-remove-padding">

                    <h5 className="uk-article-title font-cali uk-text-bolder uk-text-center uk-margin-small-top">Βιογραφικό</h5>

                    <div className='uk-width-1-1 uk-flex uk-flex-center uk-flex-middle' style={{
                        margin: '20px 0',
                    }}>
                        <img src={BIO_INFO.intro.thumbnail} alt={BIO_INFO.intro.thumbnailAlt} style={{
                            width: '50%',
                            objectFit: 'contain',
                        }} />
                    </div>

                    <div className='uk-flex uk-flex-center uk-flex-middle'>
                        <div className='uk-flex uk-flex-column uk-flex-left' style={{
                            width: '90%',
                        }}>

                            <p className='uk-text-small cu-tab uk-text-justify'>
                                {BIO_INFO.intro.text}
                            </p>

                            <h1 className="uk-article-title font-cali uk-text-bolder uk-text-center uk-margin-small-top" style={{
                                fontSize: '22px',
                            }}>{BIO_INFO.study.title}</h1>

                            <p className='uk-text-small cu-tab uk-text-justify'>
                                {BIO_INFO.study.text}
                            </p>

                            <h1 className="uk-article-title font-cali uk-text-bolder uk-text-center uk-margin-small-top" style={{
                                fontSize: '22px',
                            }}>{BIO_INFO.journalism.title}</h1>

                            <p className='uk-text-small cu-tab uk-text-justify'>
                                {BIO_INFO.journalism.text}
                            </p>

                            <h1 className="uk-article-title font-cali uk-text-bolder uk-text-center uk-margin-small-top" style={{
                                fontSize: '22px',
                            }}>{BIO_INFO.writingStart.title}</h1>

                            <p className='uk-text-small cu-tab uk-text-justify'>
                                {BIO_INFO.writingStart.text}
                            </p>

                            <h1 className="uk-article-title font-cali uk-text-bolder uk-text-center uk-margin-small-top" style={{
                                fontSize: '22px',
                            }}>{BIO_INFO.writingEnd.title}</h1>

                            <p className='uk-text-small cu-tab uk-text-justify'>
                                {BIO_INFO.writingEnd.text}
                            </p>

                            <h1 className="uk-article-title font-cali uk-text-bolder uk-text-center uk-margin-small-top" style={{
                                fontSize: '22px',
                            }}>{BIO_INFO.books.title}</h1>

                            <ul className="uk-list uk-list-disc uk-list-emphasis">
                                {BIO_INFO.books.text.map((el, i) => {
                                    return (<li key={'bio-books-' + i} style={{
                                        textJustify: 'inter-word',
                                        textAlign: 'justify',
                                    }}><span className='uk-text-small'>
                                        {el}</span></li>)
                                })}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="uk-section uk-section-default uk-margin-medium-top">
                    <Footer />
                </div>
            </div>
        </React.Fragment>
        );
    }
}

export default Bio;